import React, { Component } from "react";
import { Tabs, Alert } from "antd";
import { connect } from "react-redux";
import {
  fetchOnHoldOrders,
  fetchPendingOrders,
  fetchProcessingOrders,
  fetchBlankSelectionOrders,
  setActiveTabKey,
  fetchStockOrdersList,
  getStockPickingOrder,
  getMultiStockGroupedOrders,
} from "../homeActions";
import OnHoldTab from "./tabPanes/OnHoldTab";
import PendingAuthTab from "./tabPanes/PendingAuthTab";
import UnderProcessingTab from "./tabPanes/UnderProcessingTab";
import BlankSelectionTab from "./tabPanes/BlankSelectionTab";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { getUserPermissions } from "../../../app/common/util/genericUtils";
import StockOrdersTab from "./tabPanes/StockOrdersTab";
import StockPickingTab from "./tabPanes/StockPickingTab";
import MultiStockGroupedTab from "./tabPanes/MultiStockGroupedTab";

const TabPane = Tabs.TabPane;

const tabs = [
  {
    component: OnHoldTab,
    label: "OnHold",
    key: 1,
    requiredPermissions: ["can_view_onhold_orders"],
  },
  {
    component: PendingAuthTab,
    label: "Authorization",
    key: 2,
    requiredPermissions: ["can_view_pendingauth_orders"],
  },
  {
    component: UnderProcessingTab,
    label: "Processing",
    key: 3,
    requiredPermissions: ["can_view_processing_orders"],
  },
  {
    component: BlankSelectionTab,
    label: "Blank Selection",
    key: 4,
    requiredPermissions: ["can_do_blankselection"],
  },
  {
    component: StockOrdersTab,
    label: "Stock Orders",
    key: 5,
    requiredPermissions: ["can_view_stock_orders"],
  },
  {
    component: StockPickingTab,
    label: "Stock Picking (Single)",
    key: 6,
    requiredPermissions: ["can_pick_stock_order"],
  },
  {
    component: MultiStockGroupedTab,
    label: "Stock Picking (Multi)",
    key: 7,
    requiredPermissions: ["can_pick_stock_order"],
  },
];

class TabView extends Component {
  state = {};

  componentDidMount() {
    this.refreshOrders(this.props.activeKey);
  }

  refreshOrders = (activeKey) => {
    const {
      fetchOnHoldOrders,
      fetchPendingOrders,
      fetchProcessingOrders,
      fetchBlankSelectionOrders,
      setActiveTabKey,
      fetchStockOrdersList,
      getStockPickingOrder,
      getMultiStockGroupedOrders,
    } = this.props;
    setActiveTabKey(activeKey);
    const mapper = [
      { key: "1", func: fetchOnHoldOrders },
      { key: "2", func: fetchPendingOrders },
      { key: "3", func: fetchProcessingOrders },
      { key: "4", func: fetchBlankSelectionOrders },
      { key: "5", func: fetchStockOrdersList },
      { key: "6", func: getStockPickingOrder },
      { key: "7", func: getMultiStockGroupedOrders },
    ];
    mapper.forEach((item) => {
      item.key === activeKey && item.func();
    });
  };

  render() {
    const { activeKey } = this.props;
    const userPermissions = getUserPermissions();
    return (
      <div className="tab-container">
        <Tabs
          // defaultActiveKey="1"
          onChange={this.refreshOrders}
          activeKey={activeKey}
        >
          {tabs.map((tab) => {
            return (
              <TabPane tab={tab.label} key={tab.key}>
                <PermissibleRender
                  userPermissions={userPermissions}
                  requiredPermissions={tab.requiredPermissions}
                  renderOtherwise={
                    <Alert message="Access Denied" type="error" />
                  }
                >
                  <tab.component />
                </PermissibleRender>
              </TabPane>
            );
          })}
        </Tabs>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  activeKey: state.home.activeTabKey,
});
const actions = {
  fetchOnHoldOrders,
  fetchPendingOrders,
  fetchProcessingOrders,
  fetchBlankSelectionOrders,
  setActiveTabKey,
  fetchStockOrdersList,
  getStockPickingOrder,
  getMultiStockGroupedOrders,
};
export default connect(
  mapStateToProps,
  actions
)(TabView);
