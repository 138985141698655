import {
  FORM_SUBMIT_START,
  FORM_SUBMIT_SUCCESS,
  FORM_SUBMIT_FAILURE,
  FETCH_FORM_DATA_START,
  FETCH_FORM_DATA_SUCCESS,
  FETCH_FORM_DATA_FAILURE,
  SET_TINT_CHECKBOX_TRUE,
  SET_TINT_CHECKBOX_FALSE,
  SET_FITTING_CHECKBOX_TRUE,
  SET_FITTING_CHECKBOX_FALSE,
  SET_CUSTOMER_ID,
  SET_ADDRESS_LIST,
  SET_LNAM_ID,
  SET_CORRIDOR_ID,
  RESET_FIELDS,
  SET_URGENCY,
  SET_TINT_SPECIFICATION,
  SET_FITTING_FOR_CORRIDOR,
  SET_FITTING_HEIGHT_ID,
  SET_CORRIDOR_FOR_FITTING,
  ENABLE_RIGHT_PRISM,
  DISABLE_RIGHT_PRISM,
  ENABLE_LEFT_PRISM,
  DISABLE_LEFT_PRISM,
  SET_SWING_FOR_LNAM,
  SHOW_ADD_FOR_LNAM,
  HIDE_ADD_FOR_LNAM,
  SET_PANTO_MANDATORY_FLAG,
  SET_NPD_FOR_LNAM,
  SET_ZTILT_MANDATORY_FLAG,
  SET_BVD_MANDATORY_FLAG,
  SET_RIGHT_EYE_CHECKBOX,
  SET_LEFT_EYE_CHECKBOX,
  HIDE_FIELDS_FOR_FP_LNAMS,
  SET_ADD_FIELD_INCLUDE_FLAG,
  SET_MONO_PD_FOR_LNAM,
  SHOW_CUSTOMER_INITIAL_FIELD,
  CHECK_DUPLICATE_ORDERS_START,
  CHECK_DUPLICATE_ORDERS_SUCCESS,
  CHECK_DUPLICATE_ORDERS_FAILURE,
  SET_NPD_DISABLED_FLAG,
  SHOW_NPD_FOR_LNAM,
  SHOW_MONO_PD_FOR_LNAM,
  SET_CTO_FIELDS_MANDATORY,
  SET_TINT_DESCRIPTION,
  SET_LNAM_DESCRIPTION,
  SET_ADDRESS_DESCRIPTION,
  GET_ORDER_FROM_REFERENCEID_START,
  GET_ORDER_FROM_REFERENCEID_SUCCESS,
  GET_ORDER_FROM_REFERENCEID_FAILURE,
  SET_DISTRIBUTOR_LIST,
  SET_RETAILER_LIST,
  SET_PRODUCT_GROUP_LIST,
  SET_PRODUCT_GROUP_DESCRIPTION,
  SET_LNAM_LIST_FOR_PRODUCT_GROUP,
  SET_PRODUCT_GROUP_OWNER,
  SET_FIELD_DISABLE_OVERIDE_FLAG,
  GET_PG_CONFIG_START,
  GET_PG_CONFIG_SUCCESS,
  GET_PG_CONFIG_FAILURE,
  GET_LOCATION_FOR_ORDER_START,
  GET_LOCATION_FOR_ORDER_SUCCESS,
  GET_LOCATION_FOR_ORDER_FAILURE,
  SET_STOCK_DESCRIPTION_LIST,
} from "./orderConstants";
import { createReducer } from "../../app/common/util/reducerUtil";
import { API_STATUS } from "../../app/common/util/genericUtils";

export const initialState = {
  distributorList: [],
  retailerList: [],
  productGroupList: [],
  lnamListForProductGroup: [],
  productGroupDescription: null,
  orderData: {},
  loadingFormData: false,
  customerId: null,
  addressList: [],
  lnamId: null,
  urgent: false,
  corridorId: null,
  tintRequired: false,
  fittingRequired: false,
  tintSpecification: null,
  submittingForm: false,
  fittingForCorridor: false,
  fittingHeightId: null,
  corridorForFitting: false,
  showRightPrism: false,
  showLeftPrism: false,
  showAddForLnam: false,
  isPantoMandatory: false,
  isZtiltMandatory: false,
  isBvdMandatory: false,
  monoPd: "",
  npd: "",
  swing: false,
  rightEyeCheckbox: false,
  leftEyeCheckbox: false,
  includeAddFieldInLnamSum: false,
  hideFieldsForFpLnams: true,
  addFieldIncludeFlag: false,
  showCustomerInitial: false,
  npdDisabledFlag: false,
  showNpdForLnam: false,
  showMonoPdForLnam: false,
  ctoFieldFlag: false,
  tintDescription: null,
  lnamDescription: null,
  addressDescription: null,
  loadingOrderFromReferenceId: false,
  orderDetailsFromReferenceId: {},
  productGroupOwner: null,
  pgDependentFields: null,
  fieldDisableOverideFlag: false,
  mfLocationDependencies: {
    state: "NOT_LOADED",
    totalPower: null,
    mfLocationId: null,
    type: null,
  },
  loadingMfLocation: false,
  stockDescriptionList: [],
};

const setDistributorList = (state, payload) => {
  return {
    ...state,
    distributorList: payload,
  };
};
const setRetailerList = (state, payload) => {
  return {
    ...state,
    retailerList: payload,
  };
};
const setProductListGroup = (state, payload) => {
  return {
    ...state,
    productGroupList: payload,
  };
};
const setProductGroupDescription = (state, payload) => {
  return {
    ...state,
    productGroupDescription: payload,
  };
};
const setLnamListForProductGroup = (state, payload) => {
  return {
    ...state,
    lnamListForProductGroup: payload,
  };
};
export const setCustomerId = (state, payload) => {
  return {
    ...state,
    customerId: payload,
    fittingForCorridor: false,
  };
};
export const setAddressList = (state, payload) => {
  return {
    ...state,
    addressList: payload,
  };
};
export const setLnamId = (state, payload) => {
  return {
    ...state,
    lnamId: payload,
  };
};
export const setCorridorId = (state, payload) => {
  return {
    ...state,
    corridorId: payload,
    fittingForCorridor: true,
  };
};
export const setFittingForCorridor = (state, payload) => {
  return {
    ...state,
    fittingForCorridor: payload,
  };
};

export const setFittingHeightId = (state, payload) => {
  return {
    ...state,
    fittingHeightId: payload,
    corridorForFitting: true,
  };
};

export const setCorridorForFitting = (state, payload) => {
  return {
    ...state,
    corridorForFitting: payload,
  };
};
export const setPantoMandatoryFlag = (state, payload) => {
  return {
    ...state,
    isPantoMandatory: payload,
  };
};
export const setZtiltMandatoryFlag = (state, payload) => {
  return {
    ...state,
    isZtiltMandatory: payload,
  };
};
export const setBvdMandatoryFlag = (state, payload) => {
  return {
    ...state,
    isBvdMandatory: payload,
  };
};
export const setMonoPdForLnam = (state, payload) => {
  return {
    ...state,
    monoPd: payload,
  };
};
export const hideFieldsForFpLnams = (state, payload) => {
  return {
    ...state,
    hideFieldsForFpLnams: payload,
  };
};
export const setNpdForLnam = (state, payload) => {
  return {
    ...state,
    npd: payload,
  };
};
export const setSwingForLnam = (state, payload) => {
  return {
    ...state,
    swing: payload,
  };
};
export const setUrgency = (state, payload) => {
  return {
    ...state,
    urgent: payload,
  };
};
export const setTintSpecification = (state, payload) => {
  return {
    ...state,
    tintSpecification: payload,
  };
};
export const setCtoFieldFlag = (state, payload) => {
  return {
    ...state,
    ctoFieldFlag: payload,
  };
};
export const resetFields = (state) => {
  return {
    ...state,
    distributorList: [],
    retailerList: [],
    productGroupList: [],
    lnamListForProductGroup: [],
    productGroupDescription: null,
    customerId: null,
    addressList: [],
    lnamId: null,
    urgent: false,
    corridorId: null,
    tintRequired: false,
    fittingRequired: false,
    tintSpecification: null,
    submittingForm: false,
    fittingForCorridor: false,
    fittingHeightId: null,
    corridorForFitting: false,
    showRightPrism: false,
    showLeftPrism: false,
    showAddForLnam: false,
    isPantoMandatory: false,
    isZtiltMandatory: false,
    isBvdMandatory: false,
    monoPd: "",
    npd: "",
    swing: false,
    rightEyeCheckbox: false,
    leftEyeCheckbox: false,
    includeAddFieldInLnamSum: false,
    hideFieldsForFpLnams: true,
    addFieldIncludeFlag: false,
    showCustomerInitial: false,
    orderData: {},
    npdDisabledFlag: false,
    showNpdForLnam: false,
    showMonoPdForLnam: false,
    ctoFieldFlag: false,
    tintDescription: null,
    lnamDescription: null,
    addressDescription: null,
    productGroupOwner: null,
    pgDependentFields: null,
    fieldDisableOverideFlag: false,
    mfLocationDependencies: {
      state: "NOT_LOADED",
      totalPower: null,
      mfLocationId: null,
      type: null,
    },
    loadingMfLocation: false,
    stockDescriptionList: [],
  };
};

export const setTintCheckboxTrue = (state) => {
  return {
    ...state,
    tintRequired: true,
  };
};
export const setTintCheckboxFalse = (state) => {
  return {
    ...state,
    tintRequired: false,
  };
};

export const setFittingCheckboxTrue = (state) => {
  return {
    ...state,
    fittingRequired: true,
  };
};
export const setFittingCheckboxFalse = (state) => {
  return {
    ...state,
    fittingRequired: false,
  };
};
export const enableRightPrism = (state) => {
  return {
    ...state,
    showRightPrism: true,
  };
};
export const disableRightPrism = (state) => {
  return {
    ...state,
    showRightPrism: false,
  };
};
export const enableLeftPrism = (state) => {
  return {
    ...state,
    showLeftPrism: true,
  };
};
export const disableLeftPrism = (state) => {
  return {
    ...state,
    showLeftPrism: false,
  };
};
export const showCustomerInitialField = (state, payload) => {
  return {
    ...state,
    showCustomerInitial: payload,
  };
};
export const showAddForLnam = (state) => {
  return {
    ...state,
    showAddForLnam: true,
  };
};
export const hideAddForLnam = (state) => {
  return {
    ...state,
    showAddForLnam: false,
  };
};
export const setRightEyeCheckBox = (state, payload) => {
  return {
    ...state,
    rightEyeCheckbox: payload,
  };
};
export const setLeftEyeCheckBox = (state, payload) => {
  return {
    ...state,
    leftEyeCheckbox: payload,
  };
};
export const setAddFieldIncludeFlag = (state, payload) => {
  return {
    ...state,
    addFieldIncludeFlag: payload,
  };
};
export const formSubmitStart = (state, payload) => {
  return {
    ...state,
    submittingForm: true,
  };
};

export const formSubmitSuccess = (state, payload) => {
  return {
    ...state,
    submittingForm: false,
  };
};

export const formSubmitFailure = (state, payload) => {
  return {
    ...state,
    submittingForm: false,
  };
};

export const fetchFormDataStart = (state, payload) => {
  return {
    ...state,
    loadingFormData: true,
  };
};

export const fetchFormDataSuccess = (state, payload) => {
  return {
    ...state,
    loadingFormData: false,
  };
};

export const fetchFormDataFailure = (state, payload) => {
  return {
    ...state,
    loadingFormData: false,
  };
};
export const checkDuplicateOrdersStart = (state) => {
  return {
    ...state,
    submittingForm: true,
    orderData: {},
  };
};
export const checkDuplicateOrdersSuccess = (state, payload) => {
  return {
    ...state,
    submittingForm: false,
    orderData: payload,
  };
};
export const checkDuplicateOrdersFailure = (state) => {
  return {
    ...state,
    submittingForm: false,
    orderData: {},
  };
};
export const setNpdDisabledFlag = (state, payload) => {
  return {
    ...state,
    npdDisabledFlag: payload,
  };
};

export const showNpdForLnam = (state, payload) => {
  return {
    ...state,
    showNpdForLnam: payload,
  };
};
export const showMonoPdForLnam = (state, payload) => {
  return {
    ...state,
    showMonoPdForLnam: payload,
  };
};

export const setTintDescription = (state, payload) => {
  return { ...state, tintDescription: payload };
};
export const setLnamDescription = (state, payload) => {
  return { ...state, lnamDescription: payload };
};
export const setAddressDescription = (state, payload) => {
  return { ...state, addressDescription: payload };
};
export const getOrderFromReferenceIdStart = (state, payload) => {
  return {
    ...state,
    loadingOrderFromReferenceId: true,
    orderDetailsFromReferenceId: {},
  };
};
export const getOrderFromReferenceIdSuccess = (state, payload) => {
  return {
    ...state,
    loadingOrderFromReferenceId: false,
    orderDetailsFromReferenceId: payload,
  };
};
export const getOrderFromReferenceIdFailure = (state, payload) => {
  return {
    ...state,
    loadingOrderFromReferenceId: false,
    orderDetailsFromReferenceId: {},
  };
};
const setProductGroupOwner = (state, payload) => {
  return {
    ...state,
    productGroupOwner: payload,
  };
};

const setFieldDisableOverideFlag = (state, payload) => {
  return {
    ...state,
    fieldDisableOverideFlag: payload,
  };
};
const getPgConfigStart = (state, payload) => {
  return {
    ...state,
    pgDependentFields: {
      ...state.pgDependentFields,
      [payload.configIdentifier]: {
        status: API_STATUS.LOADING,
      },
    },
  };
};
const getPgConfigSuccess = (state, payload) => {
  return {
    ...state,
    pgDependentFields: {
      ...state.pgDependentFields,
      [payload.configIdentifier]: {
        status: API_STATUS.SUCCESS,
        ...payload.data,
      },
    },
  };
};
const getPgConfigFailure = (state, payload) => {
  return {
    ...state,
    pgDependentFields: {
      ...state.pgDependentFields,
      [payload.configIdentifier]: {
        status: API_STATUS.ERROR,
      },
    },
  };
};
const getLocationForOrderStart = (state) => {
  return {
    ...state,
    loadingMfLocation: true,
  };
};
const getLocationForOrderSuccess = (state, payload) => {
  return {
    ...state,
    loadingMfLocation: false,
    mfLocationDependencies: payload,
  };
};
const getLocationForOrderFailure = (state) => {
  return {
    ...state,
    loadingMfLocation: false,
  };
};

const setStockDescriptionList = (state, payload) => {
  return { ...state, stockDescriptionList: payload };
};

export default createReducer(initialState, {
  [SET_DISTRIBUTOR_LIST]: setDistributorList,
  [SET_RETAILER_LIST]: setRetailerList,
  [SET_PRODUCT_GROUP_LIST]: setProductListGroup,
  [SET_PRODUCT_GROUP_DESCRIPTION]: setProductGroupDescription,
  [SET_LNAM_LIST_FOR_PRODUCT_GROUP]: setLnamListForProductGroup,
  [FORM_SUBMIT_START]: formSubmitStart,
  [SET_CUSTOMER_ID]: setCustomerId,
  [SET_ADDRESS_LIST]: setAddressList,
  [SET_LNAM_ID]: setLnamId,
  [SET_CORRIDOR_ID]: setCorridorId,
  [RESET_FIELDS]: resetFields,
  [SET_URGENCY]: setUrgency,
  [SET_RIGHT_EYE_CHECKBOX]: setRightEyeCheckBox,
  [SET_LEFT_EYE_CHECKBOX]: setLeftEyeCheckBox,
  [ENABLE_RIGHT_PRISM]: enableRightPrism,
  [DISABLE_RIGHT_PRISM]: disableRightPrism,
  [ENABLE_LEFT_PRISM]: enableLeftPrism,
  [DISABLE_LEFT_PRISM]: disableLeftPrism,
  [SHOW_ADD_FOR_LNAM]: showAddForLnam,
  [HIDE_ADD_FOR_LNAM]: hideAddForLnam,
  [SET_SWING_FOR_LNAM]: setSwingForLnam,
  [SET_FITTING_FOR_CORRIDOR]: setFittingForCorridor,
  [SET_FITTING_HEIGHT_ID]: setFittingHeightId,
  [SET_CORRIDOR_FOR_FITTING]: setCorridorForFitting,
  [SET_TINT_SPECIFICATION]: setTintSpecification,
  [SET_TINT_CHECKBOX_TRUE]: setTintCheckboxTrue,
  [SET_TINT_CHECKBOX_FALSE]: setTintCheckboxFalse,
  [SET_FITTING_CHECKBOX_TRUE]: setFittingCheckboxTrue,
  [SET_FITTING_CHECKBOX_FALSE]: setFittingCheckboxFalse,
  [SET_PANTO_MANDATORY_FLAG]: setPantoMandatoryFlag,
  [SET_ZTILT_MANDATORY_FLAG]: setZtiltMandatoryFlag,
  [SET_BVD_MANDATORY_FLAG]: setBvdMandatoryFlag,
  [SHOW_CUSTOMER_INITIAL_FIELD]: showCustomerInitialField,
  [SET_MONO_PD_FOR_LNAM]: setMonoPdForLnam,
  [SET_NPD_FOR_LNAM]: setNpdForLnam,
  [SET_ADD_FIELD_INCLUDE_FLAG]: setAddFieldIncludeFlag,
  [FORM_SUBMIT_SUCCESS]: formSubmitSuccess,
  [FORM_SUBMIT_FAILURE]: formSubmitFailure,
  [FETCH_FORM_DATA_START]: fetchFormDataStart,
  [FETCH_FORM_DATA_SUCCESS]: fetchFormDataSuccess,
  [FETCH_FORM_DATA_FAILURE]: fetchFormDataFailure,
  [HIDE_FIELDS_FOR_FP_LNAMS]: hideFieldsForFpLnams,
  [CHECK_DUPLICATE_ORDERS_START]: checkDuplicateOrdersStart,
  [CHECK_DUPLICATE_ORDERS_SUCCESS]: checkDuplicateOrdersSuccess,
  [CHECK_DUPLICATE_ORDERS_FAILURE]: checkDuplicateOrdersFailure,
  [SET_NPD_DISABLED_FLAG]: setNpdDisabledFlag,
  [SHOW_NPD_FOR_LNAM]: showNpdForLnam,
  [SHOW_MONO_PD_FOR_LNAM]: showMonoPdForLnam,
  [SET_CTO_FIELDS_MANDATORY]: setCtoFieldFlag,
  [SET_TINT_DESCRIPTION]: setTintDescription,
  [SET_LNAM_DESCRIPTION]: setLnamDescription,
  [SET_ADDRESS_DESCRIPTION]: setAddressDescription,
  [GET_ORDER_FROM_REFERENCEID_START]: getOrderFromReferenceIdStart,
  [GET_ORDER_FROM_REFERENCEID_SUCCESS]: getOrderFromReferenceIdSuccess,
  [GET_ORDER_FROM_REFERENCEID_FAILURE]: getOrderFromReferenceIdFailure,
  [SET_PRODUCT_GROUP_OWNER]: setProductGroupOwner,
  [SET_FIELD_DISABLE_OVERIDE_FLAG]: setFieldDisableOverideFlag,

  [GET_PG_CONFIG_START]: getPgConfigStart,
  [GET_PG_CONFIG_SUCCESS]: getPgConfigSuccess,
  [GET_PG_CONFIG_FAILURE]: getPgConfigFailure,

  [GET_LOCATION_FOR_ORDER_START]: getLocationForOrderStart,
  [GET_LOCATION_FOR_ORDER_SUCCESS]: getLocationForOrderSuccess,
  [GET_LOCATION_FOR_ORDER_FAILURE]: getLocationForOrderFailure,

  [SET_STOCK_DESCRIPTION_LIST]: setStockDescriptionList,
});
