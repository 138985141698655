import React, { Component } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { closeModal } from "./modalActions";
import MultiStockOrderList from "../home/tabView/tabPanes/MultiStockOrderList";

const actions = { closeModal };

class MultiStockOrderListModal extends Component {
  render() {
    const { closeModal, groupId } = this.props;
    return (
      <Modal
        title="Multi Stock Orders"
        visible={true}
        onCancel={closeModal}
        width="75%"
        footer={null}
      >
        <MultiStockOrderList groupId={groupId} />
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(MultiStockOrderListModal);
