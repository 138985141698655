import React from "react";
import { Form, Segment, Button, Label, Grid, Header } from "semantic-ui-react";
import { Field, reduxForm } from "redux-form";
import TextInput from "../../../app/common/form/TextInput";
import { connect } from "react-redux";
import {
  combineValidators,
  isRequired,
  composeValidators,
  hasLengthGreaterThan,
} from "revalidate";
import { loginUser } from "../authActions";
import { Redirect } from "react-router-dom";

const validate = combineValidators({
  username: isRequired({ message: "Employee Id is required" }),
  password: composeValidators(
    isRequired({ message: "Password is required" }),
    //isAlphaNumeric("password")("!@#$"),
    hasLengthGreaterThan(5)({
      message: "Password needs to be atleast 6 characters",
    })
  )(),
});

export const LoginForm = ({
  handleSubmit,
  loginUser,
  error,
  invalid,
  submitting,
  isAuthenticated,
}) => {
  if (isAuthenticated) return <Redirect to="/" />;

  return (
    <div className="login-form">
      <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}</style>
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header
            as="h1"
            content="Suprol CRM"
            color="teal"
            textAlign="center"
          />

          <Form error size="large" onSubmit={handleSubmit(loginUser)}>
            <Segment>
              <Field
                name="username"
                component={TextInput}
                type="text"
                placeholder="Employee Id"
              />
              <Field
                name="password"
                component={TextInput}
                type="password"
                placeholder="password"
              />
              <Button
                fluid
                size="large"
                color="teal"
                disabled={invalid || submitting}
                loading={submitting}
              >
                Login
              </Button>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    </div>
  );
};

const actions = {
  loginUser,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.authenticated,
});

export default connect(
  mapStateToProps,
  actions
)(reduxForm({ form: "loginForm", validate })(LoginForm));
