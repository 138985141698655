import React, { Component } from "react";
import { Row, Col } from "antd";
import Commons from "../../orderDetails/Commons";
import Barcode from "react-barcode";
import RightLensConfig from "../../orderDetails/RightLensConfig";
import LeftLensConfig from "../../orderDetails/LeftLensConfig";
import {
  makeCommonsData,
  makeRightEyeConfig,
  makeLeftEyeConfig,
  makeFrameData,
  makeAddOnsData,
} from "../../orderPunching/formUtils";
import {
  stockOrderCommonsHeaderMap,
  rightLensStockHeaderMap,
  leftLensStockHeaderMap,
} from "../../orderPunching/constants";
import Frame from "../../orderDetails/Frame";
import AddOns from "../../orderDetails/AddOns";
class StockOrderDetails extends Component {
  state = {};
  render() {
    const { details, highlightedFields } = this.props;
    const commonsData = makeCommonsData(details);
    const rightLensConfig = makeRightEyeConfig(details);
    const leftLensConfig = makeLeftEyeConfig(details);
    const frameData = makeFrameData(details);

    const addOnsData = makeAddOnsData(details);
    return (
      <div className="order-details-container">
        <Row>
          <Col span={12}>
            <Barcode
              value={details.internal_order_id}
              displayValue={false}
              format="CODE128"
              height={44}
              width={2}
            />
          </Col>
          <Col span={12}>
            {details.external_order_id !== null && (
              <Barcode
                value={details.external_order_id}
                displayValue={false}
                format="CODE128"
                height={44}
                width={2}
              />
            )}
          </Col>
        </Row>
        <h3>Customer Section</h3>
        <Commons
          data={commonsData}
          highlightedFields={highlightedFields}
          headerMap={stockOrderCommonsHeaderMap}
        />
        <h3>Lens Section</h3>
        <Row>
          <RightLensConfig
            data={rightLensConfig.right_lens_config}
            highlightedFields={highlightedFields}
            headerMap={rightLensStockHeaderMap}
          />
          <LeftLensConfig
            data={leftLensConfig.left_lens_config}
            highlightedFields={highlightedFields}
            headerMap={leftLensStockHeaderMap}
          />
        </Row>
        <div>
          <h3>Frame Section</h3>
          <Frame data={frameData.frame} />
        </div>

        <div>
          <h3>Add Ons Section</h3>
          <AddOns data={addOnsData.add_ons} />
        </div>
      </div>
    );
  }
}

export default StockOrderDetails;
