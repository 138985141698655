import React, { Component } from "react";
import { Button } from "antd";
import { connect } from "react-redux";
import { checkDuplicateOrder } from "../../../orderLinking/linkingActions";
import { combineDateTime } from "../../formUtils";

class LinkingButtonSet extends Component {
  state = {};
  handleLinkingOrder = (data) => {
    data["status"] = "pending_auth";
    data["source"] = "manual";
    data["product_group_owner"] = this.props.productGroupOwner;
    const dataWithDateRefactor = combineDateTime(data);
    const { checkDuplicateOrder } = this.props;
    checkDuplicateOrder(dataWithDateRefactor);
  };
  handleBtnDisable = () => {
    const { invalid, rightEyeCheckbox, leftEyeCheckbox } = this.props;

    if (rightEyeCheckbox === false && leftEyeCheckbox === false) {
      return true;
    } else {
      if (invalid === true) {
        return true;
      } else {
        return false;
      }
    }
  };
  render() {
    const { handleSubmit, style, type } = this.props;
    let disabled = this.handleBtnDisable();
    console.log(type);
    return (
      <div className="form-submit-btn" style={style}>
        <Button
          style={{ marginRight: "8%" }}
          size="large"
          type="primary"
          color="teal"
          disabled={disabled}
          onClick={handleSubmit(this.handleLinkingOrder)}
        >
          Save & Process
        </Button>
      </div>
    );
  }
}

const actions = {
  checkDuplicateOrder,
};
const mapStateToProps = (state) => ({
  rightEyeCheckbox: state.orderForm.rightEyeCheckbox,
  leftEyeCheckbox: state.orderForm.leftEyeCheckbox,
});
export default connect(
  mapStateToProps,
  actions
)(LinkingButtonSet);
