import React, { Component } from "react";
import { Form, Label } from "semantic-ui-react";
import { Select, Icon } from "antd";

const Option = Select.Option;

class DropDown extends Component {
  state = {
    description: null,
    open: false,
  };

  handleChange = (value) => {
    const {
      input: { onChange, onBlur },
      getSelectedValue,
    } = this.props;
    this.setState({ description: null });
    onChange(value);
    onBlur(value);

    if (value && value.key) {
      this.handleDescription(value.key);
    } else {
      this.handleDescription(value);
    }

    if (getSelectedValue) {
      getSelectedValue(value);
    }
    this.setState({ open: false });
  };
  handleDescription = (data) => {
    const { extraData } = this.props;
    if (extraData) {
      console.log("data", data);
      const description = this.props.extraData(data);
      this.setState({ description });
    }
  };
  render() {
    const {
      data,
      input: { value, onFocus },
      placeholder,
      meta: { touched, error },
      showSearch,
      allowClear,
      labelInValue,
      disabled,
    } = this.props;
    const { description, open } = this.state;
    return (
      <Form.Field error={touched && !!error}>
        <Select
          onChange={this.handleChange}
          placeholder={placeholder}
          value={value === "" ? undefined : value}
          optionFilterProp="children"
          labelInValue={labelInValue}
          open={open}
          onFocus={(v) => {
            this.setState({ open: true });
            onFocus(v);
          }}
          onBlur={() => this.setState({ open: false })}
          filterOption={(input, option) =>
            String(option.props.children)
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          disabled={disabled}
          showSearch={showSearch}
          onSelect={() => this.setState({ open: false })}
          allowClear={allowClear}
          clearIcon={
            <Icon type="close" onClick={() => this.handleChange("")} />
          }
        >
          {data &&
            data.map((item, index) => {
              return (
                <Option key={index} value={item.key}>
                  {item.label}
                </Option>
              );
            })}
        </Select>
        {description && (
          <Label basic color="blue" style={{ marginTop: "5%" }}>
            {description}
          </Label>
        )}
        {touched && error && (
          <Label basic color="red">
            {error}
          </Label>
        )}
      </Form.Field>
    );
  }
}

export default DropDown;
