import { setItem, getItem, DBgetItem, DBsetItem } from "../../app/common/util/genericUtils";
import { objArrSort } from "../orderPunching/formUtils";

export const getProductGroupListForSelectedCustomer = async (customerId, selectedPgId) => {
    const customerList = await DBgetItem("customerList");
    let list = [];
    let defaultProductGroup = null;
    let productGroupsForSelectedIdentifier = []
    let defaultProductGroupId = selectedPgId;
    let productGroupsIdForSelectedIdentifier = [];
    if (customerList) {
        for (let i = 0; i < customerList.length; i++) {
            if (customerList[i].id === customerId) {
                productGroupsIdForSelectedIdentifier = customerList[i].product_groups
                break;
            }
        }
        const divisionList = await DBgetItem("divisionList");
        if (divisionList) {
            for (let j = 0; j < divisionList.length; j++) {
                if (productGroupsIdForSelectedIdentifier.includes(divisionList[j].id)) {
                    productGroupsForSelectedIdentifier.push(divisionList[j])
                    list.push({
                        label: divisionList[j].code,
                        key: divisionList[j].id
                    })
                    if (divisionList[j].id == defaultProductGroupId) {
                        defaultProductGroup = divisionList[j]
                    }
                }
            }
        }
    }
    if (defaultProductGroup !== null && productGroupsForSelectedIdentifier.length > 0) {
        await DBsetItem('selectedProductGroup', defaultProductGroup);
        await DBsetItem('productGroupsForSelectedIdentifier', productGroupsForSelectedIdentifier)
    }

    let sortedArr = objArrSort(list);
    return {
        productGroupList: sortedArr,
        defaultProductGroup
    }
}
export const getProductGroupListForSelectedDistributor = async (customerId, distributorId, selectedPgId) => {
    const customerList = await DBgetItem("customerList");
    let list = [];
    let defaultProductGroup = null;
    let productGroupsForSelectedIdentifier = []
    let defaultProductGroupId = selectedPgId;
    let productGroupsIdForSelectedIdentifier = [];
    if (customerList) {
        for (let i = 0; i < customerList.length; i++) {
            if (customerList[i].id === customerId) {
                customerList[i].distributor.forEach(distributor => {
                    if (distributor.id === distributorId) {
                        productGroupsIdForSelectedIdentifier = distributor.product_groups
                    }
                })
                break
            }
        }
        const divisionList = await DBgetItem("divisionList");
        if (divisionList) {
            for (let j = 0; j < divisionList.length; j++) {
                if (productGroupsIdForSelectedIdentifier.includes(divisionList[j].id)) {
                    productGroupsForSelectedIdentifier.push(divisionList[j])
                    list.push({
                        label: divisionList[j].code,
                        key: divisionList[j].id
                    })
                    if (divisionList[j].id == defaultProductGroupId) {
                        defaultProductGroup = divisionList[j]
                    }
                }
            }
        }
    }
    if (defaultProductGroup !== null && productGroupsForSelectedIdentifier.length > 0) {
        await DBsetItem('selectedProductGroup', defaultProductGroup);
        await DBsetItem('productGroupsForSelectedIdentifier', productGroupsForSelectedIdentifier)
    }

    let sortedArr = objArrSort(list);
    return {
        productGroupList: sortedArr,
        defaultProductGroup
    }
}

export const getProductGroupListForSelectedRetailer = async (customerId, retailerId, selectedPgId) => {
    const customerList = await DBgetItem("customerList");
    let list = [];
    let defaultProductGroup = null;
    let productGroupsForSelectedIdentifier = [];
    let found = false
    let defaultProductGroupId = selectedPgId;
    let productGroupsIdForSelectedIdentifier = [];
    if (customerList) {
        for (let i = 0; i < customerList.length; i++) {
            if (customerList[i].id === customerId) {
                if (customerList[i].customer_retailer.length > 0) {
                    for (let j = 0; j < customerList[i].customer_retailer.length; j++) {
                        if (customerList[i].customer_retailer[j].id === retailerId) {
                            found = true
                            productGroupsIdForSelectedIdentifier = customerList[i].customer_retailer[j].product_groups
                            break
                        }
                    }
                }
                if (customerList[i].distributor.length > 0 && found === false) {
                    for (let k = 0; k < customerList[i].distributor.length; k++) {
                        customerList[i].distributor[k].distributor_retailer.forEach(distRetailer => {
                            if (distRetailer.id === retailerId) {
                                productGroupsIdForSelectedIdentifier = distRetailer.product_groups
                            }
                        })
                    }
                }
                break
            }

        }

        const divisionList = await DBgetItem("divisionList");
        if (divisionList) {
            for (let j = 0; j < divisionList.length; j++) {
                if (productGroupsIdForSelectedIdentifier.includes(divisionList[j].id)) {
                    productGroupsForSelectedIdentifier.push(divisionList[j])
                    list.push({
                        label: divisionList[j].code,
                        key: divisionList[j].id
                    })
                    if (divisionList[j].id == defaultProductGroupId) {
                        defaultProductGroup = divisionList[j]
                    }
                }
            }
        }
    }

    if (defaultProductGroup !== null && productGroupsForSelectedIdentifier.length > 0) {
        await DBsetItem('selectedProductGroup', defaultProductGroup);
        await DBsetItem('productGroupsForSelectedIdentifier', productGroupsForSelectedIdentifier)
    }

    let sortedArr = objArrSort(list);
    return {
        productGroupList: sortedArr,
        defaultProductGroup
    }
}

export const getPgFieldConfigFromLnamAndPg = (pg, lnamId) => {
    let config = {}
    for (let i = 0; i < pg.config.length; i++) {
        let currentConfig = pg.config[i]
        if (currentConfig.lnam.id === lnamId) {
            const { ellh, add, tint, prism } = currentConfig
            config = {
                ellh,
                add,
                tint,
                prism
            }
            break;
        }
    }
    return config
}