import { notification } from "antd";
import moment from "moment";
import { Modal } from "antd";
import axios from "axios";
import { reportError } from "./sentryConfig";
import localForage from "localforage";
const confirm = Modal.confirm;
const error = Modal.error;
const success = Modal.success;
export const makeUrl = (endpoint) => {
  const baseUrl = "https://api.suprol.club";
  return `${baseUrl}${endpoint}`;
};

export const setItem = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getItem = (key) => {
  const item = window.localStorage.getItem(key);
  const parsedItem = JSON.parse(item);
  return parsedItem;
};
export const removeItem = (key) => {
  window.localStorage.removeItem(key);
};

export const DBsetItem = async(key, value) => {
  await localForage.setItem(key, JSON.stringify(value));
 };
 
 export const DBgetItem = async (key) => {
   const item = await localForage.getItem(key);
   const parsedItem = JSON.parse(item);
   return parsedItem;
 };

export const removeDuplicates = (arr, comp) => {
  const unique = arr
    .map((e) => e[comp])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter((e) => arr[e])
    .map((e) => arr[e]);

  return unique;
};

export const deleteDataOnLogOut = () => {
  window.localStorage.clear();
  localForage.clear();
};
export const getUserType = () => {
  let user = getItem("suprolUser");
  let userType;
  if (user !== undefined) {
    if (user.role === "Employee") {
      userType = user.employee_type;
      return userType;
    } else if (user.role === "Customer") {
      userType = "Customer";
      return userType;
    } else if (user.role === "Distributor") {
      userType = "Distributor";
      return userType;
    }
  }
};
export const lenswareStatusUserMapping = {
  Corporate: "coorperate_value",
  Branch: "branch_value",
  Customer: "customer_value",
};
export const getUserPermissions = () => {
  let user = getItem("suprolUser");
  let permissions;
  if (user !== undefined) {
    permissions = user.permission;
    return permissions;
  }
};
export const errorToast = (message, description, duration) => {
  notification.error({
    message,
    description,
    duration: duration ? duration : 5,
  });
};
export const successToast = (message, description, duration) => {
  notification.success({
    message,
    description,
    duration: duration ? duration : 2,
  });
};

export const disablePreviousDates = (val) => {
  const date = new Date();
  return (
    moment(val)
      .hours(0)
      .minutes(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0) <
    moment(date)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
  );
};
export const disableUpcomingDates = (val) => {
  const date = new Date();
  return (
    moment(val)
      .seconds(0)
      .milliseconds(0) >
    moment(date)
      .seconds(0)
      .milliseconds(0)
  );
};

export const changeToUpperCase = (string) => {
  const upperCase = string.toUpperCase();
  return upperCase;
};

export const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const confirmModal = (
  title,
  okText,
  cancelText,
  onOkFunc,
  onCancelFunc,
  content
) => {
  confirm({
    title,
    content,
    okText,
    cancelText,
    onOk() {
      return onOkFunc ? onOkFunc() : null;
    },
    async onCancel() {
      return onCancelFunc ? onCancelFunc() : null;
    },
  });
};

export const ErrorModal = (title, content) => {
  error({
    title,
    content,
  });
};
export const SuccessModal = (title, content) => {
  success({
    title,
    content,
  });
};
export const printMedia = (html) => {
  console.log(html);
  let popup = window.open();
  popup.document.write(html);
  setTimeout(() => {
    popup.focus();
    popup.print();
    popup.close();
  }, 1000);
};

export const getHourDifference = (date) => {
  var date1 = new Date(date);
  var date2 = new Date();
  var timeDiff = Math.abs(date2.getTime() - date1.getTime());
  var diffHours = Math.ceil(timeDiff / (1000 * 3600));
  // console.log("hour diff", diffHours);
  return diffHours;
};

export const logApiCallTime = (type, start) => {
  const token = getItem("suprolUser");
  const time = new Date() - start;
  let milToSec = ((time % 60000) / 1000).toFixed(2);
  axios({
    method: "POST",
    url: makeUrl("/orders/create/log"),
    data: {
      type,
      time: milToSec,
    },
    headers: {
      Authorization: `Token ${token.auth_token}`,
    },
  })
    .then((response) => {
      if (response && response.data.response_code === 80) {
        console.log("type: " + type + " time: " + milToSec);
      } else {
        reportError(response.data.ui_message);
      }
    })
    .catch((error) => {
      reportError(error);
    });
};

export const isValidValue = (value) => value !== undefined && value !== null;

export const API_STATUS = {
  IDLE: "IDLE",
  LOADING: "LOADING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};
