import {
  FORM_SUBMIT_START,
  FORM_SUBMIT_SUCCESS,
  FORM_SUBMIT_FAILURE,
  FETCH_FORM_DATA_START,
  FETCH_FORM_DATA_SUCCESS,
  FETCH_FORM_DATA_FAILURE,
  SET_TINT_CHECKBOX_TRUE,
  SET_TINT_CHECKBOX_FALSE,
  SET_FITTING_CHECKBOX_TRUE,
  SET_FITTING_CHECKBOX_FALSE,
  SET_CUSTOMER_ID,
  SET_ADDRESS_LIST,
  SET_LNAM_ID,
  SET_CORRIDOR_ID,
  RESET_FIELDS,
  SET_URGENCY,
  CHECK_COATING_TIMELINE,
  SET_TINT_SPECIFICATION,
  SET_FITTING_FOR_CORRIDOR,
  SET_FITTING_HEIGHT_ID,
  SET_CORRIDOR_FOR_FITTING,
  ENABLE_RIGHT_PRISM,
  DISABLE_RIGHT_PRISM,
  ENABLE_LEFT_PRISM,
  DISABLE_LEFT_PRISM,
  SHOW_ADD_FOR_LNAM,
  HIDE_ADD_FOR_LNAM,
  SET_PANTO_MANDATORY_FLAG,
  SET_ZTILT_MANDATORY_FLAG,
  SET_BVD_MANDATORY_FLAG,
  SET_NPD_FOR_LNAM,
  SET_SWING_FOR_LNAM,
  SET_RIGHT_EYE_CHECKBOX,
  SET_LEFT_EYE_CHECKBOX,
  HIDE_FIELDS_FOR_FP_LNAMS,
  SET_ADD_FIELD_INCLUDE_FLAG,
  SET_MONO_PD_FOR_LNAM,
  CHECK_INTERNAL_ORDER_ID_START,
  CHECK_INTERNAL_ORDER_ID_SUCCESS,
  CHECK_INTERNAL_ORDER_ID_FAILURE,
  SHOW_CUSTOMER_INITIAL_FIELD,
  CHECK_DUPLICATE_ORDERS_START,
  CHECK_DUPLICATE_ORDERS_SUCCESS,
  CHECK_DUPLICATE_ORDERS_FAILURE,
  SET_NPD_DISABLED_FLAG,
  SHOW_NPD_FOR_LNAM,
  SHOW_MONO_PD_FOR_LNAM,
  SET_CTO_FIELDS_MANDATORY,
  SET_TINT_DESCRIPTION,
  SET_LNAM_DESCRIPTION,
  SET_ADDRESS_DESCRIPTION,
  GET_ORDER_FROM_REFERENCEID_START,
  GET_ORDER_FROM_REFERENCEID_SUCCESS,
  GET_ORDER_FROM_REFERENCEID_FAILURE,
  SET_DISTRIBUTOR_LIST,
  SET_RETAILER_LIST,
  SET_PRODUCT_GROUP_LIST,
  SET_PRODUCT_GROUP_DESCRIPTION,
  SET_LNAM_LIST_FOR_PRODUCT_GROUP,
  SET_PRODUCT_GROUP_OWNER,
  SET_FIELD_DISABLE_OVERIDE_FLAG,
  GET_PG_CONFIG_START,
  GET_PG_CONFIG_SUCCESS,
  GET_PG_CONFIG_FAILURE,
  GET_LOCATION_FOR_ORDER_START,
  GET_LOCATION_FOR_ORDER_SUCCESS,
  GET_LOCATION_FOR_ORDER_FAILURE,
  SET_STOCK_DESCRIPTION_LIST,
} from "./orderConstants";
import {
  formSubmitApiCall,
  getFieldDataForForm,
  checkInternalOrderId,
  duplicateOrdersApiCall,
  orderFromReferenceId,
  pgConfigApi,
  manfLocationApi,
} from "./api";
import { reportError } from "../../app/common/util/sentryConfig";
import { reset } from "redux-form";
import {
  setItem,
  errorToast,
  successToast,
  getItem,
  getUserType,
  isValidValue,
  API_STATUS,
  DBsetItem,
} from "../../app/common/util/genericUtils";
import { closeModal, openModal } from "../modal/modalActions";
import {
  getCoatingCode,
  coatingTimingMap,
  getLnamType,
  getLnamName,
  getCustomerAddressfromId,
  getRetailerDistributorAddressfromId,
  leftEyeArr,
  rightEyeArr,
  getAddFieldIncludeFlag,
  getLnamDescription,
  getCustomerAddressDescription,
  getCustomerFromId,
  makeFormData,
  getCustomerDistributorsFromId,
  getRetailersFromCustomerId,
  getProductGroupListForCustomer,
  transformLnamList,
  getDistributorDataFromList,
  getProductGroupListForDistributor,
  getProductGroupListForRetailer,
  transformStockDescriptionList,
  getPgConfigIdentifier,
} from "./formUtils";
import { change, touch } from "redux-form";
import { Modal } from "antd";
import { formValueSelector } from "redux-form";
import { lnamForAdd, lnamFromFAlphabet } from "./constants";
import moment from "moment";
import { payloadForOrderPunching } from "../../app/common/form/payloadUtils";
import { setFormFields } from "../home/homeActions";
import { push } from "connected-react-router";
import { ORDER_TYPES } from "../../app/common/util/genericConstants";
import lodashGet from "lodash/get";

export const setCustomerId = (id) => {
  return {
    type: SET_CUSTOMER_ID,
    payload: id,
  };
};
export const setAddressList = (list) => {
  return {
    type: SET_ADDRESS_LIST,
    payload: list,
  };
};
export const setDistributorList = (payload) => {
  return {
    type: SET_DISTRIBUTOR_LIST,
    payload,
  };
};
export const setRetailerList = (payload) => {
  return {
    type: SET_RETAILER_LIST,
    payload,
  };
};
export const setProductGroupList = (payload) => {
  return {
    type: SET_PRODUCT_GROUP_LIST,
    payload,
  };
};
export const setProductGroupDescription = (payload) => {
  return {
    type: SET_PRODUCT_GROUP_DESCRIPTION,
    payload,
  };
};
export const setLnamListForProductGroup = (payload) => {
  return {
    type: SET_LNAM_LIST_FOR_PRODUCT_GROUP,
    payload,
  };
};

export const setStockDescriptionList = (payload) => ({
  type: SET_STOCK_DESCRIPTION_LIST,
  payload,
});

export const customerActions = (obj) => async (dispatch, getState) => {
  const customerId = getState().orderForm.customerId;
  const state = getState();
  const selector = formValueSelector("orderForm");
  const id = obj.key;
  let addressList = [];
  const shippingFlag = selector(state, "shipping_same_as_billing");
  if (id !== customerId) {
    const resetValof = ["distributor", "retailer", "shipping_address"];
    resetValof.forEach((val) => {
      dispatch(change("orderForm", val, ""));
    });
  }
  dispatch(setCustomerId(id));
  shippingFlag === "true"
    ? (addressList = await getCustomerAddressfromId(id))
    : (addressList = await getRetailerDistributorAddressfromId(id));
  dispatch(setAddressList(addressList));
  if (addressList.length > 0) {
    dispatch(setShippingAddress(addressList[0]));
    dispatch(setDispatchType(addressList[0]["dispatchType"]));
  } else {
    dispatch(setShippingAddress(null));
    dispatch(setDispatchType(null));
  }

  const adrDescription = await getCustomerAddressDescription(id);
  console.log('adrDescription', adrDescription)
  dispatch(setAddressDescription(adrDescription));
  const distributorData = await getCustomerDistributorsFromId(id);
  const retailerData = await getRetailersFromCustomerId(id);
  dispatch(setDistributorList(distributorData));
  dispatch(setRetailerList(retailerData));
  const productGroupData = await getProductGroupListForCustomer(id);

  let { defaultProductGroup, productGroupList } = productGroupData;
  if (defaultProductGroup !== null && productGroupList.length > 0) {
    dispatch(setProductGroupList(productGroupList));
    dispatch(setProductGroupDescription(defaultProductGroup.description));
    dispatch(
      change("orderForm", "product_group", {
        label: defaultProductGroup.code,
        key: defaultProductGroup.id,
      })
    );
    let lnamList = transformLnamList(defaultProductGroup.config);
    dispatch(setLnamListForProductGroup(lnamList));
    dispatch(setProductGroupOwner("customer"));
    const stockDescriptionList = transformStockDescriptionList(
      defaultProductGroup.stock_description
    );
    dispatch(setStockDescriptionList(stockDescriptionList));
  } else {
    let fieldsToChange = [
      "product_group",
      "lnam",
      "coating",
      "fitting_height",
      "corridor",
    ];
    dispatch(setProductGroupList([]));
    dispatch(setProductGroupDescription(null));
    fieldsToChange.forEach((field) => {
      dispatch(change("orderForm", field, ""));
    });
    dispatch(setLnamListForProductGroup([]));
    dispatch(setLnamId(null));
    dispatch(setLnamDescription(null));
    dispatch(setStockDescriptionList([]));
  }
};
export const handleDistributorActions = (value, customerId) => async (dispatch) => {
  if (value && value.key) {
    dispatch(change("orderForm", "retailer", ""));
    const productGroupData = await getProductGroupListForDistributor(
      customerId,
      value.key
    );
    let { defaultProductGroup, productGroupList } = productGroupData;
    dispatch(
      setProductGroupFields(
        defaultProductGroup,
        productGroupList,
        "distributor"
      )
    );
  } else {
    const resetValof = [
      "retailer",
      "product_group",
      "lnam",
      "coating",
      "fitting_height",
      "corridor",
    ];
    resetValof.forEach((val) => {
      dispatch(change("orderForm", val, ""));
    });
    dispatch(setLnamDescription(null));
    dispatch(setAddressDescription(null));
    dispatch(setProductGroupDescription(null));
    dispatch(setProductGroupOwner("customer"));
    dispatch(customerActions({ key: customerId }));
  }
};
export const handleRetailerActions = (value, customerId) => async (dispatch) => {
  if (value && value.key) {
    const productGroupData = await getProductGroupListForRetailer(
      customerId,
      value.key
    );
    let { defaultProductGroup, productGroupList } = productGroupData;
    dispatch(
      setProductGroupFields(defaultProductGroup, productGroupList, "retailer")
    );
  } else {
    const resetValof = [
      "distributor",
      "product_group",
      "lnam",
      "coating",
      "fitting_height",
      "corridor",
    ];
    resetValof.forEach((val) => {
      dispatch(change("orderForm", val, ""));
    });
    dispatch(setLnamDescription(null));
    dispatch(setAddressDescription(null));
    dispatch(setProductGroupDescription(null));
    dispatch(setProductGroupOwner("customer"));
    dispatch(customerActions({ key: customerId }));
  }
};
export const setProductGroupFields = (
  defaultProductGroup,
  productGroupList,
  selectedProductGroupOwner
) => (dispatch) => {
  if (defaultProductGroup !== null && productGroupList.length > 0) {
    dispatch(setProductGroupList(productGroupList));
    dispatch(setProductGroupDescription(defaultProductGroup.description));
    dispatch(
      change("orderForm", "product_group", {
        label: defaultProductGroup.code,
        key: defaultProductGroup.id,
      })
    );

    let lnamList = transformLnamList(defaultProductGroup.config);
    dispatch(setLnamListForProductGroup(lnamList));
    dispatch(setProductGroupOwner(selectedProductGroupOwner));
  }
  let fieldsToChange = ["lnam", "coating", "fitting_height", "corridor"];
  fieldsToChange.forEach((field) => dispatch(change("orderForm", field, "")));
  dispatch(setLnamDescription(null));
};

export const setShippingAddress = (customerAddress) => (dispatch) => {
  dispatch(change("orderForm", "shipping_address", customerAddress));
};
export const setDispatchType = (addressDispatchType) => (dispatch) => {
  dispatch(change("orderForm", "dispatch_type", addressDispatchType));
};
export const setLnamId = (id) => {
  return {
    type: SET_LNAM_ID,
    payload: id,
  };
};
export const setCorridorId = (id) => {
  return {
    type: SET_CORRIDOR_ID,
    payload: id,
  };
};
export const setFittingforCorridor = (flag) => {
  return {
    type: SET_FITTING_FOR_CORRIDOR,
    payload: flag,
  };
};
export const setFittingHeightId = (id) => {
  return {
    type: SET_FITTING_HEIGHT_ID,
    payload: id,
  };
};
export const setCorridorForFitting = (flag) => {
  return {
    type: SET_CORRIDOR_FOR_FITTING,
    payload: flag,
  };
};

export const setUrgency = (flag) => {
  return {
    type: SET_URGENCY,
    payload: flag,
  };
};
export const setTintSpecification = (value) => {
  return {
    type: SET_TINT_SPECIFICATION,
    payload: value,
  };
};
export const resetFields = () => {
  return {
    type: RESET_FIELDS,
  };
};

export const setTintCheckboxTrue = () => {
  return {
    type: SET_TINT_CHECKBOX_TRUE,
  };
};

export const setTintCheckboxFalse = () => {
  return {
    type: SET_TINT_CHECKBOX_FALSE,
  };
};

export const setFittingCheckboxTrue = () => {
  return {
    type: SET_FITTING_CHECKBOX_TRUE,
  };
};

export const setFittingCheckboxFalse = () => {
  return {
    type: SET_FITTING_CHECKBOX_FALSE,
  };
};
export const enableRightPrism = () => {
  return {
    type: ENABLE_RIGHT_PRISM,
  };
};
export const disableRightPrism = () => {
  return {
    type: DISABLE_RIGHT_PRISM,
  };
};
export const enableLeftPrism = () => {
  return {
    type: ENABLE_LEFT_PRISM,
  };
};
export const disableLeftPrism = () => {
  return {
    type: DISABLE_LEFT_PRISM,
  };
};
export const setSwingForLnam = (swing) => {
  return {
    type: SET_SWING_FOR_LNAM,
    payload: swing,
  };
};
export const setRightEyeCheckBox = (flag) => {
  return {
    type: SET_RIGHT_EYE_CHECKBOX,
    payload: flag,
  };
};
export const setLeftEyeCheckBox = (flag) => {
  return {
    type: SET_LEFT_EYE_CHECKBOX,
    payload: flag,
  };
};
export const checkSwingForLnam = () => (dispatch, getState) => {
  const state = getState();
  const selector = formValueSelector("orderForm");
  const lnamId = selector(state, "lnam");
  if (lnamId) {
    const lnamType = getLnamType(lnamId.key);
    console.log(lnamType);
    const rightEyeCheckbox = getState().orderForm.rightEyeCheckbox;
    const leftEyeCheckbox = getState().orderForm.leftEyeCheckbox;
    let lnamList = ["RS8", "RS4", "BLN", "RSV"];
    if (lnamList.includes(lnamType) === true) {
      dispatch(setSwingForLnam(true));
      rightEyeCheckbox === true &&
        dispatch(change("orderForm", "right_lens_config.swing", "15"));
      leftEyeCheckbox === true &&
        dispatch(change("orderForm", "left_lens_config.swing", "15"));
    } else {
      dispatch(setSwingForLnam(false));
      rightEyeCheckbox === true &&
        dispatch(change("orderForm", "right_lens_config.swing", ""));
      leftEyeCheckbox === true &&
        dispatch(change("orderForm", "left_lens_config.swing", ""));
    }
  }
};
export const showCustomerInitialField = (flag) => {
  return {
    type: SHOW_CUSTOMER_INITIAL_FIELD,
    payload: flag,
  };
};

export const checkCustomerInitialField = () => (dispatch, getState) => {
  const selector = formValueSelector("orderForm");
  const lnamId = selector(getState(), "lnam");
  if (lnamId) {
    const lnamType = getLnamType(lnamId.key);
    if (lnamFromFAlphabet.includes(lnamType) === true) {
      dispatch(showCustomerInitialField(true));
      dispatch(touch("orderForm", "clinit"));
      dispatch(touch("orderForm", "cto"));
      dispatch(touch("orderForm", "fitting_height"));
    } else {
      dispatch(showCustomerInitialField(false));
    }
  }
};

export const setCtoFieldsMandatory = (flag) => {
  return {
    type: SET_CTO_FIELDS_MANDATORY,
    payload: flag,
  };
};
export const setMonoPdForLnam = (ipd) => {
  return {
    type: SET_MONO_PD_FOR_LNAM,
    payload: ipd,
  };
};
export const setNpdForLnam = (npd) => {
  return {
    type: SET_NPD_FOR_LNAM,
    payload: npd,
  };
};
export const setNpdDisabledFlag = (flag) => {
  return {
    type: SET_NPD_DISABLED_FLAG,
    payload: flag,
  };
};
export const showNpdForLnam = (flag) => {
  return {
    type: SHOW_NPD_FOR_LNAM,
    payload: flag,
  };
};
export const showMonoPdForLnam = (flag) => {
  return {
    type: SHOW_MONO_PD_FOR_LNAM,
    payload: flag,
  };
};
export const checkMonoPdForLnam = () => (dispatch, getState) => {
  const state = getState();
  const selector = formValueSelector("orderForm");
  const lnamId = selector(state, "lnam");
  if (lnamId) {
    const lnamType = getLnamType(lnamId.key);
    const rightEyeCheckbox = getState().orderForm.rightEyeCheckbox;
    const leftEyeCheckbox = getState().orderForm.leftEyeCheckbox;
    if (lnamType.startsWith("F") === true) {
      dispatch(setMonoPdForLnam("+32.00"));
      dispatch(showMonoPdForLnam(true));
      if (rightEyeCheckbox === true) {
        dispatch(change("orderForm", "frame.mono_pd_r", "+32.00"));
      }
      if (leftEyeCheckbox === true) {
        dispatch(change("orderForm", "frame.mono_pd_l", "+32.00"));
      }
    } else {
      dispatch(setMonoPdForLnam(""));
      dispatch(showMonoPdForLnam(false));
      if (rightEyeCheckbox === true) {
        dispatch(change("orderForm", "frame.mono_pd_r", ""));
      }
      if (leftEyeCheckbox === true) {
        dispatch(change("orderForm", "frame.mono_pd_l", ""));
      }
    }
  }
};

export const checkNpdPdForLnam = () => (dispatch, getState) => {
  const state = getState();
  const selector = formValueSelector("orderForm");
  const lnamId = selector(state, "lnam");
  if (lnamId) {
    const lnamType = getLnamType(lnamId.key);
    const rightEyeCheckbox = getState().orderForm.rightEyeCheckbox;
    const leftEyeCheckbox = getState().orderForm.leftEyeCheckbox;
    const lnamList = ["FPD", "FPL", "FPP"];
    lnamType === "FPD"
      ? dispatch(setNpdDisabledFlag(true))
      : dispatch(setNpdDisabledFlag(false));

    if (lnamList.includes(lnamType) === true) {
      dispatch(setNpdForLnam("+30.00"));
      dispatch(showNpdForLnam(true));
      if (rightEyeCheckbox === true) {
        dispatch(change("orderForm", "frame.npd_r", "+30.00"));
      }
      if (leftEyeCheckbox === true) {
        dispatch(change("orderForm", "frame.npd_l", "+30.00"));
      }
    } else {
      dispatch(setNpdForLnam(""));
      dispatch(showNpdForLnam(false));
      if (rightEyeCheckbox === true) {
        dispatch(change("orderForm", "frame.npd_r", ""));
      }
      if (leftEyeCheckbox === true) {
        dispatch(change("orderForm", "frame.npd_l", ""));
      }
    }
  }
};

export const showAddForLnam = () => {
  return {
    type: SHOW_ADD_FOR_LNAM,
  };
};
export const hideAddForLnam = () => {
  return {
    type: HIDE_ADD_FOR_LNAM,
  };
};

export const checkAddForLnam = () => (dispatch, getState) => {
  const state = getState();
  const selector = formValueSelector("orderForm");
  const lnamId = selector(state, "lnam");
  if (lnamId) {
    const lnamName = getLnamName(lnamId.key);
    if (lnamForAdd.includes(lnamName) === false) {
      dispatch(showAddForLnam());
    } else {
      dispatch(hideAddForLnam());
    }
  } else {
    dispatch(hideAddForLnam());
  }
};
export const hideFieldsForFpLnams = (payload) => {
  return {
    type: HIDE_FIELDS_FOR_FP_LNAMS,
    payload,
  };
};
export const setDblForLnam = () => (dispatch, getState) => {
  const state = getState();
  const selector = formValueSelector("orderForm");
  const lnamId = selector(state, "lnam");
  if (lnamId !== undefined) {
    const lnamType = getLnamType(lnamId.key);
    if (lnamType.startsWith("F") === true) {
      dispatch(change("orderForm", "frame.dbl", "18"));
    } else {
      dispatch(change("orderForm", "frame.dbl", ""));
    }
  } else {
    dispatch(change("orderForm", "frame.dbl", ""));
  }
};
export const makeFieldRequiredWithValue = () => (dispatch, getState) => {
  const state = getState();
  const selector = formValueSelector("orderForm");
  const lnamId = selector(state, "lnam");
  if (lnamId) {
    const lnamType = getLnamType(lnamId.key);
    const lnamList = ["FPD", "FPP", "FPL"];
    if (lnamList.includes(lnamType) === true) {
      dispatch(setPantoMandatoryFlag(true));
      dispatch(setZtiltMandatoryFlag(true));
      dispatch(setBvdMandatoryFlag(true));
      dispatch(hideFieldsForFpLnams(false));
      dispatch(change("orderForm", "frame.panto", "10"));
      dispatch(change("orderForm", "frame.ztilt", "4"));
      dispatch(change("orderForm", "frame.bvd", "12"));
    } else {
      dispatch(setPantoMandatoryFlag(false));
      dispatch(setZtiltMandatoryFlag(false));
      dispatch(setBvdMandatoryFlag(false));
      dispatch(change("orderForm", "frame.panto", ""));
      dispatch(change("orderForm", "frame.ztilt", ""));
      dispatch(change("orderForm", "frame.bvd", ""));
      dispatch(hideFieldsForFpLnams(true));
    }
  } else {
    dispatch(setPantoMandatoryFlag(false));
    dispatch(setZtiltMandatoryFlag(false));
    dispatch(setBvdMandatoryFlag(false));
    dispatch(change("orderForm", "frame.panto", ""));
    dispatch(change("orderForm", "frame.ztilt", ""));
    dispatch(change("orderForm", "frame.bvd", ""));
    dispatch(hideFieldsForFpLnams(true));
  }
};
export const setPantoMandatoryFlag = (flag) => {
  return {
    type: SET_PANTO_MANDATORY_FLAG,
    payload: flag,
  };
};
export const setZtiltMandatoryFlag = (flag) => {
  return {
    type: SET_ZTILT_MANDATORY_FLAG,
    payload: flag,
  };
};
export const setBvdMandatoryFlag = (flag) => {
  return {
    type: SET_BVD_MANDATORY_FLAG,
    payload: flag,
  };
};

export const setLnamFields = (id) => (dispatch) => {
  dispatch(findLnamDescription(id));
  dispatch(checkSwingForLnam());
  dispatch(checkMonoPdForLnam());
  dispatch(checkNpdPdForLnam());
  // dispatch(checkAddForLnam());
  dispatch(setDblForLnam());
  dispatch(makeFieldRequiredWithValue());
  dispatch(checkAddFieldIncludeFlag());
  dispatch(checkCustomerInitialField());
  dispatch(makeWidthFieldsTouched());
};

export const makeWidthFieldsTouched = () => (dispatch, getState) => {
  const state = getState();
  const selector = formValueSelector("orderForm");
  const lnamId = selector(state, "lnam");
  if (lnamId && lnamId.key) {
    const lnamType = getLnamType(lnamId.key);
    if (lnamFromFAlphabet.includes(lnamType) === true) {
      let fields = ["frame.a_width", "frame.b_width"];
      dispatch(makefieldTouched(fields));
    }
  }
};
export const makefieldTouched = (fieldNames) => (dispatch) => {
  fieldNames.forEach((fieldName) => {
    dispatch(touch("orderForm", fieldName));
  });
};
export const transformOnBlur = (newValue, name, cb) => (dispatch) => {
  const right = "right_lens_config";
  const left = "left_lens_config";

  let val =
    newValue.indexOf(".") === -1 ? Number(newValue) / 100 : Number(newValue);
  // console.log("entered", newValue, val);
  let transformedVal;
  if (newValue > 0) {
    if (newValue > 0 && newValue < 10) {
      transformedVal = "+0" + parseFloat(val).toFixed(2);
      dispatch(change("orderForm", name, transformedVal));
      dispatch(touch("orderForm", name));
    } else {
      transformedVal = "+" + parseFloat(val).toFixed(2);
      dispatch(change("orderForm", name, "+" + parseFloat(val).toFixed(2)));
      dispatch(touch("orderForm", name));
    }
  } else if (newValue < 0) {
    transformedVal = String(parseFloat(val).toFixed(2));
    dispatch(change("orderForm", name, transformedVal));
    dispatch(touch("orderForm", name));
  } else if (newValue === 0) {
    transformedVal = String(parseFloat(val).toFixed(2));
    dispatch(change("orderForm", name, transformedVal));
    dispatch(touch("orderForm", name));
  } else if (!newValue) {
    if (
      name === `${right}.sph` ||
      name === `${right}.cyl` ||
      name === `${left}.sph` ||
      name === `${left}.cyl`
    ) {
      transformedVal = "0.00";
      dispatch(change("orderForm", name, transformedVal));
      dispatch(touch("orderForm", name));
    } else {
      dispatch(touch("orderForm", name));
    }
  }
  const isCyl = name === `${right}.cyl` || name === `${left}.cyl`;
  isCyl && cb && cb(transformedVal);
};
export const changeFieldsWithDia = (newValue, name) => (dispatch, getState) => {
  dispatch(change("orderForm", "right_lens_config.ellh", newValue));
  dispatch(touch("orderForm", "right_lens_config.ellh"));
  dispatch(touch("orderForm", "right_lens_config.dia"));
  const leftEyeCheckbox = getState().orderForm.leftEyeCheckbox;
  if (leftEyeCheckbox === true) {
    dispatch(change("orderForm", "left_lens_config.dia", newValue));
    dispatch(change("orderForm", "left_lens_config.ellh", newValue));
    dispatch(touch("orderForm", "left_lens_config.ellh"));
    dispatch(touch("orderForm", "left_lens_config.dia"));
  }
};
export const formSubmitStart = () => {
  return {
    type: FORM_SUBMIT_START,
  };
};

export const formSubmitSuccess = (response) => {
  return {
    type: FORM_SUBMIT_SUCCESS,
    payload: response,
  };
};
export const formSubmitFailure = () => {
  return {
    type: FORM_SUBMIT_FAILURE,
  };
};

export const submitForm = (formData, cb) => async (dispatch) => {
  dispatch(formSubmitStart());
  dispatch(closeModal());
  formData["source"] = "manual";
  return formSubmitApiCall(formData)
    .then((response) => {
      console.log(response);
      if (response && response.data.response_code === 80) {
        dispatch(formSubmitOperations());
        cb && cb();
      } else {
        dispatch(formSubmitFailure());
        errorToast("Error", response.data.ui_message);
        window.scrollTo(0, 0);
        //    dispatch(reset("orderForm"));
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(formSubmitFailure(error));
      errorToast("Error", error.message);
      reportError();
    });
};
export const formSubmitOperations = () => (dispatch) => {
  dispatch(formSubmitSuccess());
  // dispatch(setCustomerDependencies());
  window.scrollTo(0, 0);
  dispatch(reset("orderForm"));
  dispatch(resetFields());
  dispatch(setTintCheckboxFalse());
  dispatch(setFittingCheckboxFalse());
  dispatch(setUrgency(false));
  dispatch(setRightEyeCheckBox(false));
  dispatch(setLeftEyeCheckBox(false));
  dispatch(setCustomerDependencies());
  successToast("Success", "The order has been placed successfully.");
};
export const fetchFormDataStart = () => {
  return {
    type: FETCH_FORM_DATA_START,
  };
};
export const fetchFormDataSuccess = (response) => {
  return {
    type: FETCH_FORM_DATA_SUCCESS,
    payload: response,
  };
};
export const fetchFormDataFailure = () => {
  return {
    type: FETCH_FORM_DATA_FAILURE,
  };
};

export const fetchFormData = () => async (dispatch) => {
  dispatch(fetchFormDataStart());
  return getFieldDataForForm()
    .then((response) => {
      const listNames = [
        "lnamList",
        "tintList",
        "employeeList",
        "dispatchList",
        //"branchList",
        "manufacturingUnitList",
        "config",
      ];
      listNames.forEach((name, index) => {
        console.log('jss',  index)
        if (
          response &&
          response.length > 0 &&
          response[index].data.response_code === 80
        ) {
          setItem(name, response[index].data.data);
        } else {
          errorToast(`Failed to load ${name}`, response[index].data.ui_message);
        }
      });
      DBsetItem( "divisionList", response[6].data.data)
      dispatch(fetchFormDataSuccess());
      successToast("Success", "Form data successfully loaded.");
    })
    .catch((error) => {
      dispatch(fetchFormDataFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const checkCoatingTimelineStart = () => {
  return {
    type: CHECK_COATING_TIMELINE,
  };
};
export const checkCoatingTimeline = (formName) => (dispatch, getState) => {
  dispatch(checkCoatingTimelineStart());
  const state = getState();
  const selector = formValueSelector(formName);
  const coatingId = selector(state, "coating");
  const lnamId = selector(state, "lnam");
  let promised_hour = selector(state, "promised_hour");
  let promised_minute = selector(state, "promised_minute");
  let promised_date = selector(state, "promised_date");
  if (!lnamId) {
    dispatch(touch(formName, "lnam"));
    dispatch(change(formName, "promised_date", null));
    dispatch(change(formName, "promised_hour", null));
    dispatch(change(formName, "promised_minute", null));
  } else if (!coatingId) {
    dispatch(touch(formName, "coating"));
    dispatch(change(formName, "promised_date", null));
    dispatch(change(formName, "promised_hour", null));
    dispatch(change(formName, "promised_minute", null));
  } else {
    if (promised_date) {
      const date = moment(promised_date).format("YYYY-MM-DD");
      const dateTimeString = date + " " + promised_hour + ":" + promised_minute;
      const coatingName = getCoatingCode(lnamId.key, coatingId.key);
      const selectedDateTime = new Date(dateTimeString);
      if (coatingName) {
        if (coatingName === "UNCOAT") {
          if (selectedDateTime < coatingTimingMap.uncoat.requiredDateTime) {
            errorToast("Error", "Please select time more than 1 hour.");
            dispatch(change(formName, "promised_date", null));
            dispatch(change(formName, "promised_hour", null));
            dispatch(change(formName, "promised_minute", null));
          }
        } else if (coatingName === "PRVZHC") {
          if (selectedDateTime < coatingTimingMap.pureviz.requiredDateTime) {
            errorToast("Error", "Please select time more than 4 hours.");
            dispatch(change(formName, "promised_date", null));
            dispatch(change(formName, "promised_hour", null));
            dispatch(change(formName, "promised_minute", null));
          }
        } else if (coatingName === "AQUAHC") {
          if (selectedDateTime < coatingTimingMap.aqua.requiredDateTime) {
            errorToast("Error", "Please select time more than 4 hours.");
            dispatch(change(formName, "promised_date", null));
            dispatch(change(formName, "promised_hour", null));
            dispatch(change(formName, "promised_minute", null));
          }
        } else {
          if (selectedDateTime < coatingTimingMap.others.requiredDateTime) {
            errorToast("Error", "Please select time more than 7 hours.");
            dispatch(change(formName, "promised_date", null));
            dispatch(change(formName, "promised_hour", null));
            dispatch(change(formName, "promised_minute", null));
          }
        }
      }
    } else {
      dispatch(touch(formName, "promised_date"));
      dispatch(change(formName, "promised_hour", null));
      dispatch(change(formName, "promised_minute", null));
    }
  }
};

export const bothEyesEqual = (e, value) => (dispatch, getState) => {
  const selector = formValueSelector("orderForm");
  const values = selector(
    getState(),
    "right_lens_config.sph",
    "right_lens_config.cyl",
    "right_lens_config.axis",
    "right_lens_config.add",
    "right_lens_config.dia",
    "right_lens_config.ellh",
    "right_lens_config.swing",
    "right_lens_config.mbase",
    "right_lens_config.et",
    "right_lens_config.ct",
    "right_lens_config.ed"
  );
  if (values.right_lens_config) {
    for (var j = 0; j < leftEyeArr.length; j++) {
      let field = "";
      if (value) {
        let re = values.right_lens_config;
        if (re[rightEyeArr[j]]) {
          field = re[rightEyeArr[j]];
        }
        dispatch(change("orderForm", leftEyeArr[j], field));
      } else {
        dispatch(change("orderForm", leftEyeArr[j], field));
      }
    }
  }
};
export const setAddFieldIncludeFlag = (payload) => {
  return {
    type: SET_ADD_FIELD_INCLUDE_FLAG,
    payload,
  };
};
export const checkAddFieldIncludeFlag = () => (dispatch, getState) => {
  const selector = formValueSelector("orderForm");
  const lnamId = selector(getState(), "lnam");
  if (lnamId && lnamId.key) {
    const addFieldIncludeFlag = getAddFieldIncludeFlag(lnamId.key);
    if (addFieldIncludeFlag === true) {
      dispatch(setAddFieldIncludeFlag(true));
    } else {
      dispatch(setAddFieldIncludeFlag(false));
    }
  }
};

export const checkTotalPowerForRX = (type) => (dispatch, getState) => {
  const resetFieldsWithError = (errorMessage) => {
    errorToast("Error", errorMessage);
    const fields = ["sph", "cyl"];
    fields.forEach((field) => {
      dispatch(change("orderForm", `${type}.${field}`, ""));
    });
    return;
  };

  const selector = formValueSelector("orderForm");

  const cylFromField = selector(getState(), `${type}.cyl`);

  if (!cylFromField) return;

  const sphFromField = selector(getState(), `${type}.sph`);

  if (!sphFromField) resetFieldsWithError("Please enter sph first");

  const pgObj = selector(getState(), "product_group");

  const pgId = lodashGet(pgObj, "key", null);

  const lnamObj = selector(getState(), "lnam");

  const lnamId = lodashGet(lnamObj, "key", null);

  const configIdentifier = getPgConfigIdentifier(pgId, lnamId);

  const pgDependentFields = lodashGet(
    getState(),
    `orderForm.pgDependentFields.${configIdentifier}`,
    {}
  );

  if (
    !pgDependentFields ||
    !isValidValue(pgDependentFields.min_prescription) ||
    !isValidValue(pgDependentFields.max_prescription)
  )
    resetFieldsWithError(
      "Either config is not loaded or min max prescription is incorrect."
    );

  const min_prescription = parseFloat(pgDependentFields.min_prescription);
  const max_prescription = parseFloat(pgDependentFields.max_prescription);

  const sph = parseFloat(sphFromField);
  const cyl = parseFloat(cylFromField);

  const product = sph * cyl;

  const sum = sph + cyl;

  if (product > 0) {
    if (sum < min_prescription || sum > max_prescription) {
      resetFieldsWithError(
        `Product of sph and cyl (For RX) is +ve and sum doesn't lie b/w ${min_prescription} & ${max_prescription}`
      );
    }
  }

  if (product < 0) {
    const maxValue = Math.max(sph, cyl);
    if (maxValue < min_prescription || maxValue > max_prescription) {
      resetFieldsWithError(
        `Product of sph and cyl (For RX) is -ve and max value doesn't lie b/w ${min_prescription} & ${max_prescription}`
      );
    }
  }

  if (product === 0) {
    if (cyl < min_prescription || cyl > max_prescription) {
      resetFieldsWithError(
        `Product of sph and cyl (For RX) is 0 and cyl doesn't lie b/w ${min_prescription} & ${max_prescription}`
      );
    }
  }
};

export const isValidStockPower = ({ sph, min, max }) => {
  if (sph < min || sph > max) {
    return false;
  } else {
    return true;
  }
};

export const checkTotalPowerForStock = (type) => (dispatch, getState) => {
  const resetFieldsWithError = (errorMessage) => {
    errorToast("Error", errorMessage);
    setTimeout(() => dispatch(change("orderForm", `${type}.sph`, "")), 1000);
    return;
  };

  const selector = formValueSelector("orderForm");

  const sphFromField = selector(getState(), `${type}.sph`);

  if (!sphFromField) resetFieldsWithError("Please enter sph first");

  const pgObj = selector(getState(), "product_group");

  const pgId = lodashGet(pgObj, "key", null);

  const lnamObj = selector(getState(), "lnam");

  const lnamId = lodashGet(lnamObj, "key", null);

  const configIdentifier = getPgConfigIdentifier(pgId, lnamId);

  const pgDependentFields = lodashGet(
    getState(),
    `orderForm.pgDependentFields.${configIdentifier}`,
    {}
  );

  if (
    !pgDependentFields ||
    !isValidValue(pgDependentFields.min_prescription) ||
    !isValidValue(pgDependentFields.max_prescription)
  )
    resetFieldsWithError(
      "Either config is not loaded or min max prescription is incorrect."
    );

  const min = parseFloat(pgDependentFields.min_prescription);
  const max = parseFloat(pgDependentFields.max_prescription);

  const sph = parseFloat(sphFromField);

  if (!isValidStockPower({ min, max, sph })) {
    resetFieldsWithError(`For stock order sph must lie b/w ${min} & ${max}`);
  }
};

export const checkInternalOrderIdStart = () => {
  return {
    type: CHECK_INTERNAL_ORDER_ID_START,
  };
};
export const checkInternalOrderIdSuccess = () => {
  return {
    type: CHECK_INTERNAL_ORDER_ID_SUCCESS,
  };
};
export const checkInternalOrderIdFailure = () => {
  return {
    type: CHECK_INTERNAL_ORDER_ID_FAILURE,
  };
};

export const checkOrderId = (newValue, name) => async (dispatch) => {
  if (newValue.length > 7 && newValue.length < 13) {
    dispatch(checkInternalOrderIdStart);
    return checkInternalOrderId(newValue)
      .then((response) => {
        if (response && response.data.response_code === 80) {
          if (response.data.data.count === 0) {
            successToast("Success", "Order is not duplicate");
          } else {
            errorToast("Error", "This Internal Order Id already exist");
            dispatch(reset("orderForm"));
            dispatch(touch("orderForm", "internal_order_id"));
          }
        } else {
          dispatch(checkInternalOrderIdFailure());
          errorToast("Error", response.data.ui_message);
          dispatch(reset("orderForm"));
        }
      })
      .catch((error) => {
        dispatch(checkInternalOrderIdFailure());
        errorToast("Error", error.ui_message);
        dispatch(reset("orderForm"));
      });
  } else {
    dispatch(touch("orderForm", "internal_order_id"));
  }
};

export const checkDuplicateOrdersStart = () => {
  return {
    type: CHECK_DUPLICATE_ORDERS_START,
  };
};
export const checkDuplicateOrdersSuccess = (payload) => {
  return {
    type: CHECK_DUPLICATE_ORDERS_SUCCESS,
    payload,
  };
};
export const checkDuplicateOrdersFailure = () => {
  return {
    type: CHECK_DUPLICATE_ORDERS_FAILURE,
  };
};
export const checkDuplicateOrders = (formData, cb) => async (dispatch) => {
  dispatch(checkDuplicateOrdersStart());
  const data = payloadForOrderPunching(formData);
  console.log("Form data", JSON.stringify(data));
  dispatch(closeModal());
  return duplicateOrdersApiCall(data)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(checkDuplicateOrdersSuccess());
        dispatch(submitForm(data, cb));
      } else if (response && response.data.response_code === 90) {
        dispatch(checkDuplicateOrdersSuccess(data));
        if (formData.order_source === ORDER_TYPES.RX) {
          dispatch(
            openModal(
              "DuplicateOrderListModal",
              Object.assign({}, { orderList: response.data.data })
            )
          );
        } else if (formData.order_source === ORDER_TYPES.STOCK_SINGLE) {
          Modal.confirm({
            title: "Duplicate Order detected. Process Order?",
            okText: "Process Order",
            onOk: () => dispatch(submitForm(data, cb)),
            cancelText: "Cancel",
            onCancel: () => window.location.reload(),
          });
        }
      } else {
        dispatch(checkDuplicateOrdersFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(checkDuplicateOrdersFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const copyRightEyeFieldsToLeft = () => (dispatch, getState) => {
  const selector = formValueSelector("orderForm");
  const values = selector(
    getState(),
    "right_lens_config.add",
    "right_lens_config.dia",
    "right_lens_config.ellh",
    "right_lens_config.swing",
    "right_lens_config.mbase"
  );
  console.log("right fields", values);
  let fieldsToCopy = ["dia", "ellh", "swing", "add", "mbase"];
  fieldsToCopy.forEach((field) => {
    if (values.right_lens_config && values.right_lens_config[field]) {
      dispatch(
        change(
          "orderForm",
          `left_lens_config.${field}`,
          values.right_lens_config[field]
        )
      );
    }
  });
};

export const setTintDescription = (payload) => {
  return {
    type: SET_TINT_DESCRIPTION,
    payload,
  };
};
export const findLnamDescription = (id) => (dispatch) => {
  const description = getLnamDescription(id);
  dispatch(setLnamDescription(description));
};
export const setLnamDescription = (payload) => {
  return {
    type: SET_LNAM_DESCRIPTION,
    payload,
  };
};

export const setAddressDescription = (payload) => {
  return {
    type: SET_ADDRESS_DESCRIPTION,
    payload,
  };
};

export const setCustomerDependencies = () => async (dispatch) => {
  let userType = getUserType();
  /*
  possible user type values are
  Corporate
  Branch
  Customer
  Distributor
  */

  if (userType !== "Corporate") {
    const url = window.location.pathname;
    const regex = /validate/;
    if (regex.test(url) === false) {
      let user = getItem("suprolUser");
      if (user && user.customer !== undefined) {
        dispatch(setFieldDisableOverideFlag(true));
        let customerObj = { key: user.customer };
        let customerData = await getCustomerFromId(user.customer);
        console.log("customer data", customerData);
        const customerForSelect = {
          key: customerData.id,
          label:
            customerData.name +
            " - " +
            customerData.nickname +
            " - " +
            customerData.customer_accn,
        };
        dispatch(change("orderForm", "customer", customerForSelect));
        dispatch(customerActions(customerObj));
        if (userType === "Distributor") {
          if (user.distributor && customerData.distributor.length > 0) {
            let distributor = getDistributorDataFromList(
              user.distributor,
              customerData.distributor
            );
            dispatch(change("orderForm", "distributor", distributor));
          } else {
            errorToast(
              "Error",
              "Distributor at login/ in customer list not correct"
            );
            errorToast("Error", "Please contact the Admin");
          }
        }
        // let adrDescription = getCustomerAddressDescription(user.customer);
        // dispatch(setAddressDescription(adrDescription));
      }
    } else {
      dispatch(setFieldDisableOverideFlag(true));
    }
  }
};

export const setInitialManufacturingLocation = () => (dispatch) => {
  const manufacturingUnitList = getItem("manufacturingUnitList");
  let mUnit;
  if (
    manufacturingUnitList &&
    manufacturingUnitList !== null &&
    manufacturingUnitList.length > 0
  ) {
    manufacturingUnitList.forEach((unit) => {
      if (unit.short_name.toUpperCase() === "DLAB") {
        mUnit = {
          label: unit.short_name,
          key: unit.id,
        };
      }
    });
    console.log("mbu", mUnit);
    dispatch(change("orderForm", "manufacturing_business_unit", mUnit));
  }
};

export const getOrderFromReferenceIdStart = () => {
  return {
    type: GET_ORDER_FROM_REFERENCEID_START,
  };
};
export const getOrderFromReferenceIdSuccess = (payload) => {
  return {
    type: GET_ORDER_FROM_REFERENCEID_SUCCESS,
    payload,
  };
};
export const getOrderFromReferenceIdFailure = () => {
  return {
    type: GET_ORDER_FROM_REFERENCEID_FAILURE,
  };
};

export const validatefromHome = (id) => async (dispatch) => {
  dispatch(getOrderFromReferenceIdStart());
  return orderFromReferenceId(id)
    .then(async (response) => {
      if (response && response.data.response_code === 80) {
        const data = response.data.data;
        if (data.product_group_owner !== null) {
          dispatch(setFormFields(data));
          console.log(data);
          await dispatch(getPgConfig(data.lnam.id, data.product_group.id));
          const formData = makeFormData(data);
          dispatch(getOrderFromReferenceIdSuccess(formData));
          dispatch(closeModal());
          const orderType = data.is_stock
            ? ORDER_TYPES.STOCK_SINGLE
            : ORDER_TYPES.RX;
          dispatch(
            push(`/validate/reference_id/${orderType}/${data.reference_id}`)
          );
        } else {
          dispatch(getOrderFromReferenceIdFailure());
          errorToast("Error", "Old order, product group does not exist.");
        }
      } else {
        dispatch(getOrderFromReferenceIdFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(getOrderFromReferenceIdFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const setProductGroupOwner = (payload) => {
  return {
    type: SET_PRODUCT_GROUP_OWNER,
    payload,
  };
};

export const setFieldDisableOverideFlag = (payload) => {
  return {
    type: SET_FIELD_DISABLE_OVERIDE_FLAG,
    payload,
  };
};

const getPgConfigStart = (payload) => {
  return {
    type: GET_PG_CONFIG_START,
    payload,
  };
};
const getPgConfigSuccess = (payload) => {
  return {
    type: GET_PG_CONFIG_SUCCESS,
    payload,
  };
};
const getPgConfigFailure = (payload) => {
  return {
    type: GET_PG_CONFIG_FAILURE,
    payload,
  };
};

export const getPgConfig = (lnamId, pgId) => (dispatch, getState) => {
  const configIdentifier = getPgConfigIdentifier(pgId, lnamId);

  const configStatus = lodashGet(
    getState(),
    `orderForm.pgDependentFields.${configIdentifier}.status`,
    API_STATUS.ERROR
  );

  if (configStatus === API_STATUS.SUCCESS) return;

  dispatch(
    getPgConfigStart({
      configIdentifier,
    })
  );

  return pgConfigApi(lnamId, pgId)
    .then((response) => {
      console.log("PG Config", response);
      if (response && response.data && response.data.response_code === 80) {
        const data = {
          configIdentifier,
          data: response.data.data,
        };
        dispatch(getPgConfigSuccess(data));
      } else {
        dispatch(
          getPgConfigFailure({
            configIdentifier,
          })
        );
        errorToast("Error", response.data.ui_message);
        errorToast("Error", "Please logout and login again or contact Admin");
      }
    })
    .catch((error) => {
      dispatch(
        getPgConfigFailure({
          configIdentifier,
        })
      );
      errorToast("Error", error.message);
    });
};

const getLocationForOrderStart = () => {
  return {
    type: GET_LOCATION_FOR_ORDER_START,
  };
};
export const getLocationForOrderSuccess = (payload) => {
  return {
    type: GET_LOCATION_FOR_ORDER_SUCCESS,
    payload,
  };
};
const getLocationForOrderFailure = () => {
  return {
    type: GET_LOCATION_FOR_ORDER_FAILURE,
  };
};

export const getLocationForEye = (data, type) => (dispatch, getState) => {
  dispatch(getLocationForOrderStart());
  return manfLocationApi(data)
    .then((response) => {
      console.log("MANF Location RIGHT", response);
      if (response && response.data && response.data.response_code === 80) {
        const mfLocationDependencies = getState().orderForm
          .mfLocationDependencies;
        const mfFieldValue = {
          key: response.data.data.location_id,
          label: response.data.data.location_short_name,
        };
        if (
          mfLocationDependencies.state === "NOT_LOADED" &&
          mfLocationDependencies.mfLocationId === null
        ) {
          dispatch(
            change("orderForm", "manufacturing_business_unit", mfFieldValue)
          );
          const statePayload = {
            state: "LOADED",
            totalPower: data.total_power,
            mfLocationId: response.data.data.location_id,
            type,
          };
          dispatch(getLocationForOrderSuccess(statePayload));
        } else {
          const previousTotalPower = mfLocationDependencies.totalPower;
          const previousType = mfLocationDependencies.type;
          if (type === previousType) {
            dispatch(
              change("orderForm", "manufacturing_business_unit", mfFieldValue)
            );
            const statePayload = {
              state: "LOADED",
              totalPower: data.total_power,
              mfLocationId: response.data.data.location_id,
              type,
            };
            dispatch(getLocationForOrderSuccess(statePayload));
          } else {
            if (data.total_power > previousTotalPower) {
              dispatch(
                change("orderForm", "manufacturing_business_unit", mfFieldValue)
              );
              const statePayload = {
                state: "LOADED",
                totalPower: data.total_power,
                mfLocationId: response.data.data.location_id,
                type,
              };
              dispatch(getLocationForOrderSuccess(statePayload));
            } else {
              dispatch(getLocationForOrderSuccess(mfLocationDependencies));
            }
          }
        }
      } else {
        dispatch(getLocationForOrderFailure());
        errorToast("Error", response.data.ui_message);
        setTimeout(() => {
          dispatch(change("orderForm", `${type}_lens_config.sph`, ""));
          dispatch(change("orderForm", `${type}_lens_config.cyl`, ""));
        }, 1000);
      }
    })
    .catch((error) => {
      dispatch(getLocationForOrderFailure());
      errorToast("Error", error.message);
      setTimeout(() => {
        dispatch(change("orderForm", `${type}_lens_config.sph`, ""));
        dispatch(change("orderForm", `${type}_lens_config.cyl`, ""));
      }, 1000);
    });
};
