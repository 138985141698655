import React from "react";
import CreateOrderButtons from "./rxSets/CreateOrderButtons";
import LinkingButtonSet from "./rxSets/LinkingButtonSet";
import ValidateButtonSet from "./rxSets/ValidateButtonSet";
import WhatsappButtonSet from "./rxSets/WhatsappButtonSet";

class RxOrderActionButton extends React.Component {
  render() {
    const {
      type,
      invalid,
      handleSubmit,
      initialValues,
      productGroupOwner,
      onFormHold,
      onFormSubmit,
      handleBtnDisable,
      btnStyles,
    } = this.props;
    const style = btnStyles && btnStyles[type] ? btnStyles[type] : {};
    return (
      <>
        {type === "create" && (
          <CreateOrderButtons
            invalid={invalid}
            onFormHold={onFormHold}
            onFormSubmit={onFormSubmit}
            handleSubmit={handleSubmit}
            handleBtnDisable={handleBtnDisable}
            style={style}
            type={type}
          />
        )}
        {type === "validate" && (
          <ValidateButtonSet
            invalid={invalid}
            handleSubmit={handleSubmit}
            details={initialValues}
            type={type}
            showNextPrevious={true}
            productGroupOwner={productGroupOwner}
            style={style}
          />
        )}
        {type === "validateFromHome" && (
          <ValidateButtonSet
            invalid={invalid}
            handleSubmit={handleSubmit}
            details={initialValues}
            type={type}
            showNextPrevious={true}
            productGroupOwner={productGroupOwner}
            style={style}
          />
        )}
        {type === "whatsapp" && (
          <WhatsappButtonSet
            invalid={invalid}
            handleSubmit={handleSubmit}
            type={type}
            productGroupOwner={productGroupOwner}
            style={style}
          />
        )}
        {type === "linking" && (
          <LinkingButtonSet
            invalid={invalid}
            handleSubmit={handleSubmit}
            type={type}
            productGroupOwner={productGroupOwner}
            style={style}
          />
        )}
      </>
    );
  }
}
export { RxOrderActionButton };
