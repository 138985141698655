import React, { Component } from "react";
import { Grid, Header, Form } from "semantic-ui-react";
import { Field } from "redux-form";
import { Row, Col } from "antd";
import TextInput from "../../../app/common/form/TextInput";
import RadioInput from "../../../app/common/form/RadioInput";
import CustomSelect from "../../../app/common/form/CustomSelect";
import { connect } from "react-redux";
import {
  setTintCheckboxTrue,
  setTintCheckboxFalse,
  setFittingCheckboxTrue,
  setFittingCheckboxFalse,
  setTintSpecification,
  setTintDescription,
} from "../orderActions";
import {
  getTintList,
  tintSpecificationData,
  getTintDescription,
  getPgDataForSelectedLnam,
} from "../formUtils";
import FieldWrapper from "../FieldWrapper";
import { getUserPermissions } from "../../../app/common/util/genericUtils";

class AddOns extends Component {
  state = {};
  handleFitting = (e, value) => {
    const { setFittingCheckboxTrue, setFittingCheckboxFalse } = this.props;
    value === true ? setFittingCheckboxTrue() : setFittingCheckboxFalse();
  };
  handleTinting = (e, value) => {
    const { setTintCheckboxTrue, setTintCheckboxFalse } = this.props;
    value === true ? setTintCheckboxTrue() : setTintCheckboxFalse();
  };
  getTintSpecification = (val) => {
    const { tintSpecification, setTintSpecification } = this.props;
    if (val.key !== tintSpecification) {
      console.log(val.key, tintSpecification);
      this.props.change("add_ons.tint", "");
      setTintSpecification(val.key);
    } else {
      setTintSpecification(val.key);
    }
  };
  handleTintDescription = (obj) => {
    let description = getTintDescription(obj.key);
    this.props.setTintDescription(description);
  };
  render() {
    const {
      tintRequired,
      fittingRequired,
      labelInValue,
      tintSpecification,
      tintDescription,
      pgDependentFields,
    } = this.props;
    const tintData = getTintList(tintSpecification);
    const userPermissions = getUserPermissions();
    const canViewSpecialInstruction = userPermissions.includes(
      "can_view_special_instruction"
    );
    return (
      <Row>
        <Row>
          <FieldWrapper
            name="tint_needed"
            id="tint_needed"
            //isChecked={true}
            component="input"
            type="checkbox"
            onChange={this.handleTinting}
            heading="Tint Needed"
            mandatory={false}
            width={4}
            disabled={pgDependentFields.tint !== true}
          />
          {tintRequired && (
            <Col span={20}>
              <Row gutter={10}>
                <FieldWrapper
                  name="specification"
                  placeholder="Tint Specification"
                  data={tintSpecificationData}
                  component={CustomSelect}
                  getSelectedValue={this.getTintSpecification}
                  allowClear={true}
                  labelInValue={labelInValue}
                  showSearch={true}
                  heading="Tint Specification"
                  mandatory={false}
                  width={6}
                  type="select"
                />

                <FieldWrapper
                  name="tint"
                  placeholder="Tint Description"
                  data={tintData}
                  component={CustomSelect}
                  showSearch={true}
                  allowClear={true}
                  labelInValue={labelInValue}
                  getSelectedValue={this.handleTintDescription}
                  heading="Tint Description"
                  mandatory={false}
                  width={10}
                  description={tintDescription}
                  type="select"
                />
              </Row>
            </Col>
          )}
        </Row>
        <Row style={{ marginTop: "3%" }}>
          <Col span={4}>
            <Header sub color="teal" content="Fitting Needed" />
            <Form.Group inline style={{ marginTop: "15px" }}>
              <Field
                name="fitting_needed"
                id="fitting_needed"
                //isChecked={true}
                component="input"
                type="checkbox"
                onChange={this.handleFitting}
              />
            </Form.Group>
          </Col>

          {fittingRequired && (
            <Col span={20}>
              <Grid.Row style={{ display: "flex" }}>
                <FieldWrapper
                  name="fitting_received"
                  type="radio"
                  valueOne="true"
                  labelOne="Fitting Received By Lab"
                  valueTwo="false"
                  labelTwo="Fitting awaited by Lab"
                  component={RadioInput}
                  heading="Fitting Status"
                  mandatory={false}
                  width={12}
                />
              </Grid.Row>
            </Col>
          )}
        </Row>

        <Row gutter={14} style={{ marginTop: "3%" }}>
          {canViewSpecialInstruction ? (
            <FieldWrapper
              name="special_instructions"
              type="text"
              component={TextInput}
              placeholder="Special Instructions"
              onFocus={() => window.scrollTo(0, 1000)}
              mandatory={false}
              heading="Special Instructions"
              width={6}
            />
          ) : null}
          <FieldWrapper
            name="in_out"
            type="text"
            component={TextInput}
            placeholder="In Out"
            mandatory={false}
            heading="In Out"
            width={6}
          />
          <FieldWrapper
            name="other_charges"
            type="text"
            component={TextInput}
            placeholder="Other Charges"
            mandatory={false}
            heading="Other Charges"
            width={6}
          />

          <FieldWrapper
            name="tool_charges"
            type="text"
            component={TextInput}
            placeholder="Tool Charge"
            mandatory={false}
            heading="Tool Charge"
            width={6}
          />
        </Row>
      </Row>
    );
  }
}
const mapStateToProps = (state) => ({
  tintRequired: state.orderForm.tintRequired,
  fittingRequired: state.orderForm.fittingRequired,
  tintSpecification: state.orderForm.tintSpecification,
  tintDescription: state.orderForm.tintDescription,
  pgDependentFields: getPgDataForSelectedLnam(state),
});
const actions = {
  setTintCheckboxTrue,
  setTintCheckboxFalse,
  setFittingCheckboxTrue,
  setFittingCheckboxFalse,
  setTintSpecification,
  setTintDescription,
};
export default connect(
  mapStateToProps,
  actions
)(AddOns);
