import {
  GET_LINKING_REASONS_START,
  GET_LINKING_REASONS_SUCCESS,
  GET_LINKING_REASONS_FAILURE,
  CHECK_LINKED_ORDER_START,
  CHECK_LINKED_ORDER_SUCCESS,
  CHECK_LINKED_ORDER_FAILURE,
  LINK_ORDERS_START,
  LINK_ORDERS_SUCCESS,
  LINK_ORDERS_FAILURE,
  SET_DATA_FOR_LINKING_FORM,
  GET_DETAILS_FOR_LINKING_FORM_START,
  GET_DETAILS_FOR_LINKING_FORM_SUCCESS,
  GET_DETAILS_FOR_LINKING_FORM_FAILURE,
  SET_LINKED_ORDER_ID,
} from "./linkingConstants";
import {
  linkingReasonsApiCall,
  getLinkedOrderDetails,
  linkOrdersApicall,
} from "./api";
import {
  errorToast,
  ErrorModal,
  confirmModal,
} from "../../app/common/util/genericUtils";
import { reportError } from "../../app/common/util/sentryConfig";
import { closeModal, openModal } from "../modal/modalActions";
import { setFormFields } from "../home/homeActions";
import { makeFormData } from "../orderPunching/formUtils";
import { push } from "connected-react-router";
import moment from "moment";
import { orderDetails } from "../home/api";
import {
  checkDuplicateOrdersStart,
  checkDuplicateOrdersSuccess,
  checkDuplicateOrdersFailure,
  formSubmitStart,
  formSubmitSuccess,
  formSubmitFailure,
  getPgConfig,
} from "../orderPunching/orderActions";
import { payloadForOrderPunching } from "../../app/common/form/payloadUtils";
import {
  duplicateOrdersApiCall,
  formSubmitApiCall,
} from "../orderPunching/api";
import { message } from "antd";
import { ORDER_TYPES } from "../../app/common/util/genericConstants";
export const getLinkingReasonsStart = () => {
  return {
    type: GET_LINKING_REASONS_START,
  };
};
export const getLinkingReasonsSuccess = (data) => {
  return {
    type: GET_LINKING_REASONS_SUCCESS,
    payload: data,
  };
};
export const getLinkingReasonsFailure = () => {
  return {
    type: GET_LINKING_REASONS_FAILURE,
  };
};

export const getLinkingReasons = () => async (dispatch) => {
  dispatch(getLinkingReasonsStart());
  return linkingReasonsApiCall()
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(getLinkingReasonsSuccess(response.data.data));
      } else {
        errorToast("Error", response.data.ui_message);
        dispatch(getLinkingReasonsFailure());
      }
    })
    .catch((error) => {
      dispatch(getLinkingReasonsFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const checkLinkedOrderStart = () => {
  return {
    type: CHECK_LINKED_ORDER_START,
  };
};

export const checkLinkedOrderSuccess = () => {
  return {
    type: CHECK_LINKED_ORDER_SUCCESS,
  };
};
export const checkLinkedOrderFailure = () => {
  return {
    type: CHECK_LINKED_ORDER_FAILURE,
  };
};
export const setLinkedOrderId = (payload) => {
  return {
    type: SET_LINKED_ORDER_ID,
    payload,
  };
};
export const checkLinkedOrder = (id, type, linkingOrderId) => async (
  dispatch
) => {
  dispatch(checkLinkedOrderStart());
  return getLinkedOrderDetails(id)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(checkLinkedOrderSuccess());
        console.log("res", response.data);
        if (response.data.data.linked_order === null) {
          if (response.data.data.is_stock === true) {
            ErrorModal(
              "Linking Error",
              "The entered order is a stock order and currently stock order linking is not supported."
            );
          } else {
            dispatch(closeModal());
            dispatch(setLinkedOrderId(response.data.data.id));
            if (type && type !== undefined && type === "linkOldOrder") {
              dispatch(openModal("LinkingReasonModal", { linkingOrderId }));
            } else {
              confirmModal(
                "Do You want to copy th content of this order ?",
                "Yes",
                "No",
                () => dispatch(copyContents(response.data.data)),
                () => dispatch(redirectWithoutData()),
                ""
              );
            }
          }
        } else {
          ErrorModal(
            "Linking Error",
            `The order is already linked to ${
              response.data.data.linked_order.internal_order_id
            }. This order can't be linked again, please enter other order.`
          );
        }
      } else {
        dispatch(checkLinkedOrderFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(checkLinkedOrderFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const linkOrdersStart = () => {
  return {
    type: LINK_ORDERS_START,
  };
};
export const linkOrdersSuccess = () => {
  return {
    type: LINK_ORDERS_SUCCESS,
  };
};
export const linkOrdersFailure = () => {
  return {
    type: LINK_ORDERS_FAILURE,
  };
};
export const linkOrders = (data) => async (dispatch) => {
  dispatch(linkOrdersStart());
  message.info("Linking Orders");
  return linkOrdersApicall(data)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(linkOrdersSuccess());
        message.success("Orders Linked Successfully");
        dispatch(push("/"));
        dispatch(closeModal());
      } else {
        dispatch(linkOrdersFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(linkOrdersFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const setDataForLinkingForm = (data) => {
  return {
    type: SET_DATA_FOR_LINKING_FORM,
    payload: data,
  };
};

export const copyContents = (data) => async (dispatch) => {
  if (data.product_group_owner !== null) {
    message.info("Preparing Form");
    dispatch(setFormFields(data));
    console.log(data);
    await dispatch(getPgConfig(data.lnam.id, data.product_group.id));
    const formData = makeFormData(data);
    dispatch(setDataForLinkingForm(formData));
    message.success("Form prepared successfully");
    dispatch(push(`/create/linked_order/${ORDER_TYPES.RX}/${data.id}`));
  } else {
    errorToast("Error", "Old order, product group does not exist.");
  }
};

export const redirectWithoutData = () => (dispatch) => {
  const initialValues = {
    shipping_same_as_billing: "true",
    urgent: "false",
    received_date: moment(),
  };
  dispatch(setDataForLinkingForm(initialValues));
  dispatch(push(`/create/${ORDER_TYPES.RX}/new_linked_order`));
};

export const getDetailsForLinkingFormStart = () => {
  return {
    type: GET_DETAILS_FOR_LINKING_FORM_START,
  };
};
export const getDetailsForLinkingFormSuccess = (data) => {
  return {
    type: GET_DETAILS_FOR_LINKING_FORM_SUCCESS,
    payload: data,
  };
};
export const getDetailsForLinkingFormFailure = () => {
  return {
    type: GET_DETAILS_FOR_LINKING_FORM_FAILURE,
  };
};

export const getDetailsForLinkingForm = (id, index) => async (dispatch) => {
  dispatch(getDetailsForLinkingFormStart());
  return orderDetails(id)
    .then((response) => {
      console.log("Order Details", response);
      if (response && response.data.response_code === 80) {
        const data = response.data.data;
        dispatch(setFormFields(data));
        console.log(data);
        const formData = makeFormData(data);
        dispatch(setLinkedOrderId(response.data.data.id));
        dispatch(getDetailsForLinkingFormSuccess(formData));
        dispatch(push(`/create/linked_order/${ORDER_TYPES.RX}/${data.id}`));
      } else {
        dispatch(getDetailsForLinkingFormFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(getDetailsForLinkingFormFailure());
      console.log(error);
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const checkDuplicateOrder = (formData) => async (dispatch) => {
  dispatch(checkDuplicateOrdersStart());
  message.info("Checking Duplicate Orders");
  const data = payloadForOrderPunching(formData);
  console.log("Form data", JSON.stringify(data));
  return duplicateOrdersApiCall(data)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(checkDuplicateOrdersSuccess());
        dispatch(punchLinkingOrder(data));
        message.success("No Duplicate Order Found");
      } else if (response && response.data.response_code === 90) {
        message.error("Duplicate Order Found");
        dispatch(checkDuplicateOrdersSuccess(data));
        dispatch(
          openModal(
            "DuplicateOrderListModal",
            Object.assign(
              {},
              { orderList: response.data.data, type: "linking" }
            )
          )
        );
      } else {
        dispatch(checkDuplicateOrdersFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(checkDuplicateOrdersFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const punchLinkingOrder = (data) => async (dispatch) => {
  dispatch(formSubmitStart());
  message.info("Punching New Order");
  return formSubmitApiCall(data)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(formSubmitSuccess());
        message.success("New Order Punched");
        console.log("linking order id, ", response.data.data);
        dispatch(
          openModal("LinkingReasonModal", {
            linkingOrderId: response.data.data.id,
          })
        );
      } else {
        dispatch(formSubmitFailure());
        errorToast("Error", response.data.ui_message);
        window.scrollTo(0, 0);
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(formSubmitFailure(error));
      errorToast("Error", error.message);
      reportError();
    });
};
