import React, { Component } from "react";
import { Row, Col, Spin, Button } from "antd";
import { FieldArray, formValueSelector } from "redux-form";
import FieldWrapper from "../FieldWrapper";
import DropDown from "../../../app/common/form/DropDown";
import { Icon, Label } from "semantic-ui-react";
import TextInput from "../../../app/common/form/TextInput";
import { connect } from "react-redux";
import {
  combineDateTime,
  getPgConfigIdentifier,
  getSelectedProductGroupData,
} from "../formUtils";
import { API_STATUS, errorToast } from "../../../app/common/util/genericUtils";
import { getPgConfig, isValidStockPower, submitForm } from "../orderActions";
import lodashGet from "lodash/get";
import { ORDER_TYPES } from "../../../app/common/util/genericConstants";
import { withRouter } from "react-router-dom";
import { payloadForOrderPunching } from "../../../app/common/form/payloadUtils";

const normalizeToPowerFormat = (
  newValue,
  prevVal,
  allVal,
  prevAllVal,
  name
) => {
  let val =
    newValue.indexOf(".") === -1 ? Number(newValue) / 100 : Number(newValue);

  let transformedVal;

  if (newValue > 0) {
    if (newValue > 0 && newValue < 10) {
      transformedVal = "+0" + parseFloat(val).toFixed(2);
    } else {
      transformedVal = "+" + parseFloat(val).toFixed(2);
    }
  } else if (newValue < 0) {
    transformedVal = String(parseFloat(val).toFixed(2));
  } else if (newValue === 0) {
    transformedVal = String(parseFloat(val).toFixed(2));
  } else if (!newValue) {
    if (name.includes("sph") || name.includes("cyl")) {
      transformedVal = "0.00";
    }
  }

  return transformedVal;
};

class LensSectionItem extends Component {
  componentDidMount() {
    const { change, index } = this.props;
    window.scrollBy(0, 200);

    change(`lens[${index}].dia`, "65");
    change(`lens[${index}].ellh`, "65");
  }

  handleResetFields = (fields = []) =>
    fields.forEach((field) => this.props.change(field, ""));

  handleStockDescriptionChange = (lensIndex) => async (stockDescription) => {
    const { formState, change, getPgConfig } = this.props;

    if (stockDescription && stockDescription.key) {
      const selector = formValueSelector("orderForm");
      const productGroup = selector(formState, "product_group");
      const selectedProductGroup = await getSelectedProductGroupData(
        productGroup.key
      );
      console.log('3.', selectedProductGroup)

      const { stock_description = [] } = selectedProductGroup;

      let selectedStockDescriptionData = {};

      stock_description.forEach((description) => {
        if (description.id === stockDescription.key) {
          selectedStockDescriptionData = description;
        }
      });

      const lnamFieldValue = {
        label: `${selectedStockDescriptionData.lnam.name} - ${
          selectedStockDescriptionData.lnam.description
        }`,
        key: selectedStockDescriptionData.lnam.id,
      };

      const coatingFieldValue = {
        label: selectedStockDescriptionData.coating.value,
        key: selectedStockDescriptionData.coating.id,
      };

      change(`${lensIndex}.lnam`, lnamFieldValue);
      change(`${lensIndex}.coating`, coatingFieldValue);

      getPgConfig(lnamFieldValue.key, productGroup.key);
    } else {
      this.handleResetFields([`${lensIndex}.lnam`, `${lensIndex}.coating`]);
    }
  };

  getPGConfigData = () => {
    const { pgDependentFields, index, formState } = this.props;

    const pgId = lodashGet(
      formState,
      `form.orderForm.values.product_group.key`,
      null
    );
    const lnamId = lodashGet(
      formState,
      `form.orderForm.values.lens[${index}].lnam.key`,
      null
    );

    const configIdentifier = getPgConfigIdentifier(pgId, lnamId);

    return lodashGet(pgDependentFields, `${configIdentifier}`, {});
  };

  checkPowerRange = (value) => {
    const { index, change } = this.props;

    const pgConfigData = this.getPGConfigData();

    const { min_prescription: min, max_prescription: max } = pgConfigData;

    if (!min || !max) {
      errorToast(
        "Error",
        "Either config is not loaded or min max prescription is incorrect."
      );
      setTimeout(() => change(`lens[${index}].sph`, ""), 1000);
    } else {
      const normalizedSph = normalizeToPowerFormat(
        value,
        undefined,
        undefined,
        undefined,
        "sph"
      );
      const notValidPower = !isValidStockPower({
        min: parseFloat(min),
        max: parseFloat(max),
        sph: parseFloat(normalizedSph),
      });
      if (notValidPower) {
        errorToast(
          "Error",
          `Value of sph should lie b/w ${parseFloat(min)} & ${parseFloat(max)}`
        );
        setTimeout(() => change(`lens[${index}].sph`, ""), 1000);
      }
    }
  };

  render() {
    const {
      index,
      field,
      fields,
      labelInValue,
      stockDescriptionList,
    } = this.props;

    const pgConfigData = this.getPGConfigData();

    return (
      <div style={{ marginTop: 40 }} key={`${index}`}>
        {pgConfigData.status === API_STATUS.LOADING && (
          <div style={{ marginBottom: 20 }}>
            <Spin />
            <Label color="blue" style={{ marginLeft: "15px" }}>
              Please wait we are getting prescription config
            </Label>
          </div>
        )}
        {pgConfigData.status === API_STATUS.SUCCESS && (
          <Label color="green" style={{ marginBottom: 20 }}>
            Prescription config loaded.
          </Label>
        )}
        {pgConfigData.status === API_STATUS.ERROR && (
          <Label color="red" style={{ marginBottom: 20 }}>
            Prescription config not loaded.
          </Label>
        )}
        <Row gutter={10} style={{ display: "flex", alignItems: "center" }}>
          <FieldWrapper
            name={`${field}.stock_description`}
            component={DropDown}
            type="select"
            placeholder="Select Stock Description"
            data={stockDescriptionList}
            showSearch={true}
            allowClear={true}
            labelInValue={labelInValue}
            heading="Stock Description"
            mandatory={true}
            width={6}
            getSelectedValue={this.handleStockDescriptionChange(field)}
          />
          <FieldWrapper
            name={`${field}.lnam`}
            component={DropDown}
            type="select"
            placeholder="LNAM"
            data={[]}
            allowClear={true}
            showSearch={true}
            heading="LNAM"
            mandatory={true}
            width={8}
            description=""
            labelInValue={labelInValue}
            disabled={true}
          />
          <FieldWrapper
            name={`${field}.coating`}
            component={DropDown}
            type="select"
            placeholder="coating"
            data={[]}
            showSearch={true}
            allowClear={true}
            labelInValue={labelInValue}
            heading="Coating"
            mandatory={true}
            width={6}
            disabled={true}
          />
          <Col span={3}>
            <Row gutter={10}>
              <Icon
                name="plus square"
                color="black"
                size="large"
                style={{ cursor: "pointer", marginLeft: 20 }}
                onClick={() => {
                  if (fields.length < 5) {
                    fields.push({});
                  }
                }}
              />
              {index > 0 && (
                <Icon
                  name="window close"
                  color="black"
                  size="large"
                  style={{ cursor: "pointer", marginLeft: 20 }}
                  onClick={() => fields.remove(index)}
                />
              )}
            </Row>
          </Col>
        </Row>
        <Row gutter={10} style={{ marginTop: 20 }}>
          <FieldWrapper
            name={`${field}.sph`}
            type="text"
            component={TextInput}
            placeholder="SPH"
            handleOnBlur={this.checkPowerRange}
            mandatory={true}
            heading="SPH"
            width={3}
            normalize={normalizeToPowerFormat}
          />

          <FieldWrapper
            name={`${field}.cyl`}
            type="text"
            component={TextInput}
            placeholder="CYL"
            mandatory={false}
            heading="CYL"
            width={3}
            normalize={normalizeToPowerFormat}
          />
          <FieldWrapper
            name={`${field}.axis`}
            type="text"
            component={TextInput}
            placeholder="Axis"
            mandatory={false}
            heading="AXIS"
            width={3}
          />
          {pgConfigData && pgConfigData.add === true && (
            <FieldWrapper
              name={`${field}.add`}
              type="text"
              component={TextInput}
              placeholder="ADD_R"
              mandatory={true}
              heading="ADD"
              width={3}
              normalize={normalizeToPowerFormat}
            />
          )}
          <FieldWrapper
            name={`${field}.qty`}
            type="text"
            component={TextInput}
            placeholder="Quantity"
            mandatory={true}
            heading="Quantity"
            width={3}
          />
        </Row>
      </div>
    );
  }
}

class LensSectionWrapper extends Component {
  componentDidMount() {
    this.props.fields.push({});
  }

  render() {
    const { fields } = this.props;

    return fields.map((field, index) => {
      return (
        <LensSectionItem
          {...this.props}
          field={field}
          index={index}
          key={`${index}`}
        />
      );
    });
  }
}

const mapStateToProps = (state) => ({
  formState: state,
  stockDescriptionList: state.orderForm.stockDescriptionList,
  pgDependentFields: state.orderForm.pgDependentFields,
  productGroupOwner: state.orderForm.productGroupOwner,
});

const actions = {
  getPgConfig,
  submitForm,
};

const LensSection = connect(
  mapStateToProps,
  actions
)(LensSectionWrapper);

class MultiStockLensSectionWrapper extends Component {
  handleSaveAndNew = (values) => {
    const { productGroupOwner, submitForm, change } = this.props;
    Object.assign(values, {
      status: "processing",
      source: "manual",
      order_source: ORDER_TYPES.STOCK_MULTI,
      product_group_owner: productGroupOwner,
    });
    const payload = combineDateTime(values);
    const apiPayload = payloadForOrderPunching(payload);

    const callBack = () => {
      change("lens", [{}]);
    };

    submitForm(apiPayload, callBack);
  };

  handleSaveAndExit = (values) => {
    const { productGroupOwner, submitForm, history } = this.props;
    Object.assign(values, {
      status: "processing",
      source: "manual",
      order_source: ORDER_TYPES.STOCK_MULTI,
      product_group_owner: productGroupOwner,
    });
    const payload = combineDateTime(values);
    const apiPayload = payloadForOrderPunching(payload);

    const callBack = () => {
      history.push("/");
    };

    submitForm(apiPayload, callBack);
  };

  render() {
    const { labelInValue, change, handleSubmit, invalid } = this.props;
    return (
      <div style={{ marginBottom: 40 }}>
        <FieldArray
          name="lens"
          component={LensSection}
          labelInValue={labelInValue}
          change={change}
        />
        <Row style={{ marginTop: 40 }}>
          <Button
            onClick={handleSubmit(this.handleSaveAndNew)}
            disabled={invalid}
            style={{ marginRight: 30 }}
            size="large"
          >
            Save and Enter New
          </Button>
          <Button
            onClick={handleSubmit(this.handleSaveAndExit)}
            disabled={invalid}
            size="large"
          >
            Save and Exit
          </Button>
        </Row>
      </div>
    );
  }
}

const MultiStockLensSection = connect(
  mapStateToProps,
  actions
)(withRouter(MultiStockLensSectionWrapper));

export { MultiStockLensSection };
