import React, { Component } from "react";
import { Col, Row, message } from "antd";
import { Header, Form, Label } from "semantic-ui-react";
import { Field, touch, formValueSelector } from "redux-form";
import TextInput from "../../../app/common/form/TextInput";
import { getPgDataForSelectedLnam, lensConfig } from "../formUtils";
import { connect } from "react-redux";
import {
  enableLeftPrism,
  disableLeftPrism,
  transformOnBlur,
  setLeftEyeCheckBox,
  copyRightEyeFieldsToLeft,
  getLocationForEye,
  checkTotalPowerForRX,
  checkTotalPowerForStock,
} from "../orderActions";
import CustomSelect from "../../../app/common/form/CustomSelect";
import { mbaseList } from "../constants";
import FieldWrapper from "../FieldWrapper";
import { getUserType, errorToast } from "../../../app/common/util/genericUtils";
import { ORDER_TYPES } from "../../../app/common/util/genericConstants";
class LeftEyeConfig extends Component {
  state = {
    userType: getUserType(),
  };
  checkEyeField = (e, value) => {
    const {
      enableLeftPrism,
      disableLeftPrism,
      monoPdL,
      npdL,
      change,
      copyRightEyeFieldsToLeft,
      setLeftEyeCheckBox,
      swing,
    } = this.props;
    setLeftEyeCheckBox(value);
    if (value === true) {
      swing === true && change("left_lens_config.swing", "15");
      copyRightEyeFieldsToLeft();
      enableLeftPrism();
      change("frame.mono_pd_l", monoPdL);
      change("frame.npd_l", npdL);
      change("left_lens_config.dia", "65");
      change("left_lens_config.ellh", "65");
      touch("orderForm", "left_lens_config.dia");
      touch("orderForm", "left_lens_config.ellh");
    } else {
      change("left_lens_config.swing", "");
      disableLeftPrism();
      lensConfig.forEach((config) => {
        change(`left_lens_config.${config}`, "");
      });
      change("frame.mono_pd_l", "");
      change("frame.npd_l", "");
      change("left_lens_config.dia", "");
      change("left_lens_config.ellh", "");
    }
  };
  changeEllhWithDia = (newValue, name) => {
    const { change, touch } = this.props;
    change("left_lens_config.ellh", newValue);
    touch("orderForm", "left_lens_config.dia");
    touch("orderForm", "left_lens_config.ellh");
  };

  checkPower = (newValue, name) => {
    this.props.transformOnBlur(newValue, name);
  };
  handleMbase = (obj) => {
    if (obj && obj.key !== undefined) {
      let { userType } = this.state;
      if (userType && userType !== "Corporate") {
        message.warning(
          "Only enter a value here if customer has specifically requested a base. \nLab’s lens calculation optimizes for thickness and base automatically. \nIf you enter a base value here, unless it is impossible to make power in that base, the lens calculator will use this base provided, and the lab can’t guarantee the thinnest possible lens in this case",
          15
        );
      }
    }
  };
  handleCtR = (newValue, name) => {
    this.props.transformOnBlur(newValue, name);
    let { userType } = this.state;
    if (userType && userType !== "Corporate") {
      message.warning(
        "Our system has a minimum thickness recommendation for lens quality and life. If you enter a value lower than what is calculated by the system at the time of processing at the lab, value entered by you will be ignored, Please acknowledge.",
        10
      );
    }
  };
  handleEtr = (newValue, name) => {
    this.props.transformOnBlur(newValue, name);
    let { userType } = this.state;
    if (userType && userType !== "Corporate") {
      message.warning(
        "Our system has a minimum thickness recommendation for lens quality and life. If you enter a value lower than what is calculated by the system at the time of processing at the lab, value entered by you will be ignored, Please acknowledge.",
        10
      );
    }
  };

  handleSph = (newValue, name) => {
    const {
      change,
      transformOnBlur,
      checkTotalPowerForStock,
      orderType,
    } = this.props;
    transformOnBlur(newValue, name);
    change("left_lens_config.add", "");
    orderType === ORDER_TYPES.STOCK_SINGLE &&
      checkTotalPowerForStock("left_lens_config");
    // change("left_lens_config.cyl", "");
  };

  handleCyl = (newValue, name) => {
    const {
      formState,
      change,
      getLocationForEye,
      orderType,
      checkTotalPowerForRX,
    } = this.props;
    const selector = formValueSelector("orderForm");
    const customer = selector(formState, "customer");
    const distributor = selector(formState, "distributor");
    const productGroup = selector(formState, "product_group");
    const lnam = selector(formState, "lnam");
    const coating = selector(formState, "coating");
    const sph = selector(formState, "left_lens_config.sph");
    if (
      customer &&
      customer.key &&
      productGroup &&
      productGroup.key &&
      lnam &&
      lnam.key &&
      coating &&
      lnam.key &&
      sph
    ) {
      const callBack = (transformedVal) => {
        if (orderType === ORDER_TYPES.RX) {
          const payload = {
            customer_id: customer.key,
            distributor_id:
              distributor && distributor.key ? distributor.key : null,
            product_group_id: productGroup.key,
            lnam_id: lnam.key,
            coating_id: coating.key,
            total_power: Number(sph) + Number(transformedVal),
          };
          getLocationForEye(payload, "left");
        }
      };
      this.props.transformOnBlur(newValue, name, callBack);
      orderType === ORDER_TYPES.RX && checkTotalPowerForRX("left_lens_config");
    } else {
      errorToast(
        "Customer, Product Group, Lnam, Coating and Sph are mandatory"
      );
      setTimeout(() => change("left_lens_config.cyl", ""), 1000);
    }
  };
  render() {
    const {
      leftEyeCheckbox,
      labelInValue,
      swing,
      pgDependentFields,
    } = this.props;
    return (
      <Col span={12} style={{ background: "aliceblue", padding: "24px" }}>
        <Row>
          <Col span={2}>
            <Header sub color="teal" content="LE" />
            <Form.Group inline style={{ marginTop: "15px" }}>
              <Field
                id="le"
                name="le"
                //isChecked={true}
                component="input"
                type="checkbox"
                onChange={this.checkEyeField}
              />
            </Form.Group>
          </Col>
          <Col>
            {leftEyeCheckbox === false && (
              <Label basic color="red">
                Select LE to enter values
              </Label>
            )}
          </Col>
        </Row>
        <Row gutter={10}>
          <FieldWrapper
            name="sph"
            type="text"
            component={TextInput}
            placeholder="SPH_L"
            disabled={!leftEyeCheckbox}
            handleOnBlur={this.handleSph}
            mandatory={true}
            heading="SPH_L"
            width={6}
          />
          <FieldWrapper
            name="cyl"
            type="text"
            component={TextInput}
            placeholder="CYL_L"
            disabled={!leftEyeCheckbox}
            handleOnBlur={this.handleCyl}
            mandatory={false}
            heading="CYL_L"
            width={6}
          />
          <FieldWrapper
            name="axis"
            type="text"
            component={TextInput}
            placeholder="Axis_L"
            disabled={!leftEyeCheckbox}
            mandatory={false}
            heading="AXIS_L"
            width={6}
          />
          {/* showAddForLnam === true && */}
          {pgDependentFields.add === true && leftEyeCheckbox === true && (
            <FieldWrapper
              name="add"
              type="text"
              component={TextInput}
              placeholder="ADD_L"
              disabled={!leftEyeCheckbox}
              handleOnBlur={this.checkPower}
              mandatory={true}
              heading="ADD_L"
              width={6}
            />
          )}

          {leftEyeCheckbox === true && (
            <FieldWrapper
              name="dia"
              type="text"
              component={TextInput}
              placeholder="DIA_L"
              disabled={!leftEyeCheckbox}
              handleOnBlur={this.changeEllhWithDia}
              mandatory={true}
              heading="DIA_L"
              width={6}
            />
          )}
          {pgDependentFields.ellh === true && (
            <FieldWrapper
              name="ellh"
              type="text"
              component={TextInput}
              placeholder="ELLH_L"
              disabled={!leftEyeCheckbox}
              mandatory={false}
              heading="ELLH_L"
              width={6}
            />
          )}
          {leftEyeCheckbox === true && swing === true && (
            <FieldWrapper
              name="swing"
              type="text"
              component={TextInput}
              placeholder="SWING_L"
              disabled={!leftEyeCheckbox || !swing}
              mandatory={false}
              heading="SWING_L"
              width={6}
            />
          )}
          <FieldWrapper
            name="mbase"
            type="select"
            component={CustomSelect}
            placeholder="MBASE_L"
            disabled={!leftEyeCheckbox}
            labelInValue={labelInValue}
            data={mbaseList}
            showSearch={true}
            getSelectedValue={this.handleMbase}
            mandatory={false}
            heading="MBASE_L"
            width={6}
            allowClear={true}
          />
          <FieldWrapper
            name="ct"
            type="text"
            component={TextInput}
            placeholder="CT_L"
            disabled={!leftEyeCheckbox}
            handleOnBlur={this.handleCtR}
            mandatory={false}
            heading="CT_L"
            width={6}
          />
          <FieldWrapper
            name="et"
            type="text"
            component={TextInput}
            placeholder="ET_L"
            disabled={!leftEyeCheckbox}
            handleOnBlur={this.handleEtr}
            mandatory={false}
            heading="ET_L"
            width={6}
          />
          <FieldWrapper
            name="ed"
            type="text"
            component={TextInput}
            placeholder="ed_L"
            disabled={!leftEyeCheckbox}
            mandatory={false}
            heading="ED_L"
            width={6}
          />
        </Row>
      </Col>
    );
  }
}
const actions = {
  enableLeftPrism,
  disableLeftPrism,
  transformOnBlur,
  setLeftEyeCheckBox,
  touch,
  copyRightEyeFieldsToLeft,
  getLocationForEye,
  checkTotalPowerForRX,
  checkTotalPowerForStock,
};
const mapStateToProps = (state) => ({
  formState: state,
  showAddForLnam: state.orderForm.showAddForLnam,
  npdL: state.orderForm.npd,
  monoPdL: state.orderForm.monoPd,
  swing: state.orderForm.swing,
  leftEyeCheckbox: state.orderForm.leftEyeCheckbox,
  addFieldIncludeFlag: state.orderForm.addFieldIncludeFlag,
  pgDependentFields: getPgDataForSelectedLnam(state),
});
export default connect(
  mapStateToProps,
  actions
)(LeftEyeConfig);
