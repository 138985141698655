import React, { Component } from "react";
import { connect } from "react-redux";

import WhatsappModal from "./WhatsappModal";
import HoldModal from "./HoldModal";
import CancellationModal from "./CancellationModal";
import DuplicateOrderListModal from "./DuplicateOrderListModal";
import DuplicateOrderDetailsModal from "./DuplicateOrderDetailsModal";
import WhatsappImageModal from "./WhatsappImageModal";
import OrderSelectionModal from "./OrderSelectionModal";
import MultipleBlankReasonModal from "./MultipleBlankReasonModal";
import CreateLabelModal from "./CreateLabelModal";
import LinkedOrderModal from "./LinkedOrderModal";
import LinkingReasonModal from "./LinkingReasonModal";
import CreateNotificationModal from "./CreateNotificationModal";
import OrderValidationModal from "./OrderValidationModal";
//import ValidateFromHomeModal from "./ValidateFromHomeModal";
import StockProcessModal from "./StockProcessModal";
import MultiStockOrderListModal from "./MultiStockOrderListModal";

const mapStateToProps = (state) => ({
  currentModal: state.modals,
});
const modalLookUp = {
  HoldModal,
  WhatsappModal,
  CancellationModal,
  DuplicateOrderListModal,
  DuplicateOrderDetailsModal,
  WhatsappImageModal,
  OrderSelectionModal,
  MultipleBlankReasonModal,
  CreateLabelModal,
  LinkedOrderModal,
  LinkingReasonModal,
  CreateNotificationModal,
  OrderValidationModal,
  StockProcessModal,
  MultiStockOrderListModal,
  //ValidateFromHomeModal
};

export class ModalManager extends Component {
  render() {
    const { currentModal } = this.props;
    let renderedModal;

    if (currentModal) {
      const { modalType, modalProps } = currentModal;
      const ModalComponent = modalLookUp[modalType];

      renderedModal = <ModalComponent {...modalProps} />;
    }
    return <span>{renderedModal}</span>;
  }
}
export default connect(mapStateToProps)(ModalManager);
