import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Icon, Button, Label } from "semantic-ui-react";
import Sidebar from "../../app/common/util/Sidebar";
import { connect } from "react-redux";
import NavBar from "../nav/NavBar/NavBar";
import OrderForm from "./OrderForm";
import { fetchFormData } from "./orderActions";
import LoaderComponent from "../../app/common/util/LoaderComponent";
import { doesFormDataExist } from "./formUtils";
import moment from "moment";
import { messages } from "./constants";
import { getUserType } from "../../app/common/util/genericUtils";
class OrdersPage extends Component {
  state = {};
  componentWillMount() {
    const dataFlag = doesFormDataExist();
    dataFlag && this.props.fetchFormData();
  }
  handleMessages = () => {
    return (
      <div style={{ margin: "2% 0 0 10%" }}>
        {messages.map((message, index) => {
          return (
            <Label
              color="violet"
              basic
              key={index}
              style={{ margin: "0 1% 1% 0" }}
            >
              {message}
            </Label>
          );
        })}
      </div>
    );
  };
  render() {
    if (this.props.loadingFormData) {
      return <LoaderComponent />;
    }
    const userType = getUserType();
    console.log(userType);
    return (
      <div>
        <Sidebar />
        <Button
          as={Link}
          to="/"
          icon
          labelPosition="left"
          style={{ margin: "5% 0 0 10%" }}
        >
          <Icon name="left arrow" />
          Back to Orders
        </Button>
        {userType === "Branch" || userType === "Customer" ? (
          this.handleMessages()
        ) : (
          <span />
        )}
        <OrderForm
          type="create"
          labelInValue={true}
          initialValues={
            userType === "Corporate"
              ? initialValues
              : initialValForBranchAndCustomer
          }
        />
        <NavBar />
      </div>
    );
  }
}
const actions = {
  fetchFormData,
};
const mapStateToProps = (state) => ({
  loadingFormData: state.orderForm.loadingFormData,
});

export default connect(
  mapStateToProps,
  actions
)(OrdersPage);

const initialValues = {
  shipping_same_as_billing: "true",
  urgent: "false",
  received_date: moment(),
};
const initialValForBranchAndCustomer = {
  shipping_same_as_billing: "true",
  urgent: "false",
};
