import {
  FETCH_ONHOLD_ORDERS_START,
  FETCH_ONHOLD_ORDERS_SUCCESS,
  FETCH_ONHOLD_ORDERS_FAILURE,
  FETCH_PENDING_AUTH_ORDERS_START,
  FETCH_PENDING_AUTH_ORDERS_SUCCESS,
  FETCH_PENDING_AUTH_ORDERS_FAILURE,
  FETCH_PROCESSING_ORDERS_START,
  FETCH_PROCESSING_ORDERS_SUCCESS,
  FETCH_PROCESSING_ORDERS_FAILURE,
  FETCH_CANCELLED_ORDERS_START,
  FETCH_CANCELLED_ORDERS_SUCCESS,
  FETCH_CANCELLED_ORDERS_FAILURE,
  FETCH_BLANK_SELECTION_ORDERS_START,
  FETCH_BLANK_SELECTION_ORDERS_SUCCESS,
  FETCH_BLANK_SELECTION_ORDERS_FAILURE,
  ONHOLD_STATUS_CHANGE_START,
  ONHOLD_STATUS_CHANGE_SUCCESS,
  ONHOLD_STATUS_CHANGE_FAILURE,
  GET_ORDER_DETAILS_START,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAILURE,
  VALIDATE_PENDING_AUTH_ORDERS_START,
  VALIDATE_PENDING_AUTH_ORDERS_SUCCESS,
  VALIDATE_PENDING_AUTH_ORDERS_FAILURE,
  PUT_ORDER_ON_HOLD_START,
  PUT_ORDER_ON_HOLD_SUCCESS,
  PUT_ORDER_ON_HOLD_FAILURE,
  SET_INDEX_FOR_ORDER,
  SET_CURRENT_PAGE,
  FETCH_CUSTOMER_WITHOUT_DIST_START,
  FETCH_CUSTOMER_WITHOUT_DIST_SUCCESS,
  FETCH_CUSTOMER_WITHOUT_DIST_FAILURE,
  SET_PAGE_FOR_CUSTOMER,
  FETCH_CUSTOMER_WITH_DIST_START,
  FETCH_CUSTOMER_WITH_DIST_SUCCESS,
  FETCH_CUSTOMER_WITH_DIST_FAILURE,
  SET_ACTIVE_TAB_KEY,
  CHECK_LENSWARE_STATUS_START,
  CHECK_LENSWARE_STATUS_SUCCESS,
  CHECK_LENSWARE_STATUS_FAILURE,
  RESET_HOME_STATE,
  FETCH_STOCK_ORDERS_LIST_START,
  FETCH_STOCK_ORDERS_LIST_SUCCESS,
  FETCH_STOCK_ORDERS_LIST_FAILURE,
  PRINT_STOCK_ORDER_START,
  PRINT_STOCK_ORDER_SUCCESS,
  PRINT_STOCK_ORDER_FAILURE,
  ARCHIVE_STOCK_ORDER_START,
  ARCHIVE_STOCK_ORDER_SUCCESS,
  ARCHIVE_STOCK_ORDER_FAILURE,
  CHECK_FORM_EDIT_STATUS_START,
  CHECK_FORM_EDIT_STATUS_SUCCESS,
  CHECK_FORM_EDIT_STATUS_FAILURE,
  UPDATE_ORDER_START,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
  GET_STOCK_PICKING_ORDERS_START,
  GET_STOCK_PICKING_ORDERS_SUCCESS,
  GET_STOCK_PICKING_ORDERS_FAILURE,
  GET_MULTI_STOCK_PICKING_ORDERS_START,
  GET_MULTI_STOCK_PICKING_ORDERS_SUCCESS,
  GET_MULTI_STOCK_PICKING_ORDERS_FAILURE,
  GET_MULTI_STOCK_GROUPED_ORDERS_START,
  GET_MULTI_STOCK_GROUPED_ORDERS_SUCCESS,
  GET_MULTI_STOCK_GROUPED_ORDERS_FAILURE,
} from "./homeConstants";
import { createReducer } from "../../app/common/util/reducerUtil";

const initialState = {
  loadingCustomerList: false,
  onHoldOrders: [],
  loadingOnHoldOrders: false,
  processingOrders: [],
  loadingProcessingOrders: false,
  pendingAuthOrders: [],
  loadingPendingAuthOrders: false,
  cancelledOrders: [],
  loadingCancelledOrders: false,
  blankSelectionOrders: [],
  loadingBlankSelectionOrders: false,
  orderDetails: {},
  validatingPendingAuth: false,
  putOnHoldLoader: false,
  indexForOrder: null,
  currentPage: null,
  pageForCustomer: 1,
  activeTabKey: "1",
  loadingOrderData: false,
  loadingLenswareStatus: false,
  loadingStockOrders: false,
  stockOrders: [],
  printingStockOrder: false,
  archivingStockOrder: false,
  checkingFormEditStatus: false,
  formEditStatus: null,
  updatingOrder: false,
  loadingStockPicking: false,
  stockPickingOrders: [],
  loadingMultiStockGroupedOrders: false,
  multiStockGroupedOrders: [],
  loadingMultiStockPicking: false,
  multiStockPickingOrders: [],
};
export const setActiveTabKey = (state, activeTabKey) => {
  return {
    ...state,
    activeTabKey,
  };
};
export const fetchCustomerWithoutDistStart = (state) => {
  return {
    ...state,
    loadingCustomerList: true,
  };
};

export const fetchCustomerWithoutDistSuccess = (state) => {
  return {
    ...state,
    loadingCustomerList: false,
  };
};

export const fetchCustomerWithoutDistFailure = (state) => {
  return {
    ...state,
    loadingCustomerList: false,
  };
};
export const fetchCustomerWithDistStart = (state) => {
  return {
    ...state,
    loadingCustomerList: true,
  };
};

export const fetchCustomerWithDistSuccess = (state) => {
  return {
    ...state,
    loadingCustomerList: false,
  };
};

export const fetchCustomerWithDistFailure = (state) => {
  return {
    ...state,
    loadingCustomerList: false,
  };
};
export const setPageForCustomer = (state, payload) => {
  return {
    ...state,
    pageForCustomer: payload,
  };
};
export const fetchOnHoldOrdersStart = (state) => {
  return {
    ...state,
    loadingOnHoldOrders: true,
  };
};

export const fetchOnHoldOrdersSuccess = (state, payload) => {
  return {
    ...state,
    onHoldOrders: payload,
    loadingOnHoldOrders: false,
  };
};
export const fetchOnHoldOrdersFailure = (state) => {
  return {
    ...state,
    onHoldOrders: [],
    loadingOnHoldOrders: false,
  };
};

export const fetchPendingAuthOrdersStart = (state) => {
  return {
    ...state,
    loadingPendingAuthOrders: true,
  };
};

export const fetchPendingAuthOrdersSuccess = (state, payload) => {
  return {
    ...state,
    pendingAuthOrders: payload,
    loadingPendingAuthOrders: false,
  };
};
export const fetchPendingAuthOrdersFailure = (state) => {
  return {
    ...state,
    pendingAuthOrders: [],
    loadingPendingAuthOrders: false,
  };
};

export const fetchProcessingOrdersStart = (state) => {
  return {
    ...state,
    loadingProcessingOrders: true,
  };
};

export const fetchProcessingOrdersSuccess = (state, payload) => {
  return {
    ...state,
    processingOrders: payload,
    loadingProcessingOrders: false,
  };
};
export const fetchProcessingOrdersFailure = (state) => {
  return {
    ...state,
    processingOrders: [],
    loadingProcessingOrders: false,
  };
};

export const fetchCancelledOrdersStart = (state) => {
  return {
    ...state,
    loadingCancelledOrders: true,
  };
};

export const fetchCancelledOrdersSuccess = (state, payload) => {
  return {
    ...state,
    cancelledOrders: payload,
    loadingCancelledOrders: false,
  };
};
export const fetchCancelledOrdersFailure = (state) => {
  return {
    ...state,
    cancelledOrders: [],
    loadingCancelledOrders: false,
  };
};

export const fetchBlankSelectionOrdersStart = (state) => {
  return {
    ...state,
    loadingBlankSelectionOrders: true,
  };
};

export const fetchBlankSelectionOrdersSuccess = (state, payload) => {
  return {
    ...state,
    blankSelectionOrders: payload,
    loadingBlankSelectionOrders: false,
  };
};
export const fetchBlankSelectionOrdersFailure = (state) => {
  return {
    ...state,
    blankSelectionOrders: [],
    loadingBlankSelectionOrders: false,
  };
};

export const onHoldStatusChangeStart = (state) => {
  return {
    ...state,
  };
};
export const onHoldStatusChangeSuccess = (state) => {
  return {
    ...state,
  };
};
export const onHoldStatusChangeFailure = (state) => {
  return {
    ...state,
  };
};

export const getOrderDetailsStart = (state) => {
  return {
    ...state,
    orderDetails: {},
    loadingOrderData: true,
  };
};
export const getOrderDetailsSuccess = (state, payload) => {
  return {
    ...state,
    orderDetails: payload,
    loadingOrderData: false,
  };
};
export const getOrderDetailsFailure = (state) => {
  return {
    ...state,
    orderDetails: {},
    loadingOrderData: false,
  };
};
export const validatePendingAuthOrdersStart = (state) => {
  return {
    ...state,
    validatingPendingAuth: true,
  };
};
export const validatePendingAuthOrdersSuccess = (state) => {
  return {
    ...state,
    validatingPendingAuth: false,
  };
};
export const validatePendingAuthOrdersFailure = (state) => {
  return {
    ...state,
    validatingPendingAuth: false,
  };
};
export const putOrderOnHoldStart = (state) => {
  return {
    ...state,
    putOnHoldLoader: true,
  };
};
export const putOrderOnHoldSuccess = (state) => {
  return {
    ...state,
    putOnHoldLoader: false,
  };
};
export const putOrderOnHoldFailure = (state) => {
  return {
    ...state,
    putOnHoldLoader: false,
  };
};
export const setIndexForOrder = (state, payload) => {
  return {
    ...state,
    indexForOrder: payload,
  };
};
export const setCurrentPage = (state, payload) => {
  return {
    ...state,
    currentPage: payload,
  };
};
export const checkLenswareStatusStart = (state) => {
  return {
    ...state,
    loadingLenswareStatus: true,
  };
};
export const checkLenswareStatusSuccess = (state) => {
  return {
    ...state,
    loadingLenswareStatus: false,
  };
};
export const checkLenswareStatusFailure = (state) => {
  return {
    ...state,
    loadingLenswareStatus: false,
  };
};
export const resetHomeState = (state) => {
  return {
    ...state,
    ...initialState,
  };
};
export const fetchStockOrdersListStart = (state) => {
  return {
    ...state,
    loadingStockOrders: true,
    stockOrders: [],
  };
};

export const fetchStockOrdersListSuccess = (state, payload) => {
  return {
    ...state,
    loadingStockOrders: false,
    stockOrders: payload,
  };
};

export const fetchStockOrdersListFailure = (state) => {
  return {
    ...state,
    loadingStockOrders: false,
    stockOrders: [],
  };
};
export const printStockOrderStart = (state) => {
  return {
    ...state,
    printingStockOrder: true,
  };
};
export const printStockOrderSuccess = (state) => {
  return {
    ...state,
    printingStockOrder: false,
  };
};
export const printStockOrderFailure = (state) => {
  return {
    ...state,
    printingStockOrder: false,
  };
};
export const archiveStockOrderStart = (state) => {
  return {
    ...state,
    archivingStockOrder: true,
  };
};
export const archiveStockOrderSuccess = (state) => {
  return {
    ...state,
    archivingStockOrder: false,
  };
};
export const archiveStockOrderFailure = (state) => {
  return {
    ...state,
    archivingStockOrder: false,
  };
};
export const checkFormEditStatusStart = (state) => {
  return {
    ...state,
    checkingFormEditStatus: true,
    formEditStatus: null,
  };
};
export const checkFormEditStatusSuccess = (state, status) => {
  return {
    ...state,
    checkingFormEditStatus: false,
    formEditStatus: status,
  };
};
export const checkFormEditStatusFailure = (state) => {
  return {
    ...state,
    checkingFormEditStatus: false,
    formEditStatus: null,
  };
};
export const updateOrderStart = (state) => {
  return {
    ...state,
    updatingOrder: true,
  };
};
export const updateOrderSuccess = (state) => {
  return {
    ...state,
    updatingOrder: false,
  };
};
export const updateOrderFailure = (state) => {
  return {
    ...state,
    updatingOrder: false,
  };
};

export const getStockPickingStart = (state) => {
  return {
    ...state,
    loadingStockPicking: true,
    stockPickingOrders: [],
  };
};
export const getStockPickingSuccess = (state, data) => {
  return {
    ...state,
    loadingStockPicking: false,
    stockPickingOrders: data,
  };
};
export const getStockPickingFailure = (state) => {
  return {
    ...state,
    loadingStockPicking: false,
    stockPickingOrders: [],
  };
};

export const getMultiStockGroupedOrdersStart = (state) => {
  return {
    ...state,
    loadingMultiStockGroupedOrders: true,
    multiStockGroupedOrders: [],
  };
};
export const getMultiStockGroupedOrdersSuccess = (state, data) => {
  return {
    ...state,
    loadingMultiStockGroupedOrders: false,
    multiStockGroupedOrders: data,
  };
};
export const getMultiStockGroupedOrdersFailure = (state) => {
  return {
    ...state,
    loadingMultiStockGroupedOrders: false,
    multiStockGroupedOrders: [],
  };
};

export const getMultiStockPickingStart = (state) => {
  return {
    ...state,
    loadingMultiStockPicking: true,
    multiStockPickingOrders: [],
  };
};
export const getMultiStockPickingSuccess = (state, data) => {
  return {
    ...state,
    loadingMultiStockPicking: false,
    multiStockPickingOrders: data,
  };
};
export const getMultiStockPickingFailure = (state) => {
  return {
    ...state,
    loadingMultiStockPicking: false,
    multiStockPickingOrders: [],
  };
};

export default createReducer(initialState, {
  [FETCH_CUSTOMER_WITHOUT_DIST_START]: fetchCustomerWithoutDistStart,
  [FETCH_CUSTOMER_WITHOUT_DIST_SUCCESS]: fetchCustomerWithoutDistSuccess,
  [FETCH_CUSTOMER_WITHOUT_DIST_FAILURE]: fetchCustomerWithoutDistFailure,
  [FETCH_CUSTOMER_WITH_DIST_START]: fetchCustomerWithDistStart,
  [FETCH_CUSTOMER_WITH_DIST_SUCCESS]: fetchCustomerWithDistSuccess,
  [FETCH_CUSTOMER_WITH_DIST_FAILURE]: fetchCustomerWithDistFailure,
  [SET_PAGE_FOR_CUSTOMER]: setPageForCustomer,
  [FETCH_ONHOLD_ORDERS_START]: fetchOnHoldOrdersStart,
  [FETCH_ONHOLD_ORDERS_SUCCESS]: fetchOnHoldOrdersSuccess,
  [FETCH_ONHOLD_ORDERS_FAILURE]: fetchOnHoldOrdersFailure,
  [FETCH_PENDING_AUTH_ORDERS_START]: fetchPendingAuthOrdersStart,
  [FETCH_PENDING_AUTH_ORDERS_SUCCESS]: fetchPendingAuthOrdersSuccess,
  [FETCH_PENDING_AUTH_ORDERS_FAILURE]: fetchPendingAuthOrdersFailure,
  [FETCH_PROCESSING_ORDERS_START]: fetchProcessingOrdersStart,
  [FETCH_PROCESSING_ORDERS_SUCCESS]: fetchProcessingOrdersSuccess,
  [FETCH_PROCESSING_ORDERS_FAILURE]: fetchProcessingOrdersFailure,
  [FETCH_CANCELLED_ORDERS_START]: fetchCancelledOrdersStart,
  [FETCH_CANCELLED_ORDERS_SUCCESS]: fetchCancelledOrdersSuccess,
  [FETCH_CANCELLED_ORDERS_FAILURE]: fetchCancelledOrdersFailure,
  [FETCH_BLANK_SELECTION_ORDERS_START]: fetchBlankSelectionOrdersStart,
  [FETCH_BLANK_SELECTION_ORDERS_SUCCESS]: fetchBlankSelectionOrdersSuccess,
  [FETCH_BLANK_SELECTION_ORDERS_FAILURE]: fetchBlankSelectionOrdersFailure,
  [ONHOLD_STATUS_CHANGE_START]: onHoldStatusChangeStart,
  [ONHOLD_STATUS_CHANGE_SUCCESS]: onHoldStatusChangeSuccess,
  [ONHOLD_STATUS_CHANGE_FAILURE]: onHoldStatusChangeFailure,
  [GET_ORDER_DETAILS_START]: getOrderDetailsStart,
  [GET_ORDER_DETAILS_SUCCESS]: getOrderDetailsSuccess,
  [GET_ORDER_DETAILS_FAILURE]: getOrderDetailsFailure,
  [VALIDATE_PENDING_AUTH_ORDERS_START]: validatePendingAuthOrdersStart,
  [VALIDATE_PENDING_AUTH_ORDERS_SUCCESS]: validatePendingAuthOrdersSuccess,
  [VALIDATE_PENDING_AUTH_ORDERS_FAILURE]: validatePendingAuthOrdersFailure,
  [PUT_ORDER_ON_HOLD_START]: putOrderOnHoldStart,
  [PUT_ORDER_ON_HOLD_SUCCESS]: putOrderOnHoldSuccess,
  [PUT_ORDER_ON_HOLD_FAILURE]: putOrderOnHoldFailure,
  [SET_INDEX_FOR_ORDER]: setIndexForOrder,
  [SET_CURRENT_PAGE]: setCurrentPage,
  [SET_ACTIVE_TAB_KEY]: setActiveTabKey,
  [CHECK_LENSWARE_STATUS_START]: checkLenswareStatusStart,
  [CHECK_LENSWARE_STATUS_SUCCESS]: checkLenswareStatusSuccess,
  [CHECK_LENSWARE_STATUS_FAILURE]: checkLenswareStatusFailure,
  [RESET_HOME_STATE]: resetHomeState,
  [FETCH_STOCK_ORDERS_LIST_START]: fetchStockOrdersListStart,
  [FETCH_STOCK_ORDERS_LIST_SUCCESS]: fetchStockOrdersListSuccess,
  [FETCH_STOCK_ORDERS_LIST_FAILURE]: fetchStockOrdersListFailure,
  [PRINT_STOCK_ORDER_START]: printStockOrderStart,
  [PRINT_STOCK_ORDER_SUCCESS]: printStockOrderSuccess,
  [PRINT_STOCK_ORDER_FAILURE]: printStockOrderFailure,
  [ARCHIVE_STOCK_ORDER_START]: archiveStockOrderStart,
  [ARCHIVE_STOCK_ORDER_SUCCESS]: archiveStockOrderSuccess,
  [ARCHIVE_STOCK_ORDER_FAILURE]: archiveStockOrderFailure,
  [CHECK_FORM_EDIT_STATUS_START]: checkFormEditStatusStart,
  [CHECK_FORM_EDIT_STATUS_SUCCESS]: checkFormEditStatusSuccess,
  [CHECK_FORM_EDIT_STATUS_FAILURE]: checkFormEditStatusFailure,
  [UPDATE_ORDER_START]: updateOrderStart,
  [UPDATE_ORDER_SUCCESS]: updateOrderSuccess,
  [UPDATE_ORDER_FAILURE]: updateOrderFailure,
  [GET_STOCK_PICKING_ORDERS_START]: getStockPickingStart,
  [GET_STOCK_PICKING_ORDERS_SUCCESS]: getStockPickingSuccess,
  [GET_STOCK_PICKING_ORDERS_FAILURE]: getStockPickingFailure,
  [GET_MULTI_STOCK_GROUPED_ORDERS_START]: getMultiStockGroupedOrdersStart,
  [GET_MULTI_STOCK_GROUPED_ORDERS_SUCCESS]: getMultiStockGroupedOrdersSuccess,
  [GET_MULTI_STOCK_GROUPED_ORDERS_FAILURE]: getMultiStockGroupedOrdersFailure,
  [GET_MULTI_STOCK_PICKING_ORDERS_START]: getMultiStockPickingStart,
  [GET_MULTI_STOCK_PICKING_ORDERS_SUCCESS]: getMultiStockPickingSuccess,
  [GET_MULTI_STOCK_PICKING_ORDERS_FAILURE]: getMultiStockPickingFailure,
});
