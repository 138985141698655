import React, { Component } from "react";
import { FormSection, reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { Row, Col, Button } from "antd";
import CustomSelect from "../../../app/common/form/CustomSelect";
import { Header, Form, Label } from "semantic-ui-react";
import { validate } from "./FormValidations";
import {
  getCustomerDistributorsFromId,
  getRetailersFromCustomerId,
  getDispatchList,
  getManufacturingUnitList,
  getTintList,
  tintSpecificationData,
  getTintDescription,
  MandatoryHeader,
  refactorPayload,
} from "../../orderPunching/formUtils";
import TextInput from "../../../app/common/form/TextInput";
import RadioInput from "../../../app/common/form/RadioInput";
import {
  setTintCheckboxTrue,
  setTintCheckboxFalse,
  setFittingCheckboxTrue,
  setFittingCheckboxFalse,
  setTintSpecification,
  setTintDescription,
  resetFields,
  setUrgency,
  checkCoatingTimeline,
} from "../../orderPunching/orderActions";
import DateInput from "../../../app/common/form/DateInput";
import { disablePreviousDates } from "../../../app/common/util/genericUtils";
import {
  checkFormEditStatus,
  setFormFields,
  updateOrder,
} from "../homeActions";
class EditOrderForm extends Component {
  state = {};
  async componentWillMount() {
    this.props.checkFormEditStatus(this.props.details.id);
    this.props.setFormFields(this.props.details);

  }
  dispatchList = getDispatchList();
  muList = getManufacturingUnitList();
  handleFitting = (e, value) => {
    const { setFittingCheckboxTrue, setFittingCheckboxFalse } = this.props;
    value === true ? setFittingCheckboxTrue() : setFittingCheckboxFalse();
  };
  handleTinting = (e, value) => {
    const { setTintCheckboxTrue, setTintCheckboxFalse } = this.props;
    value === true ? setTintCheckboxTrue() : setTintCheckboxFalse();
  };
  getTintSpecification = (val) => {
    const { tintSpecification, setTintSpecification } = this.props;
    if (val.key !== tintSpecification) {
      console.log(val.key, tintSpecification);
      this.props.change("add_ons.tint", "");
      setTintSpecification(val.key);
    } else {
      setTintSpecification(val.key);
    }
  };
  handleTintDescription = (obj) => {
    let description = getTintDescription(obj.key);
    this.props.setTintDescription(description);
  };
  handleUrgentField = (e, val) => {
    const { setUrgency } = this.props;
    val === "true" ? setUrgency(true) : setUrgency(false);
  };

  componentWillUnmount() {
    this.props.resetFields();
  }
  onFormSubmit = (data) => {
    const refactoredPayload = refactorPayload(data);
    refactoredPayload["status"] = "processing";
    console.log("edited data", refactoredPayload);
    this.props.updateOrder(this.props.id, refactoredPayload);
  };
  render() {
    const {
      tintRequired,
      fittingRequired,
      tintSpecification,
      tintDescription,
      urgent,
      checkCoatingTimeline,
      formEditStatus,
      checkingFormEditStatus,
      invalid,
      handleSubmit,
      updatingOrder,
      details,
    } = this.props;
    const distributorData = getCustomerDistributorsFromId(details.customer.id);
    const retailerData = getRetailersFromCustomerId(details.customer.id);
    console.log('ssssss',distributorData, retailerData)
    const tintData = getTintList(tintSpecification);
    let disabledFlag =
      formEditStatus && formEditStatus === "DISP" ? true : false;
    if (checkingFormEditStatus === true)
      return (
        <h2 style={{ margin: "100px 0 5% 0" }}>Loading Editable Form...</h2>
      );
    return (
      <div style={{ margin: "5% 0 5% 0" }}>
        <Form>
          <Row>
            <Col span={6}>
              <Header sub color="teal" content="Patient Name" />
              <Field
                name="patient"
                component={TextInput}
                placeholder="Patient Name"
                type="text"
              />
            </Col>
          </Row>
          <Row gutter={10} style={{ marginTop: "2%" }}>
            <Col span={8}>
              <Header sub color="teal" content="Distributor Name" />
              <Field
                name="distributor"
                component={CustomSelect}
                placeholder="Distributor Name"
                data={distributorData}
                allowClear={true}
                labelInValue={true}
                showSearch={true}
                disabled={disabledFlag}
              />
            </Col>
            <Col span={6}>
              <Header sub color="teal" content="Retailter Name" />
              <Field
                name="retailer"
                component={CustomSelect}
                placeholder="Retailer Name"
                data={retailerData}
                allowClear={true}
                labelInValue={true}
                showSearch={true}
                disabled={disabledFlag}
              />
            </Col>
            <Col span={8}>
              <MandatoryHeader heading="Dispatch Type" />
              <Field
                name="dispatch_type"
                component={CustomSelect}
                placeholder="Dispatch Type"
                data={this.dispatchList}
                allowClear={true}
                labelInValue={true}
                showSearch={true}
                disabled={disabledFlag}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: "2%" }} gutter={10}>
            <Col span={6}>
              <Header sub color="teal" content="Manufacturing Location" />
              <Field
                name="manufacturing_business_unit"
                component={CustomSelect}
                placeholder="Manufacturing Location"
                data={this.muList}
                allowClear={true}
                labelInValue={true}
                showSearch={true}
                disabled={disabledFlag}
              />
            </Col>
            <Col span={6}>
              <Header sub color="teal" content="Urgent" />
              <Form.Group inline>
                <Field
                  type="radio"
                  name="urgent"
                  component={RadioInput}
                  label="Yes"
                  value="true"
                  onChange={this.handleUrgentField}
                  disabled={disabledFlag}
                />
                <Field
                  type="radio"
                  name="urgent"
                  component={RadioInput}
                  label="No"
                  value="false"
                  onChange={this.handleUrgentField}
                  disabled={disabledFlag}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row gutter={10} style={{ marginTop: "2%" }}>
            {urgent && (
              <Col span={6}>
                <MandatoryHeader heading="Promise Date" />
                <Field
                  name="promised_date"
                  component={DateInput}
                  placeholder="Promise Date"
                  disableFunction={disablePreviousDates}
                  disabled={disabledFlag}
                />
              </Col>
            )}
            {urgent && (
              <Col span={6}>
                <MandatoryHeader heading="Promised Time(Hour)" />
                <Field
                  name="promised_hour"
                  component={TextInput}
                  type="text"
                  placeholder="Promise Time(Hour)"
                  disabled={disabledFlag}
                />
              </Col>
            )}
            {urgent && (
              <Col span={6}>
                <MandatoryHeader heading="Promised Time(Minute)" />
                <Field
                  name="promised_minute"
                  component={TextInput}
                  type="text"
                  placeholder="Promise Time(Minute)"
                  handleOnBlur={() => checkCoatingTimeline("editOrderForm")}
                  disabled={disabledFlag}
                />
              </Col>
            )}
          </Row>
          <FormSection name="add_ons">
            <Row style={{ marginTop: "2%" }}>
              <Col span={4}>
                <Header sub color="teal" content="Tint Needed" />
                <Field
                  name="tint_needed"
                  id="tint_needed"
                  //isChecked={true}
                  component="input"
                  type="checkbox"
                  onChange={this.handleTinting}
                  disabled={disabledFlag}
                />
              </Col>
              {tintRequired && (
                <Col span={20}>
                  <Row gutter={10}>
                    <Col span={10}>
                      <Header sub color="teal" content="Tint Specification" />
                      <Field
                        name="specification"
                        placeholder="Tint Specification"
                        data={tintSpecificationData}
                        component={CustomSelect}
                        getSelectedValue={this.getTintSpecification}
                        allowClear={true}
                        labelInValue={true}
                        showSearch={true}
                        disabled={disabledFlag}
                      />
                    </Col>
                    <Col span={10}>
                      <Header sub color="teal" content="Tint Description" />
                      <Field
                        name="tint"
                        placeholder="Tint Description"
                        data={tintData}
                        component={CustomSelect}
                        showSearch={true}
                        allowClear={true}
                        labelInValue={true}
                        getSelectedValue={this.handleTintDescription}
                        description={tintDescription}
                        disabled={disabledFlag}
                      />
                      {tintDescription && (
                        <Label basic color="blue" style={{ marginTop: "10px" }}>
                          {tintDescription}
                        </Label>
                      )}
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
            <Row style={{ marginTop: "2%" }}>
              <Col span={4}>
                <Header sub color="teal" content="Fitting Needed" />
                <Field
                  name="fitting_needed"
                  id="fitting_needed"
                  //isChecked={true}
                  component="input"
                  type="checkbox"
                  onChange={this.handleFitting}
                  disabled={disabledFlag}
                />
              </Col>
              {fittingRequired && (
                <Col span={20}>
                  <Form.Group inline>
                    <Field
                      type="radio"
                      name="fitting_received"
                      component={RadioInput}
                      label="Fitting Received By Lab"
                      value="true"
                      disabled={disabledFlag}
                    />
                    <Field
                      type="radio"
                      name="fitting_received"
                      component={RadioInput}
                      label="Fitting Awaited By Lab"
                      value="false"
                      disabled={disabledFlag}
                    />
                  </Form.Group>
                </Col>
              )}
            </Row>
            <div style={{ marginTop: "2%" }}>
              <Button
                style={{ marginRight: "8%" }}
                size="large"
                disabled={invalid}
                onClick={handleSubmit(this.onFormSubmit)}
                type="primary"
                loading={updatingOrder}
              >
                Update
              </Button>
            </div>
          </FormSection>
        </Form>
      </div>
    );
  }
}
const actions = {
  setTintCheckboxTrue,
  setTintCheckboxFalse,
  setFittingCheckboxTrue,
  setFittingCheckboxFalse,
  setTintSpecification,
  setTintDescription,
  resetFields,
  setUrgency,
  checkCoatingTimeline,
  checkFormEditStatus,
  setFormFields,
  updateOrder,
};
const mapStateTopProps = (state) => ({
  tintRequired: state.orderForm.tintRequired,
  fittingRequired: state.orderForm.fittingRequired,
  tintSpecification: state.orderForm.tintSpecification,
  tintDescription: state.orderForm.tintDescription,
  urgent: state.orderForm.urgent,
  formEditStatus: state.home.formEditStatus,
  checkingFormEditStatus: state.home.checkingFormEditStatus,
  updatingOrder: state.home.updatingOrder,
});
export default connect(
  mapStateTopProps,
  actions
)(
  reduxForm({
    form: "editOrderForm",
    enableReinitialize: true,
    validate,
  })(EditOrderForm)
);
//100038600
