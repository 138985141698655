import React, { Component } from "react";
import { Button } from "antd";
import { connect } from "react-redux";
import { punchWhatsappOrder } from "../../../whatsappOrders/whatsappOrderActions";

class WhatsappButtonSet extends Component {
  state = {};
  handleWhatsappOrder = (data) => {
    data["status"] = "pending_auth";
    data["product_group_owner"] = this.props.productGroupOwner;
    const {
      activeWhatsappOrder,
      punchWhatsappOrder,
      multipleOrderFlag,
    } = this.props;
    punchWhatsappOrder(data, activeWhatsappOrder, multipleOrderFlag);
  };

  handleWhatsappOrderOnHold = (data) => {
    data["status"] = "on_hold";
    data["product_group_owner"] = this.props.productGroupOwner;
    const {
      activeWhatsappOrder,
      punchWhatsappOrder,
      multipleOrderFlag,
    } = this.props;
    punchWhatsappOrder(data, activeWhatsappOrder, multipleOrderFlag);
  };
  handleBtnDisable = () => {
    const { invalid, rightEyeCheckbox, leftEyeCheckbox } = this.props;

    if (rightEyeCheckbox === false && leftEyeCheckbox === false) {
      return true;
    } else {
      if (invalid === true) {
        return true;
      } else {
        return false;
      }
    }
  };
  render() {
    const { handleSubmit, style, type } = this.props;
    let disabled = this.handleBtnDisable();
    console.log(type);
    return (
      <div className="form-submit-btn" style={style}>
        <Button
          style={{ marginRight: "8%" }}
          size="large"
          type="primary"
          color="teal"
          disabled={disabled}
          onClick={handleSubmit(this.handleWhatsappOrder)}
        >
          Save & Process
        </Button>
        <Button
          style={{ marginRight: "8%" }}
          size="large"
          type="primary"
          color="teal"
          disabled={disabled}
          onClick={handleSubmit(this.handleWhatsappOrderOnHold)}
        >
          Save & Hold
        </Button>
      </div>
    );
  }
}

const actions = {
  punchWhatsappOrder,
};
const mapStateToProps = (state) => ({
  activeWhatsappOrder: state.whatsappOrders.activeWhatsappOrder,
  multipleOrderFlag: state.whatsappOrders.multipleOrderFlag,

  rightEyeCheckbox: state.orderForm.rightEyeCheckbox,
  leftEyeCheckbox: state.orderForm.leftEyeCheckbox,
});
export default connect(
  mapStateToProps,
  actions
)(WhatsappButtonSet);
