import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "../table/Table";
import Paginater from "../table/Paginater";
import moment from "moment";
import {
  getStockPickingOrder,
  getOrderDetailsForPage,
} from "../../homeActions";
import { openModal, closeModal } from "../../../modal/modalActions";
import { Tag, Button } from "antd";
import {
  errorToast,
  getItem,
  successToast,
} from "../../../../app/common/util/genericUtils";
import { printStockOrderApiCall, processOrder } from "../../api";
class StockPickingTab extends Component {
  state = {
    orderInProcessing: null,
    printingOrder: null,
  };

  handlePrintOrder = (order) => () => {
    if (order.job_print_link) {
      this.setState({ printingOrder: order.id });
      printStockOrderApiCall(order.id)
        .then((response) => {
          if (response && response.data && response.data.response_code === 80) {
            successToast("Success", "Initiating order printing");
            fetch(order.job_print_link)
              .then((resp) => resp.blob())
              .then((blob) => {
                const fileURL = window.URL.createObjectURL(blob);
                this.setState({ printingOrder: null });
                const popup = window.open(fileURL);
                setTimeout(() => popup.print(), 2000);
              });
          } else {
            this.setState({ printingOrder: null });
            errorToast("Error", response.data.ui_message);
          }
        })
        .catch((error) => {
          this.setState({ printingOrder: null });
          errorToast("Error", error.message);
        });
    } else {
      errorToast("Error", "Pdf URL not found");
    }
  };

  processOrderApi = (orderId) => {
    this.setState({ orderInProcessing: orderId });
    processOrder(orderId)
      .then((response) => {
        this.setState({ orderInProcessing: null });
        if (response && response.data && response.data.response_code === 80) {
          successToast("Success", "Order processed successfully.");
          this.props.getStockPickingOrder();
          this.props.closeModal();
        } else {
          errorToast("Error", response.data.ui_message);
        }
      })
      .catch((error) => {
        this.setState({ orderInProcessing: null });
        errorToast("Error", error.message);
      });
  };

  handleProcessOrder = (order) => () => {
    const permissionsConfig = getItem("config");
    if (permissionsConfig && permissionsConfig.enable_stock_picking === true) {
      this.props.openModal("StockProcessModal", {
        order,
        processOrder: this.processOrderApi,
      });
    } else {
      errorToast(
        "Can't map Re/LE",
        "Feature is disabled, order is directly sent for billing."
      );
      this.processOrderApi(order.id);
    }
  };

  columns = [
    {
      title: "Order No.",
      dataIndex: "internal_order_id",
      sorter: (a, b) => a.internal_order_id - b.internal_order_id,
      sortDirections: ["ascend"],
      render: (text, record, index) => (
        <p
          className="validate-link"
          onClick={() => this.props.getOrderDetailsForPage(record.id, index)}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Reference Id",
      dataIndex: "reference_id",
    },
    {
      title: "External Order Id",
      dataIndex: "external_order_id",
    },
    {
      title: "Entry Date & Time",
      dataIndex: "received_date",
      render: (text) => {
        if (text !== null) {
          return <p>{moment(text).format("YYYY-MM-DD HH:mm")}</p>;
        }
      },
    },
    {
      title: "Coating",
      dataIndex: "coating.value",
    },
    {
      title: "LNAM",
      dataIndex: "lnam.name",
    },
    {
      title: "Customer",
      dataIndex: "customer.name",
    },
    {
      title: "Promise Date & Time",
      dataIndex: "promised_date",
      render: (text) => {
        if (text !== null) {
          return <p>{moment(text).format("YYYY-MM-DD HH:mm")}</p>;
        }
      },
    },
    {
      title: "Labels",
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {record.label.length > 0 &&
              record.label.map((item, index) => {
                return (
                  <Tag color="blue" key={index}>
                    {item.label.short_code}
                  </Tag>
                );
              })}
          </div>
        );
      },
    },
    {
      title: "Print Count",
      dataIndex: "print_attempt",
    },
    {
      title: "Actions",
      fixed: "right",
      width: 200,
      render: (text, record) => {
        return (
          <div>
            <Button
              style={{ margin: "7% 0 0 5%" }}
              size="small"
              type="primary"
              onClick={this.handlePrintOrder(record)}
              loading={this.state.printingOrder === record.id}
            >
              Print
            </Button>
            <Button
              style={{ margin: "7% 0 0 5%" }}
              size="small"
              type="primary"
              onClick={this.handleProcessOrder(record)}
              loading={this.state.orderInProcessing === record.id}
            >
              Process
            </Button>
          </div>
        );
      },
    },
  ];

  render() {
    const { loading, orders, getStockPickingOrder } = this.props;
    return (
      <div>
        <Table
          rowClassName={(record, text) => {
            let end = new Date();
            let start = new Date(record.created_on);
            let hoursDiff = end.getHours() - start.getHours();
            return hoursDiff > 1 ? "highlighted-row" : "";
          }}
          columns={this.columns}
          loading={loading}
          data={orders.results}
          defaultPageSize={40}
          pagination={false}
          bordered={true}
          scroll={{ x: 1300 }}
          size="small"
        />
        <div style={{ textAlign: "center", marginTop: "4%" }}>
          <Paginater
            defaultCurrent={1}
            total={orders.count}
            onChange={getStockPickingOrder}
            defaultPageSize={40}
          />
        </div>
      </div>
    );
  }
}
const actions = {
  getStockPickingOrder,
  getOrderDetailsForPage,
  closeModal,
  openModal,
};
const mapStateToProps = (state) => ({
  loading: state.home.loadingStockPicking,
  orders: state.home.stockPickingOrders,
  activeTabKey: state.home.activeTabKey,
});
export default connect(
  mapStateToProps,
  actions
)(StockPickingTab);
