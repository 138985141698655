import React, { Component } from "react";
import { Button } from "antd";
import { connect } from "react-redux";
import {
  validatePendingAuthOrders,
  getNextOrder,
  getPreviousOrder,
  putOrderOnHold,
  setActiveTabKey,
  fetchPendingOrders,
} from "../../../home/homeActions";

class ValidateButtonSet extends Component {
  state = {};
  handleValidate = (data) => {
    const { validatePendingAuthOrders, details } = this.props;
    data["product_group_owner"] = this.props.productGroupOwner;
    validatePendingAuthOrders(details.id, data);
  };
  handleBtnDisable = () => {
    const { invalid, rightEyeCheckbox, leftEyeCheckbox } = this.props;

    if (rightEyeCheckbox === false && leftEyeCheckbox === false) {
      return true;
    } else {
      if (invalid === true) {
        return true;
      } else {
        return false;
      }
    }
  };
  render() {
    const {
      handleSubmit,
      orderId,
      putOnHoldLoader,
      putOrderOnHold,
      getNextOrder,
      getPreviousOrder,
      details,
      fetchPendingOrders,
      type,
      style,
      showNextPrevious,
    } = this.props;
    let disabled = this.handleBtnDisable();
    console.log(orderId, type);
    return (
      <div style={style ? style : { marginBottom: "2%" }}>
        <div style={{ textAlign: "center", marginTop: "4%" }}>
          <Button
            type="primary"
            disabled={disabled}
            onClick={handleSubmit(this.handleValidate)}
            style={{ marginRight: "2%" }}
          >
            Validate Order
          </Button>
          <Button
            type="primary"
            disabled={disabled}
            onClick={() => {
              putOrderOnHold(details.id);
              fetchPendingOrders();
            }}
            loading={putOnHoldLoader}
          >
            Put On Hold
          </Button>
        </div>
        {type === "validate" && showNextPrevious && showNextPrevious === true && (
          <div style={{ textAlign: "right", marginTop: "2%" }}>
            <Button
              type="primary"
              style={{ marginRight: "7%" }}
              onClick={() => getPreviousOrder(details.status)}
            >
              Previous
            </Button>
            <Button type="primary" onClick={() => getNextOrder(details.status)}>
              Next
            </Button>
          </div>
        )}
      </div>
    );
  }
}

const actions = {
  validatePendingAuthOrders,
  getNextOrder,
  getPreviousOrder,
  putOrderOnHold,
  setActiveTabKey,
  fetchPendingOrders,
};
const mapStateToProps = (state) => ({
  orderId: state.home.orderDetails.id,
  putOnHoldLoader: state.home.putOnHoldLoader,
  rightEyeCheckbox: state.orderForm.rightEyeCheckbox,
  leftEyeCheckbox: state.orderForm.leftEyeCheckbox,
});
export default connect(
  mapStateToProps,
  actions
)(ValidateButtonSet);
