import React, { Component } from "react";
import { connect } from "react-redux";
import { closeModal } from "./modalActions";
import { reduxForm, Field } from "redux-form";
import { Button, Row, Col, Modal } from "antd";
import { Form } from "semantic-ui-react";
import {
  combineValidators,
  matchesPattern,
  composeValidators,
  isRequired,
} from "revalidate";
import TextInput from "../../app/common/form/TextInput";
import { pickStockOrderEyes } from "../home/api";
import { errorToast } from "../../app/common/util/genericUtils";

const negativePositiveRegex = /^-*\+?[0-9]\d*(\.\d+)?$/;

const validate = combineValidators({
  re: composeValidators(
    isRequired({ message: "RE is required" }),
    matchesPattern(negativePositiveRegex)({
      message: "+ve and -ve value only",
    })
  )(),
  le: composeValidators(
    isRequired({ message: "LE is required" }),
    matchesPattern(negativePositiveRegex)({
      message: "+ve and -ve value only",
    })
  )(),
});

class StockProcessModal extends Component {
  state = { pickingOrder: false };

  handleFormSubmit = (values) => {
    const { order, processOrder } = this.props;
    this.setState({ pickingOrder: true });
    pickStockOrderEyes(order.id, values)
      .then((response) => {
        this.setState({ pickingOrder: false });
        if (response && response.data && response.data.response_code === 80) {
          processOrder(order.id);
        } else {
          errorToast("Error", response.data.ui_message);
        }
      })
      .catch((error) => {
        this.setState({ pickingOrder: false });
        errorToast("Error", error.message);
      });
  };

  render() {
    const { closeModal, handleSubmit, order, invalid } = this.props;
    return (
      <Modal
        title="Stock Order Processing"
        visible={true}
        onCancel={closeModal}
        onOk={closeModal}
        footer={null}
      >
        <div>
          <Form error size="large" autoComplete="off">
            <Row gutter={8}>
              {order.right_lens_config !== null && (
                <Col span={12}>
                  <Field
                    name="re"
                    type="text"
                    component={TextInput}
                    placeholder="Right Eye"
                    onBlur={this.handleOpcValidation}
                  />
                </Col>
              )}
              {order.left_lens_config !== null && (
                <Col span={12}>
                  <Field
                    name="le"
                    type="text"
                    component={TextInput}
                    placeholder="Left Eye"
                    onBlur={this.handleOpcValidation}
                  />
                </Col>
              )}
            </Row>

            <div style={{ margin: "5% 0 0 0" }}>
              <Button
                type="primary"
                disabled={invalid}
                onClick={handleSubmit(this.handleFormSubmit)}
                loading={this.state.pickingOrder}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
    );
  }
}
const actions = { closeModal };
const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({ form: "stockProcessForm", validate, enableReinitialize: true })(
    StockProcessModal
  )
);
