import {
  FETCH_ONHOLD_ORDERS_START,
  FETCH_ONHOLD_ORDERS_SUCCESS,
  FETCH_ONHOLD_ORDERS_FAILURE,
  FETCH_PENDING_AUTH_ORDERS_START,
  FETCH_PENDING_AUTH_ORDERS_SUCCESS,
  FETCH_PENDING_AUTH_ORDERS_FAILURE,
  FETCH_PROCESSING_ORDERS_START,
  FETCH_PROCESSING_ORDERS_FAILURE,
  FETCH_CANCELLED_ORDERS_START,
  FETCH_CANCELLED_ORDERS_SUCCESS,
  FETCH_CANCELLED_ORDERS_FAILURE,
  FETCH_BLANK_SELECTION_ORDERS_START,
  FETCH_BLANK_SELECTION_ORDERS_SUCCESS,
  FETCH_BLANK_SELECTION_ORDERS_FAILURE,
  ONHOLD_STATUS_CHANGE_START,
  ONHOLD_STATUS_CHANGE_SUCCESS,
  ONHOLD_STATUS_CHANGE_FAILURE,
  VALIDATE_PENDING_AUTH_ORDERS_START,
  VALIDATE_PENDING_AUTH_ORDERS_SUCCESS,
  VALIDATE_PENDING_AUTH_ORDERS_FAILURE,
  GET_ORDER_DETAILS_START,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAILURE,
  FETCH_PROCESSING_ORDERS_SUCCESS,
  PUT_ORDER_ON_HOLD_START,
  PUT_ORDER_ON_HOLD_SUCCESS,
  PUT_ORDER_ON_HOLD_FAILURE,
  SET_INDEX_FOR_ORDER,
  SET_CURRENT_PAGE,
  FETCH_CUSTOMER_WITHOUT_DIST_START,
  FETCH_CUSTOMER_WITHOUT_DIST_SUCCESS,
  FETCH_CUSTOMER_WITHOUT_DIST_FAILURE,
  SET_PAGE_FOR_CUSTOMER,
  FETCH_CUSTOMER_WITH_DIST_START,
  FETCH_CUSTOMER_WITH_DIST_SUCCESS,
  FETCH_CUSTOMER_WITH_DIST_FAILURE,
  SET_ACTIVE_TAB_KEY,
  CHECK_LENSWARE_STATUS_START,
  CHECK_LENSWARE_STATUS_SUCCESS,
  CHECK_LENSWARE_STATUS_FAILURE,
  RESET_HOME_STATE,
  FETCH_STOCK_ORDERS_LIST_START,
  FETCH_STOCK_ORDERS_LIST_SUCCESS,
  FETCH_STOCK_ORDERS_LIST_FAILURE,
  PRINT_STOCK_ORDER_START,
  PRINT_STOCK_ORDER_SUCCESS,
  PRINT_STOCK_ORDER_FAILURE,
  ARCHIVE_STOCK_ORDER_START,
  ARCHIVE_STOCK_ORDER_SUCCESS,
  ARCHIVE_STOCK_ORDER_FAILURE,
  CHECK_FORM_EDIT_STATUS_START,
  CHECK_FORM_EDIT_STATUS_SUCCESS,
  CHECK_FORM_EDIT_STATUS_FAILURE,
  UPDATE_ORDER_START,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
  GET_STOCK_PICKING_ORDERS_START,
  GET_STOCK_PICKING_ORDERS_SUCCESS,
  GET_STOCK_PICKING_ORDERS_FAILURE,
  GET_MULTI_STOCK_PICKING_ORDERS_START,
  GET_MULTI_STOCK_PICKING_ORDERS_SUCCESS,
  GET_MULTI_STOCK_PICKING_ORDERS_FAILURE,
  GET_MULTI_STOCK_GROUPED_ORDERS_START,
  GET_MULTI_STOCK_GROUPED_ORDERS_SUCCESS,
  GET_MULTI_STOCK_GROUPED_ORDERS_FAILURE,
} from "./homeConstants";
import {
  getOnHoldOrderList,
  getPendingAuthOrderList,
  getCancelledOrderList,
  getBlankSelectionOrderList,
  changeStatusOfOnHold,
  orderDetails,
  validatePendingAuth,
  getProcessingOrderList,
  putOnHold,
  getCustomerWithoutDist,
  getCustomerWithDist,
  lenswareStatusApiCall,
  getStockOrderList,
  printStockOrderApiCall,
  archiveStockOrderApiCall,
  orderUpdationApi,
  getStockPickingOrders,
  getMultiStockGroupedOrdersAPI,
  getMultiStockPickingOrdersAPI,
} from "./api";
import { reportError } from "../../app/common/util/sentryConfig";
import {
  setItem,
  errorToast,
  successToast,
  getItem,
  SuccessModal,
  getUserType,
  lenswareStatusUserMapping,
  DBsetItem,
  DBgetItem,
} from "../../app/common/util/genericUtils";
import { openModal } from "../modal/modalActions";
import { push } from "connected-react-router";
import {
  makeFormData,
  refactorPayload,
  getCustomerAddressfromId,
  getRetailerDistributorAddressfromId,
  getCustomerAddressDescription,
  getCustomerDistributorsFromId,
  getRetailersFromCustomerId,
  transformLnamList,
  transformStockDescriptionList,
} from "../orderPunching/formUtils";
import {
  setTintCheckboxTrue,
  setFittingCheckboxTrue,
  setCustomerId,
  setLnamId,
  setCorridorId,
  setAddressList,
  setUrgency,
  setRightEyeCheckBox,
  setLeftEyeCheckBox,
  enableRightPrism,
  enableLeftPrism,
  showAddForLnam,
  setSwingForLnam,
  showCustomerInitialField,
  hideFieldsForFpLnams,
  showNpdForLnam,
  showMonoPdForLnam,
  setCtoFieldsMandatory,
  setTintDescription,
  findLnamDescription,
  setAddressDescription,
  setFittingCheckboxFalse,
  setTintCheckboxFalse,
  setDistributorList,
  setRetailerList,
  setProductGroupList,
  setProductGroupDescription,
  setLnamListForProductGroup,
  setProductGroupOwner,
  getPgConfig,
  getLocationForOrderSuccess,
  setStockDescriptionList,
} from "../orderPunching/orderActions";
import { lnamFromFAlphabet } from "../orderPunching/constants";
import {
  getProductGroupListForSelectedCustomer,
  getProductGroupListForSelectedDistributor,
  getProductGroupListForSelectedRetailer,
} from "./validationFormUtils";
import { ORDER_TYPES } from "../../app/common/util/genericConstants";

export const fetchCustomerWithoutDistStart = () => {
  return {
    type: FETCH_CUSTOMER_WITHOUT_DIST_START,
  };
};
export const fetchCustomerWithoutDistSuccess = (response) => {
  return {
    type: FETCH_CUSTOMER_WITHOUT_DIST_SUCCESS,
    payload: response,
  };
};
export const fetchCustomerWithoutDistFailure = () => {
  return {
    type: FETCH_CUSTOMER_WITHOUT_DIST_FAILURE,
  };
};
export const setPageForCustomer = (page) => {
  return {
    type: SET_PAGE_FOR_CUSTOMER,
    payload: page,
  };
};
export const fetchCustomerWithDistStart = () => {
  return {
    type: FETCH_CUSTOMER_WITH_DIST_START,
  };
};
export const fetchCustomerWithDistSuccess = (response) => {
  return {
    type: FETCH_CUSTOMER_WITH_DIST_SUCCESS,
    payload: response,
  };
};
export const fetchCustomerWithDistFailure = () => {
  return {
    type: FETCH_CUSTOMER_WITH_DIST_FAILURE,
  };
};

export const fetchCustomerWithDist = (page) => async (dispatch, getState) => {
  dispatch(fetchCustomerWithDistStart());
  return getCustomerWithDist(page)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(fetchCustomerWithDistSuccess());
        DBgetItem("customerList").then((customerList)=>{
          if (customerList && customerList.length > 0) {
            let newList = customerList.concat(response.data.data.results);
            DBsetItem("customerList", newList);
          } else {
            DBsetItem("customerList", response.data.data.results);
          }
          if (response.data.data.next !== null) {
            const page = getState().home.pageForCustomer;
            dispatch(setPageForCustomer(page + 1));
            dispatch(fetchCustomerWithDist(page + 1));
          }
        })
      } else {
        dispatch(fetchCustomerWithDistFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(fetchCustomerWithDistFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};
export const fetchCustomerWithoutDist = (page) => async (
  dispatch,
  getState
) => {
  dispatch(fetchCustomerWithoutDistStart());
  return getCustomerWithoutDist(page)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(fetchCustomerWithoutDistSuccess());
        DBgetItem("customerList").then((customerList)=>{
          if (customerList && customerList.length > 0) {
            let newList = customerList.concat(response.data.data.results);
            DBsetItem("customerList", newList);
          } else {
            DBsetItem("customerList", response.data.data.results);
          }
          if (response.data.data.next !== null) {
            const page = getState().home.pageForCustomer;
            dispatch(setPageForCustomer(page + 1));
            dispatch(fetchCustomerWithoutDist(page + 1));
          } else {
            dispatch(setPageForCustomer(1));
            dispatch(fetchCustomerWithDist());
          }
        })
      } else {
        dispatch(fetchCustomerWithoutDistFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(fetchCustomerWithoutDistFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};
export const setCurrentPage = (page) => {
  return {
    type: SET_CURRENT_PAGE,
    payload: page,
  };
};
export const fetchOnHoldOrdersStart = () => {
  return {
    type: FETCH_ONHOLD_ORDERS_START,
  };
};

export const fetchOnHoldOrdersSuccess = (response) => {
  return {
    type: FETCH_ONHOLD_ORDERS_SUCCESS,
    payload: response,
  };
};
export const fetchOnHoldOrdersFailure = () => {
  return {
    type: FETCH_ONHOLD_ORDERS_FAILURE,
  };
};
export const setActiveTabKey = (key) => {
  return {
    type: SET_ACTIVE_TAB_KEY,
    payload: key,
  };
};
export const fetchOnHoldOrders = (page) => async (dispatch) => {
  dispatch(fetchOnHoldOrdersStart());
  dispatch(setCurrentPage(page));
  return getOnHoldOrderList(page)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        console.log(response.data.data);
        dispatch(fetchOnHoldOrdersSuccess(response.data.data));
      } else {
        dispatch(fetchOnHoldOrdersFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(fetchOnHoldOrdersFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const fetchPendingAuthOrdersStart = () => {
  return {
    type: FETCH_PENDING_AUTH_ORDERS_START,
  };
};

export const fetchPendingAuthOrdersSuccess = (response) => {
  return {
    type: FETCH_PENDING_AUTH_ORDERS_SUCCESS,
    payload: response,
  };
};
export const fetchPendingAuthOrdersFailure = () => {
  return {
    type: FETCH_PENDING_AUTH_ORDERS_FAILURE,
  };
};

export const fetchPendingOrders = (page) => async (dispatch) => {
  dispatch(fetchPendingAuthOrdersStart());
  dispatch(setCurrentPage(page));
  return getPendingAuthOrderList(page)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        console.log("pending auth orderlist", response.data.data);
        dispatch(fetchPendingAuthOrdersSuccess(response.data.data));
      } else {
        dispatch(fetchPendingAuthOrdersFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(fetchPendingAuthOrdersFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const fetchProcessingOrdersStart = () => {
  return {
    type: FETCH_PROCESSING_ORDERS_START,
  };
};

export const fetchProcessingOrdersSuccess = (response) => {
  return {
    type: FETCH_PROCESSING_ORDERS_SUCCESS,
    payload: response,
  };
};
export const fetchProcessingOrdersFailure = () => {
  return {
    type: FETCH_PROCESSING_ORDERS_FAILURE,
  };
};

export const fetchProcessingOrders = (page) => async (dispatch) => {
  dispatch(fetchProcessingOrdersStart());
  dispatch(setCurrentPage(page));
  return getProcessingOrderList(page)
    .then((response) => {
      console.log(response);
      if (response && response.data.response_code === 80) {
        dispatch(fetchProcessingOrdersSuccess(response.data.data));
      } else {
        dispatch(fetchProcessingOrdersFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(fetchProcessingOrdersFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const fetchCancelledOrdersStart = () => {
  return {
    type: FETCH_CANCELLED_ORDERS_START,
  };
};

export const fetchCancelledOrdersSuccess = (response) => {
  return {
    type: FETCH_CANCELLED_ORDERS_SUCCESS,
    payload: response,
  };
};
export const fetchCancelledOrdersFailure = () => {
  return {
    type: FETCH_CANCELLED_ORDERS_FAILURE,
  };
};

export const fetchCancelledOrders = (page) => async (dispatch) => {
  dispatch(fetchCancelledOrdersStart());
  dispatch(setCurrentPage(page));
  return getCancelledOrderList()
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(fetchCancelledOrdersSuccess(response.data.data));
      } else {
        dispatch(fetchCancelledOrdersFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(fetchCancelledOrdersFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const fetchBlankSelectionOrdersStart = () => {
  return {
    type: FETCH_BLANK_SELECTION_ORDERS_START,
  };
};

export const fetchBlankSelectionOrdersSuccess = (response) => {
  return {
    type: FETCH_BLANK_SELECTION_ORDERS_SUCCESS,
    payload: response,
  };
};
export const fetchBlankSelectionOrdersFailure = () => {
  return {
    type: FETCH_BLANK_SELECTION_ORDERS_FAILURE,
  };
};

export const fetchBlankSelectionOrders = (page) => async (dispatch) => {
  dispatch(fetchBlankSelectionOrdersStart());
  dispatch(setCurrentPage(page));
  return getBlankSelectionOrderList()
    .then((response) => {
      console.log("blank selection orders", response);
      if (response && response.data.response_code === 80) {
        dispatch(fetchBlankSelectionOrdersSuccess(response.data.data));
      } else {
        dispatch(fetchBlankSelectionOrdersFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(fetchBlankSelectionOrdersFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const onHoldStatusChangeStart = () => {
  return {
    type: ONHOLD_STATUS_CHANGE_START,
  };
};
export const onHoldStatusChangeSuccess = () => {
  return {
    type: ONHOLD_STATUS_CHANGE_SUCCESS,
  };
};
export const onHoldStatusChangeFailure = () => {
  return {
    type: ONHOLD_STATUS_CHANGE_FAILURE,
  };
};

export const onHoldStatusChange = (id) => async (dispatch) => {
  dispatch(onHoldStatusChangeStart());
  return changeStatusOfOnHold(id)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(onHoldStatusChangeSuccess());
        successToast("Success", "Status changed");
        dispatch(setActiveTabKey("2"));
        dispatch(fetchPendingOrders());
      } else {
        dispatch(onHoldStatusChangeFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(onHoldStatusChangeFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const getOrderDetailsStart = () => {
  return {
    type: GET_ORDER_DETAILS_START,
  };
};
export const getOrderDetailsSuccess = (response) => {
  return {
    type: GET_ORDER_DETAILS_SUCCESS,
    payload: response,
  };
};
export const getOrderDetailsFailure = () => {
  return {
    type: GET_ORDER_DETAILS_FAILURE,
  };
};
export const setIndexForOrder = (index) => {
  return {
    type: SET_INDEX_FOR_ORDER,
    payload: index,
  };
};
export const getOrderDetailsForForm = (id, index) => async (dispatch) => {
  dispatch(getOrderDetailsStart());
  dispatch(setIndexForOrder(index));
  return orderDetails(id)
    .then(async (response) => {
      console.log("Order Details", response);
      if (response && response.data.response_code === 80) {
        const data = response.data.data;
        if (data.product_group_owner !== null) {
          dispatch(setFormFields(data));
          console.log(data);
          await dispatch(getPgConfig(data.lnam.id, data.product_group.id));
          const formData = makeFormData(data);
          dispatch(getOrderDetailsSuccess(formData));
          const orderType = data.is_stock
            ? ORDER_TYPES.STOCK_SINGLE
            : ORDER_TYPES.RX;
          dispatch(push(`/validate/${orderType}/${data.id}`));
        } else {
          dispatch(getOrderDetailsFailure());
          errorToast("Error", "Old order, product group does not exist.");
        }
      } else {
        dispatch(getOrderDetailsFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(getOrderDetailsFailure());
      console.log(error);
      errorToast("Error", error.message);
      reportError(error);
    });
};
export const getOrderDetailsForPage = (id, index) => async (dispatch) => {
  dispatch(getOrderDetailsStart());
  dispatch(setIndexForOrder(index));
  return orderDetails(id)
    .then((response) => {
      console.log("Order Details", response);
      if (response && response.data.response_code === 80) {
        const data = response.data.data;
        console.log(data);
        dispatch(getOrderDetailsSuccess(data));
        dispatch(push(`/order_details/${data.id}`));
      } else {
        dispatch(getOrderDetailsFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(getOrderDetailsFailure());
      console.log(error);
      errorToast("Error", error.message);
      reportError(error);
    });
};
const setDistributorAndRetailerList = (
  customerId,
  distributor,
  retailer,
  productGroupOwner,
  pgId
) => async (dispatch) => {
  const distributorData = await getCustomerDistributorsFromId(customerId);
  const retailerData = await getRetailersFromCustomerId(customerId);
  dispatch(setDistributorList(distributorData));
  dispatch(setRetailerList(retailerData));
  dispatch(setProductGroupOwner(productGroupOwner));
  let productGroupData = {};
  if (productGroupOwner === "retailer") {
    productGroupData = await getProductGroupListForSelectedRetailer(
      customerId,
      retailer.id,
      pgId
    );
  } else if (productGroupOwner === "distributor") {
    productGroupData = await getProductGroupListForSelectedDistributor(
      customerId,
      distributor.id,
      pgId
    );
  } else if (productGroupOwner === "customer") {
    productGroupData = await getProductGroupListForSelectedCustomer(customerId, pgId);
  }
  let { defaultProductGroup, productGroupList } = productGroupData;
  dispatch(setProductGroupList(productGroupList));
  dispatch(setProductGroupDescription(defaultProductGroup.description));
  let lnamList = transformLnamList(defaultProductGroup.config);
  dispatch(setLnamListForProductGroup(lnamList));

  const stockDescriptionList = transformStockDescriptionList(
    defaultProductGroup.stock_description
  );
  dispatch(setStockDescriptionList(stockDescriptionList));
};
export const setFormFields = (data) => (dispatch) => {
  dispatch(checkTint(data.add_ons));
  dispatch(checkFitting(data.add_ons));
  dispatch(setCustomerId(data.customer.id));
  dispatch(
    setDistributorAndRetailerList(
      data.customer.id,
      data.distributor,
      data.retailer,
      data.product_group_owner,
      data.product_group.id
    )
  );
  dispatch(setLnamId(data.lnam.id));
  data.corridor &&
    data.corridor.id &&
    dispatch(setCorridorId(data.corridor.id));
  dispatch(checkAddress(data));
  dispatch(checkUrgency(data));
  dispatch(setEyeConfigs(data));
  // dispatch(checkAddField(data));
  dispatch(checkSwingField(data));
  dispatch(checkFieldForFpLnam(data));
  dispatch(checkFieldForLnamFromF(data));
  dispatch(checkTintDescription(data.add_ons));
  dispatch(findLnamDescription(data.lnam.id));
  dispatch(checkAdrDescription(data.customer.id));
  data.manufacturing_business_unit && dispatch(setMfLocationDependencies(data));
};
const setMfLocationDependencies = (data) => (dispatch) => {
  let statePayload = {
    state: "NOT_LOADED",
    totalPower: null,
    mfLocationId: null,
    type: null,
  };
  if (data.right_lens_config !== null && data.left_lens_config === null) {
    statePayload = {
      state: "LOADED",
      totalPower:
        Number(data.right_lens_config.sph) + Number(data.right_lens_config.cyl),
      mfLocationId: data.manufacturing_business_unit.id,
      type: "right",
    };
  } else if (
    data.left_lens_config !== null &&
    data.right_lens_config === null
  ) {
    statePayload = {
      state: "LOADED",
      totalPower:
        Number(data.left_lens_config.sph) + Number(data.left_lens_config.cyl),
      mfLocationId: data.manufacturing_business_unit.id,
      type: "left",
    };
  } else if (
    data.left_lens_config !== null &&
    data.right_lens_config !== null
  ) {
    const leftPower =
      Number(data.left_lens_config.sph) + Number(data.left_lens_config.cyl);
    const rightPower =
      Number(data.right_lens_config.sph) + Number(data.right_lens_config.cyl);
    statePayload = {
      state: "LOADED",
      totalPower: leftPower > rightPower ? leftPower : rightPower,
      mfLocationId: data.manufacturing_business_unit.id,
      type: leftPower > rightPower ? "left" : "right",
    };
  }
  dispatch(getLocationForOrderSuccess(statePayload));
};
export const checkAdrDescription = (id) => async (dispatch) => {
  const adrDescription = await getCustomerAddressDescription(id);
  dispatch(setAddressDescription(adrDescription));
};
export const checkTint = (data) => (dispatch) => {
  if (data !== null && data !== undefined) {
    if (data.tint !== null && data.tint !== undefined) {
      dispatch(setTintCheckboxTrue());
    } else {
      dispatch(setTintCheckboxFalse());
    }
  }
};
export const checkFitting = (data) => (dispatch) => {
  if (data !== null && data !== undefined) {
    if (data.fitting_received !== null && data.fitting_received !== undefined) {
      if (data.fitting_received === true) {
        dispatch(setFittingCheckboxTrue());
      } else {
        dispatch(setFittingCheckboxFalse());
      }
    }
  }
};
export const checkTintDescription = (data) => (dispatch) => {
  if (data !== null && data !== undefined) {
    if (data.tint !== null && data.tint !== undefined) {
      dispatch(setTintDescription(data.tint.description));
    }
  }
};
export const checkFieldForFpLnam = (data) => (dispatch) => {
  if (lnamFromFAlphabet.includes(data.lnam.type.value) === true) {
    dispatch(showCustomerInitialField(true));
    dispatch(setCtoFieldsMandatory(true));
    if (data.lnam.type.value !== "FPA") {
      dispatch(hideFieldsForFpLnams(false));
      dispatch(showNpdForLnam(true));
    }
  }
};
export const checkFieldForLnamFromF = (data) => (dispatch) => {
  const type = data.lnam.type.value;
  if (type && type.startsWith("F") === true) {
    dispatch(showMonoPdForLnam(true));
  }
};
export const checkAddress = (data) => async (dispatch) => {
  if (data.shipping_same_as_billing === true) {
    const addressList = await getCustomerAddressfromId(data.customer.id);
    dispatch(setAddressList(addressList));
  } else {
    const addressList = await getRetailerDistributorAddressfromId(data.customer.id);
    dispatch(setAddressList(addressList));
  }
};
export const checkUrgency = (data) => (dispatch) => {
  data.urgent === true
    ? dispatch(setUrgency(true))
    : dispatch(setUrgency(false));
};
export const setEyeConfigs = (data) => (dispatch) => {
  if (data.right_lens_config !== undefined && data.right_lens_config !== null) {
    dispatch(setRightEyeCheckBox(true));
    dispatch(enableRightPrism());
  }
  if (data.left_lens_config !== undefined && data.left_lens_config !== null) {
    dispatch(setLeftEyeCheckBox(true));
    dispatch(enableLeftPrism());
  }
};
export const checkSwingField = (data) => (dispatch) => {
  if (data.right_lens_config !== undefined && data.right_lens_config !== null) {
    if (
      data.right_lens_config.swing !== undefined &&
      data.right_lens_config.swing !== null
    ) {
      dispatch(setSwingForLnam(true));
    }
  }
  if (data.left_lens_config !== undefined && data.left_lens_config !== null) {
    if (
      data.left_lens_config.swing !== undefined &&
      data.left_lens_config.swing !== null
    ) {
      dispatch(setSwingForLnam(true));
    }
  }
};
export const checkAddField = (data) => (dispatch) => {
  if (data.right_lens_config !== undefined && data.right_lens_config !== null) {
    if (
      data.right_lens_config.add !== undefined &&
      data.right_lens_config.add !== null
    ) {
      dispatch(showAddForLnam());
    }
  }
  if (data.left_lens_config !== undefined && data.left_lens_config !== null) {
    if (
      data.left_lens_config.add !== undefined &&
      data.left_lens_config.add !== null
    ) {
      dispatch(showAddForLnam());
    }
  }
};
export const validatePendingAuthOrdersStart = () => {
  return {
    type: VALIDATE_PENDING_AUTH_ORDERS_START,
  };
};

export const validatePendingAuthOrdersSuccess = () => {
  return {
    type: VALIDATE_PENDING_AUTH_ORDERS_SUCCESS,
  };
};
export const validatePendingAuthOrdersFailure = () => {
  return {
    type: VALIDATE_PENDING_AUTH_ORDERS_FAILURE,
  };
};

export const validatePendingAuthOrders = (id, data) => async (dispatch) => {
  dispatch(validatePendingAuthOrdersStart());
  const refactoredPayload = refactorPayload(data);
  refactoredPayload["status"] = "processing";
  console.log("the validation order is", refactoredPayload);
  return validatePendingAuth(id, refactoredPayload)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(validatePendingAuthOrdersSuccess());
        successToast("Success", "Order validated successfully.");
        dispatch(push("/"));
      } else if (response && response.data.response_code === 70) {
        dispatch(validatePendingAuthOrdersSuccess());
        dispatch(
          openModal("OrderValidationModal", {
            uiMessage: response.data.ui_message,
          })
        );
      } else {
        dispatch(validatePendingAuthOrdersFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(validatePendingAuthOrdersFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const putOrderOnHoldStart = () => {
  return {
    type: PUT_ORDER_ON_HOLD_START,
  };
};
export const putOrderOnHoldSuccess = () => {
  return {
    type: PUT_ORDER_ON_HOLD_SUCCESS,
  };
};
export const putOrderOnHoldFailure = () => {
  return {
    type: PUT_ORDER_ON_HOLD_FAILURE,
  };
};
export const putOrderOnHold = (id) => (dispatch) => {
  dispatch(putOrderOnHoldStart());
  return putOnHold(id)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(putOrderOnHoldSuccess());
        successToast("Success", "Order Status Changed to On Hold.");
        dispatch(push("/"));
      } else {
        dispatch(putOrderOnHoldFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(putOrderOnHoldFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};
export const fetchStockOrdersListStart = () => {
  return {
    type: FETCH_STOCK_ORDERS_LIST_START,
  };
};
export const fetchStockOrdersListSuccess = (orders) => {
  return {
    type: FETCH_STOCK_ORDERS_LIST_SUCCESS,
    payload: orders,
  };
};
export const fetchStockOrdersListFailure = () => {
  return {
    type: FETCH_STOCK_ORDERS_LIST_FAILURE,
  };
};

export const fetchStockOrdersList = (page) => async (dispatch) => {
  dispatch(fetchStockOrdersListStart());
  dispatch(setCurrentPage(page));
  return getStockOrderList(page)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(fetchStockOrdersListSuccess(response.data.data));
      } else {
        dispatch(fetchStockOrdersListFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(fetchStockOrdersListFailure());
      errorToast(error.message);
      reportError(error);
    });
};
export const getNextOrder = (status) => async (dispatch, getState) => {
  const index = getState().home.indexForOrder + 1;
  var page = getState().home.currentPage;
  if (page === undefined) {
    page = 2;
  } else {
    page = page + 1;
  }
  const orders = getState().home[statusDataMapper[status]];
  console.log(orders);
  if (orders && orders.results && index >= orders.results.length) {
    if (index === 40) {
      await dispatch(statusActionMapper[status](page));
      dispatch(setIndexForOrder(0));
      const order = getState().home[statusDataMapper[status]];
      const newIndex = getState().home.indexForOrder;
      const id = order.results[newIndex]["id"];
      if (status === "pending_auth") {
        dispatch(getOrderDetailsForForm(id, newIndex));
      } else {
        dispatch(getOrderDetailsForPage(id, newIndex));
      }
    } else {
      errorToast("Error", "Next Order doesn't exist");
    }
  } else {
    const id = orders.results[index]["id"];
    if (status === "pending_auth") {
      dispatch(getOrderDetailsForForm(id, index));
    } else {
      dispatch(getOrderDetailsForPage(id, index));
    }
  }
};

export const getPreviousOrder = (status) => async (dispatch, getState) => {
  const index = getState().home.indexForOrder;
  const orders = getState().home[statusDataMapper[status]];
  var page = getState().home.currentPage;
  if (page === undefined) {
    page = 1;
  }
  console.log("page for prev", page, index);
  if (page === 1 && index === 0) {
    errorToast("Error", "Previous Order doesn't exist");
  } else {
    if (index - 1 < 0) {
      await dispatch(statusActionMapper[status](page - 1));
      dispatch(setIndexForOrder(39));
      const order = getState().home[statusDataMapper[status]];
      const newIndex = getState().home.indexForOrder;
      const id = order.results[newIndex]["id"];
      if (status === "pending_auth") {
        dispatch(getOrderDetailsForForm(id, newIndex));
      } else {
        dispatch(getOrderDetailsForPage(id, newIndex));
      }
    } else {
      const id = orders.results[index - 1]["id"];
      if (status === "pending_auth") {
        dispatch(getOrderDetailsForForm(id, index - 1));
      } else {
        dispatch(getOrderDetailsForPage(id, index - 1));
      }
    }
  }
};

const statusDataMapper = {
  on_hold: "onHoldOrders",
  processing: "processingOrders",
  pending_auth: "pendingAuthOrders",
  blank_selection: "blankSelectionOrders",
  stock: "stockOrders",
};

const statusActionMapper = {
  on_hold: fetchOnHoldOrders,
  pending_auth: fetchPendingOrders,
  processing: fetchProcessingOrders,
  blank_selection: fetchBlankSelectionOrders,
  stock: fetchStockOrdersList,
};

export const checkLenswareStatusStart = () => {
  return {
    type: CHECK_LENSWARE_STATUS_START,
  };
};
export const checkLenswareStatusSuccess = () => {
  return {
    type: CHECK_LENSWARE_STATUS_SUCCESS,
  };
};
export const checkLenswareStatusFailure = () => {
  return {
    type: CHECK_LENSWARE_STATUS_FAILURE,
  };
};

export const checkLenswareStatus = (id) => async (dispatch) => {
  dispatch(checkLenswareStatusStart());
  return lenswareStatusApiCall(id)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        const userType = getUserType();
        dispatch(checkLenswareStatusSuccess());
        SuccessModal(
          "Current Status",
          `Status: ${
            response.data.data.status === ""
              ? "N/A"
              : response.data.data.status[lenswareStatusUserMapping[userType]]
          }\nTray: ${
            response.data.data.tray === "" ? "N/A" : response.data.data.tray
          }`
        );
      } else {
        dispatch(checkLenswareStatusFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(checkLenswareStatusFailure());
      errorToast(error.message);
      reportError(error);
    });
};

export const resetHomeState = () => {
  return {
    type: RESET_HOME_STATE,
  };
};

export const printStockOrderStart = () => {
  return {
    type: PRINT_STOCK_ORDER_START,
  };
};
export const printStockOrderSuccess = () => {
  return {
    type: PRINT_STOCK_ORDER_SUCCESS,
  };
};
export const printStockOrderFailure = () => {
  return {
    type: PRINT_STOCK_ORDER_FAILURE,
  };
};
export const printStockOrder = (id) => async (dispatch) => {
  dispatch(printStockOrderStart());
  return printStockOrderApiCall(id)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(printStockOrderSuccess());
      } else {
        dispatch(printStockOrderFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(printStockOrderFailure());
      reportError(error);
      errorToast("Error", error.message);
    });
};

export const archiveStockOrderStart = () => {
  return {
    type: ARCHIVE_STOCK_ORDER_START,
  };
};
export const archiveStockOrderSuccess = () => {
  return {
    type: ARCHIVE_STOCK_ORDER_SUCCESS,
  };
};
export const archiveStockOrderFailure = () => {
  return {
    type: ARCHIVE_STOCK_ORDER_FAILURE,
  };
};
export const archiveStockOrder = (id) => async (dispatch) => {
  dispatch(archiveStockOrderStart());
  return archiveStockOrderApiCall(id)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(archiveStockOrderSuccess());
        successToast("Success", "Order Archieved");
        // dispatch(fetchStockOrdersList(1));
      } else {
        dispatch(archiveStockOrderFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      console.log(error);
      dispatch(archiveStockOrderFailure());
      reportError(error);
      errorToast("Error", error.message);
    });
};

export const checkFormEditStatusStart = () => {
  return {
    type: CHECK_FORM_EDIT_STATUS_START,
  };
};
export const checkFormEditStatusSuccess = (status) => {
  return {
    type: CHECK_FORM_EDIT_STATUS_SUCCESS,
    payload: status,
  };
};
export const checkFormEditStatusFailure = () => {
  return {
    type: CHECK_FORM_EDIT_STATUS_FAILURE,
  };
};
export const checkFormEditStatus = (id) => (dispatch) => {
  dispatch(checkFormEditStatusStart());
  return lenswareStatusApiCall(id)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        const userType = getUserType();
        let status =
          response.data.data.status === ""
            ? null
            : response.data.data.status[lenswareStatusUserMapping[userType]];

        dispatch(checkFormEditStatusSuccess(status));
      } else {
        dispatch(checkFormEditStatusFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(checkFormEditStatusFailure());
      errorToast(error.message);
      reportError(error);
    });
};
export const updateOrderStart = () => {
  return {
    type: UPDATE_ORDER_START,
  };
};
export const updateOrderSuccess = () => {
  return {
    type: UPDATE_ORDER_SUCCESS,
  };
};
export const updateOrderFailure = () => {
  return {
    type: UPDATE_ORDER_FAILURE,
  };
};
export const updateOrder = (id, data) => (dispatch) => {
  dispatch(updateOrderStart());
  return orderUpdationApi(id, data)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(updateOrderSuccess());
        successToast("Success", "Order Updated");
        dispatch(push("/"));
      } else {
        dispatch(updateOrderFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(updateOrderFailure());
      errorToast(error.message);
      reportError(error);
    });
};

const getStockPickingStart = () => {
  return {
    type: GET_STOCK_PICKING_ORDERS_START,
  };
};
const getStockPickingSuccess = (payload) => {
  return {
    type: GET_STOCK_PICKING_ORDERS_SUCCESS,
    payload,
  };
};
const getStockPickingFailure = () => {
  return {
    type: GET_STOCK_PICKING_ORDERS_FAILURE,
  };
};
export const getStockPickingOrder = (page) => (dispatch) => {
  dispatch(getStockPickingStart());
  return getStockPickingOrders(page)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(getStockPickingSuccess(response.data.data));
      } else {
        dispatch(getStockPickingFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(getStockPickingFailure());
      errorToast(error.message);
    });
};

const getMultiStockGroupedOrdersStart = () => {
  return {
    type: GET_MULTI_STOCK_GROUPED_ORDERS_START,
  };
};
const getMultiStockGroupedOrdersSuccess = (payload) => {
  return {
    type: GET_MULTI_STOCK_GROUPED_ORDERS_SUCCESS,
    payload,
  };
};
const getMultiStockGroupedOrdersFailure = () => {
  return {
    type: GET_MULTI_STOCK_GROUPED_ORDERS_FAILURE,
  };
};
export const getMultiStockGroupedOrders = (page) => (dispatch) => {
  dispatch(getMultiStockGroupedOrdersStart());
  return getMultiStockGroupedOrdersAPI(page)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(getMultiStockGroupedOrdersSuccess(response.data.data));
      } else {
        dispatch(getMultiStockGroupedOrdersFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(getMultiStockGroupedOrdersFailure());
      errorToast(error.message);
    });
};

const getMultiStockPickingStart = () => {
  return {
    type: GET_MULTI_STOCK_PICKING_ORDERS_START,
  };
};
const getMultiStockPickingSuccess = (payload) => {
  return {
    type: GET_MULTI_STOCK_PICKING_ORDERS_SUCCESS,
    payload,
  };
};
const getMultiStockPickingFailure = () => {
  return {
    type: GET_MULTI_STOCK_PICKING_ORDERS_FAILURE,
  };
};
export const getMultiStockPickingOrder = (groupId) => (dispatch) => {
  dispatch(getMultiStockPickingStart());
  return getMultiStockPickingOrdersAPI(groupId)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(getMultiStockPickingSuccess(response.data.data));
      } else {
        dispatch(getMultiStockPickingFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(getMultiStockPickingFailure());
      errorToast(error.message);
    });
};
