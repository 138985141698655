import React, { Component } from "react";
import { Modal, Button } from "antd";
import { connect } from "react-redux";
import { closeModal } from "./modalActions";
import { push } from "connected-react-router";

const actions = { closeModal, push };
const mapStateToProps = state => ({});

class OrderValidationModal extends Component {
  handleOk = () => {
    const { closeModal, push } = this.props;
    closeModal();
    push("/");
  };
  render() {
    const { uiMessage, closeModal } = this.props;
    return (
      <Modal
        title="Order Validation"
        visible={true}
        onOk={this.handleOk}
        onCancel={closeModal}
        footer={[
          <Button type="primary" onClick={this.handleOk}>
            Close
          </Button>
        ]}
      >
        <p>{uiMessage}</p>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  actions
)(OrderValidationModal);
