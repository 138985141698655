import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "../table/Table";
import Paginater from "../table/Paginater";
import { getMultiStockGroupedOrders } from "../../homeActions";
import { openModal, closeModal } from "../../../modal/modalActions";

class MultiStockGroupedTab extends Component {
  state = {};

  columns = [
    {
      title: "Stock Group Id",
      dataIndex: "stock_group_id",
      sorter: (a, b) => a.stock_group_id - b.stock_group_id,
      sortDirections: ["ascend"],
      render: (text, record, index) => (
        <p
          className="validate-link"
          onClick={() =>
            this.props.openModal("MultiStockOrderListModal", {
              groupId: record.stock_group_id,
            })
          }
        >
          {text}
        </p>
      ),
      width: 250,
    },
    {
      title: "Customer",
      dataIndex: "customer.name",
      width: 500,
    },
    {
      title: "Distributor",
      dataIndex: "distributor.name",
    },
    {
      title: "Order Count",
      dataIndex: "stock_count",
    },
  ];

  render() {
    const { loading, orders, getMultiStockGroupedOrders } = this.props;
    return (
      <div>
        <Table
          rowClassName={(record, text) => {
            let end = new Date();
            let start = new Date(record.created_on);
            let hoursDiff = end.getHours() - start.getHours();
            return hoursDiff > 1 ? "highlighted-row" : "";
          }}
          columns={this.columns}
          loading={loading}
          data={orders.results}
          defaultPageSize={40}
          pagination={false}
          bordered={true}
          scroll={{ x: 1300 }}
          size="small"
        />
        <div style={{ textAlign: "center", marginTop: "4%" }}>
          <Paginater
            defaultCurrent={1}
            total={orders.count}
            onChange={getMultiStockGroupedOrders}
            defaultPageSize={40}
          />
        </div>
      </div>
    );
  }
}
const actions = {
  getMultiStockGroupedOrders,
  closeModal,
  openModal,
};
const mapStateToProps = (state) => ({
  loading: state.home.loadingMultiStockGroupedOrders,
  orders: state.home.multiStockGroupedOrders,
  activeTabKey: state.home.activeTabKey,
});
export default connect(
  mapStateToProps,
  actions
)(MultiStockGroupedTab);
