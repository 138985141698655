import React from "react";
import { Header } from "semantic-ui-react";
import {
  getItem,
  removeDuplicates,
  changeToUpperCase,
  getUserType,
  setItem,
  DBgetItem,
  DBsetItem,
} from "../../app/common/util/genericUtils";
import moment from "moment";
import lodashGet from "lodash/get";

export const fieldRangeMapper = {
  add: {
    min: "add_min_prescription",
    max: "add_max_prescription",
  },
  cyl: {
    min: "cyl_min_prescription",
    max: "cyl_max_prescription",
  },
  dia: {
    min: "dia_min_prescription",
    max: "dia_max_prescription",
  },
};
export const leftEyeArr = [
  "left_lens_config.sph",
  "left_lens_config.cyl",
  "left_lens_config.axis",
  "left_lens_config.add",
  "left_lens_config.dia",
  "left_lens_config.ellh",
  "left_lens_config.swing",
  "left_lens_config.mbase",
  "left_lens_config.et",
  "left_lens_config.ct",
  "left_lens_config.ed",
];
export const rightEyeArr = [
  "sph",
  "cyl",
  "axis",
  "add",
  "dia",
  "ellh",
  "swing",
  "mbase",
  "et",
  "ct",
  "ed",
];
export const convertToTwoDeciamlPoint = (val) => {
  //console.log(val);
  if (!val) {
    return val;
  }
  const onlyNums = val.replace(/[^\d]/g, "");
  if (onlyNums.length === 1) {
    return `${onlyNums.slice(0, 1)}.${onlyNums.slice(1, 3)}`;
  }
  if (onlyNums.length <= 2) {
    return `${onlyNums.slice(0, 1)}.${onlyNums.slice(1, 3)}`;
  }
  if (onlyNums.length <= 3) {
    return `${onlyNums.slice(0, 1)}.${onlyNums.slice(1, 3)}`;
  }
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 4)}`;
  }
};
export const convertToDecimal = (value) => {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 3) {
    return `${onlyNums.slice(0, 1)}.${onlyNums.slice(1, 3)}`;
  }
  if (onlyNums.length <= 4) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 4)}`;
  }
};
export const objArrSort = (objArray) => {
  const arr = objArray.sort(function(a, b) {
    return a.label.localeCompare(b.label);
  });

  return arr;
};

export const getProductGroupMasterList = async () => {
  const divisionList = await DBgetItem("divisionList");
  let list = [];
  if (divisionList) {
    divisionList.forEach((item) => {
      list.push({
        key: item.id,
        label: item.code,
      });
    });
  }
  let sortedArr = objArrSort(list);
  return sortedArr;
};
export const getCustomerList = async () => {
  const customerList = await DBgetItem("customerList");
  let nameList = [];
  if (customerList) {
    customerList.forEach((customer) => {
      nameList.push({
        label:
          customer.name +
          " - " +
          customer.nickname +
          " - " +
          customer.customer_accn,
        key: customer.id,
      });
    });
    let sortedArr = objArrSort(nameList);
    return sortedArr;
  }
};
export const getCustomerListWithBranchTrue = async () => {
  const customerList = await DBgetItem("customerList");
  if (customerList) {
    let nameList = customerList
      .filter((customer) => customer.is_branch === true)
      .map((filteredCustomer) => {
        return {
          label:
            filteredCustomer.name +
            " - " +
            filteredCustomer.nickname +
            " - " +
            filteredCustomer.customer_accn,
          key: filteredCustomer.id,
        };
      });
    let sortedArr = objArrSort(nameList);
    return sortedArr;
  }
};
export const getCustomerFromId = async (id) => {
  const customerList = await DBgetItem("customerList");
  let cust;
  if (customerList) {
    customerList.forEach((customer) => {
      if (id === customer.id) {
        cust = customer;
      }
    });
    return cust;
  }
};
export const getDistributorFromCustomerId = async (customerId, distributorId) => {
  const customerList = await DBgetItem("customerList");
  let distributorData;
  if (customerList) {
    customerList.forEach((customer) => {
      if (customerId === customer.id) {
        customer.distributor.forEach((distributor) => {
          if (distributor.id === distributorId) {
            distributorData = {
              label: distributor.name + " - " + distributor.nickname,
              key: distributor.id,
            };
          }
        });
      }
    });
    return distributorData;
  }
};
export const getCustomerDistributorsFromId = async (value) => {
  const distributorList = [];
  const customerList = await DBgetItem("customerList");
  if (customerList) {
    for (let i = 0; i < customerList.length; i++) {
      if (customerList[i].id === value) {
        console.log("Selected Customer", customerList[i]);
        customerList[i].distributor.forEach((distributor) => {
          const dist = {
            label: distributor.name + " - " + distributor.nickname,
            key: distributor.id,
          };
          distributorList.push(dist);
        });
        break;
      }
    }
  }

  let sortedArr = objArrSort(distributorList);
  return sortedArr;
};

export const getRetailersFromCustomerId = async (value) => {
  const retailerList = [];
  const customerList = await DBgetItem("customerList");
  if (customerList) {
    for (let i = 0; i < customerList.length; i++) {
      if (customerList[i].id === value) {
        customerList[i].customer_retailer.forEach((retailer) => {
          const retailerData = {
            label: retailer.name + " - " + retailer.nickname,
            key: retailer.id,
          };
          retailerList.push(retailerData);
        });
        customerList[i].distributor.forEach((distributor) => {
          distributor.distributor_retailer.forEach((distRetailer) => {
            const distRetailerData = {
              label: distRetailer.name + " - " + distRetailer.nickname,
              key: distRetailer.id,
            };
            retailerList.push(distRetailerData);
          });
        });
        break;
      }
    }
  }

  const uniqueArr = removeDuplicates(retailerList, "key");
  //return uniqueArr;
  let sortedArr = objArrSort(uniqueArr);
  return sortedArr;
};

export const getProductGroupListForCustomer = async (customerId) => {
  const customerList = await DBgetItem("customerList");
  let list = [];
  let defaultProductGroup = null;
  let productGroupsForSelectedIdentifier = [];
  let defaultProductGroupId = null;
  let productGroupsIdForSelectedIdentifier = [];
  if (customerList) {
    for (let i = 0; i < customerList.length; i++) {
      if (customerList[i].id === customerId) {
        defaultProductGroupId = customerList[i].default_product_group;
        productGroupsIdForSelectedIdentifier = customerList[i].product_groups;
        break;
      }
    }
    const divisionList = await DBgetItem("divisionList");
    if (divisionList) {
      for (let j = 0; j < divisionList.length; j++) {
        if (productGroupsIdForSelectedIdentifier.includes(divisionList[j].id)) {
          productGroupsForSelectedIdentifier.push(divisionList[j]);
          list.push({
            label: divisionList[j].code,
            key: divisionList[j].id,
          });
          if (divisionList[j].id == defaultProductGroupId) {
            defaultProductGroup = divisionList[j];
          }
        }
      }
    }
  }
  if (
    defaultProductGroup !== null &&
    productGroupsForSelectedIdentifier.length > 0
  ) {
    await DBsetItem("selectedProductGroup", defaultProductGroup);
    await DBsetItem(
      "productGroupsForSelectedIdentifier",
      productGroupsForSelectedIdentifier
    );
  }

  let sortedArr = objArrSort(list);
  return {
    productGroupList: sortedArr,
    defaultProductGroup,
  };
};
export const getProductGroupListForDistributor = async (
  customerId,
  distributorId
) => {
  const customerList = await DBgetItem("customerList");
  let list = [];
  let defaultProductGroup = null;
  let productGroupsForSelectedIdentifier = [];
  let defaultProductGroupId = null;
  let productGroupsIdForSelectedIdentifier = [];
  if (customerList) {
    for (let i = 0; i < customerList.length; i++) {
      if (customerList[i].id === customerId) {
        customerList[i].distributor.forEach((distributor) => {
          if (distributor.id === distributorId) {
            defaultProductGroupId = distributor.default_product_group;
            productGroupsIdForSelectedIdentifier = distributor.product_groups;
          }
        });
        break;
      }
    }
    const divisionList = await DBgetItem("divisionList");
    if (divisionList) {
      for (let j = 0; j < divisionList.length; j++) {
        if (productGroupsIdForSelectedIdentifier.includes(divisionList[j].id)) {
          productGroupsForSelectedIdentifier.push(divisionList[j]);
          list.push({
            label: divisionList[j].code,
            key: divisionList[j].id,
          });
          if (divisionList[j].id == defaultProductGroupId) {
            defaultProductGroup = divisionList[j];
          }
        }
      }
    }
  }
  if (
    defaultProductGroup !== null &&
    productGroupsForSelectedIdentifier.length > 0
  ) {
    await DBsetItem("selectedProductGroup", defaultProductGroup);
    await DBsetItem(
      "productGroupsForSelectedIdentifier",
      productGroupsForSelectedIdentifier
    );
  }

  let sortedArr = objArrSort(list);
  return {
    productGroupList: sortedArr,
    defaultProductGroup,
  };
};
export const getLnamConfigFromId = async (lnamId) => {
  let selectedProduct = await DBgetItem("selectedProductGroup");
  // console.log("Selected product group and lnam id", selectedProduct, lnamId);
  let config = {};
  for (let i = 0; i < selectedProduct.config.length; i++) {
    if (selectedProduct.config[i].lnam.id === lnamId) {
      config = selectedProduct.config[i];
      break;
    }
  }
  delete config["coating"];
  return config;
};
export const getProductGroupListForRetailer = async (customerId, retailerId) => {
  const customerList = await DBgetItem("customerList");
  let list = [];
  let defaultProductGroup = null;
  let productGroupsForSelectedIdentifier = [];
  let found = false;
  let defaultProductGroupId = null;
  let productGroupsIdForSelectedIdentifier = [];
  if (customerList) {
    for (let i = 0; i < customerList.length; i++) {
      if (customerList[i].id === customerId) {
        if (customerList[i].customer_retailer.length > 0) {
          for (let j = 0; j < customerList[i].customer_retailer.length; j++) {
            if (customerList[i].customer_retailer[j].id === retailerId) {
              found = true;
              defaultProductGroupId =
                customerList[i].customer_retailer[j].default_product_group;
              productGroupsIdForSelectedIdentifier =
                customerList[i].customer_retailer[j].product_groups;
              break;
            }
          }
        }
        if (customerList[i].distributor.length > 0 && found === false) {
          for (let k = 0; k < customerList[i].distributor.length; k++) {
            customerList[i].distributor[k].distributor_retailer.forEach(
              (distRetailer) => {
                if (distRetailer.id === retailerId) {
                  defaultProductGroupId = distRetailer.default_product_group;
                  productGroupsIdForSelectedIdentifier =
                    distRetailer.product_groups;
                }
              }
            );
          }
        }
        break;
      }
    }
    const divisionList = await DBgetItem("divisionList");
    if (divisionList) {
      for (let j = 0; j < divisionList.length; j++) {
        if (productGroupsIdForSelectedIdentifier.includes(divisionList[j].id)) {
          productGroupsForSelectedIdentifier.push(divisionList[j]);
          list.push({
            label: divisionList[j].code,
            key: divisionList[j].id,
          });
          if (divisionList[j].id == defaultProductGroupId) {
            defaultProductGroup = divisionList[j];
          }
        }
      }
    }
  }

  if (
    defaultProductGroup !== null &&
    productGroupsForSelectedIdentifier.length > 0
  ) {
    await DBsetItem("selectedProductGroup", defaultProductGroup);
    await DBsetItem(
      "productGroupsForSelectedIdentifier",
      productGroupsForSelectedIdentifier
    );
  }

  let sortedArr = objArrSort(list);
  return {
    productGroupList: sortedArr,
    defaultProductGroup,
  };
};
export const transformLnamList = (list) => {
  let updatedList = [];
  list.forEach((item) => {
    const lnam = {
      label: item.lnam.name + " - " + item.lnam.description,
      key: item.lnam.id,
    };
    updatedList.push(lnam);
  });
  const uniqueLnams = removeDuplicates(updatedList, "key");
  return uniqueLnams;
};

export const transformStockDescriptionList = (list = []) => {
  let updatedList = list.map((item) => ({
    label: item.name,
    key: item.id,
  }));
  const uniqueStockDes = removeDuplicates(updatedList, "key");
  return uniqueStockDes;
};

export const getCustomerAddressfromId = async (value) => {
  const addressList = [];
  const customerList = await DBgetItem("customerList");
  if (customerList) {
    customerList.forEach((customer) => {
      if (customer.id === value) {
        customer.address.forEach((adr) => {
          const customerAddress = {
            label:
              adr.address_line + " - " + adr.city.name + " - " + adr.pincode,
            key: adr.id,
            dispatchType: {
              key: adr.dispatch_type.id,
              label: adr.dispatch_type.value,
            },
          };
          addressList.push(customerAddress);
        });
      }
    });
  }

  return addressList;
};

export const getRetailerDistributorAddressfromId = async (value) => {
  const addressList = [];
  const customerList = await DBgetItem("customerList");
  if (customerList) {
    customerList.forEach((customer) => {
      if (customer.id === value) {
        customer.distributor.forEach((distributor) => {
          distributor.address.forEach((adr) => {
            const distributorAddress = {
              label:
                adr.address_line + " - " + adr.city.name + " - " + adr.pincode,
              key: adr.id,
              dispatchType: {
                key: adr.dispatch_type.id,
                label: adr.dispatch_type.value,
              },
            };
            addressList.push(distributorAddress);
            distributor.distributor_retailer.forEach((disRetailer) => {
              disRetailer.address.forEach((adr) => {
                const disRetailerAddresss = {
                  label:
                    adr.address_line +
                    " - " +
                    adr.city.name +
                    " - " +
                    adr.pincode,
                  key: adr.id,
                  dispatchType: {
                    key: adr.dispatch_type.id,
                    label: adr.dispatch_type.value,
                  },
                };
                addressList.push(disRetailerAddresss);
              });
            });
          });
        });
        customer.customer_retailer.forEach((retailer) => {
          retailer.address.forEach((adr) => {
            const retailerAddress = {
              label:
                adr.address_line + " - " + adr.city.name + " - " + adr.pincode,
              key: adr.id,
              dispatchType: {
                key: adr.dispatch_type.id,
                label: adr.dispatch_type.value,
              },
            };
            addressList.push(retailerAddress);
          });
        });
      }
    });
  }
  const uniqueArr = removeDuplicates(addressList, "key");
  let sortedArr = objArrSort(uniqueArr);
  return sortedArr;
};
export const getEmployeeList = () => {
  const employeeList = [];
  const EmployeeInStorage = getItem("employeeList");
  if (EmployeeInStorage) {
    EmployeeInStorage.forEach((employee) => {
      const employeeData = {
        label: employee.name + " - " + employee.employee_code,
        key: employee.name,
      };
      employeeList.push(employeeData);
    });
  }
  let sortedArr = objArrSort(employeeList);
  return sortedArr;
};
export const getBranchList = () => {
  const branchList = [];
  const branchListInStorage = getItem("branchList");
  if (branchListInStorage) {
    branchListInStorage.forEach((employee) => {
      const employeeData = {
        label: employee.name + " - " + employee.g_accn,
        key: employee.name + " - " + employee.g_accn,
      };
      branchList.push(employeeData);
    });
  }
  let sortedArr = objArrSort(branchList);
  return sortedArr;
};
export const getDispatchList = () => {
  const dispatchList = [];
  const dispatchInStorage = getItem("dispatchList");
  if (dispatchInStorage) {
    dispatchInStorage.forEach((type) => {
      const dispatchData = {
        label: type.value,
        key: type.id,
      };
      dispatchList.push(dispatchData);
    });
  }
  return dispatchList;
};
export const getLnamList = () => {
  const lnamList = [];
  const lnamInStorage = getItem("lnamList");
  if (lnamInStorage) {
    lnamInStorage.forEach((lnam) => {
      const lnamItem = {
        label: lnam.name + " - " + lnam.description,
        key: lnam.id,
      };
      lnamList.push(lnamItem);
    });
  }

  let sortedArr = objArrSort(lnamList);
  return sortedArr;
};

export const getLnamDescription = (id) => {
  const lnamInStorage = getItem("lnamList");
  var lnamDescription;
  lnamInStorage.forEach((lnam) => {
    if (lnam.id === id) {
      lnamDescription = lnam.description;
    }
  });
  return lnamDescription;
};
export const getTintDescription = (id) => {
  const tintInStorage = getItem("tintList");
  var tintDescription;
  tintInStorage.forEach((tint) => {
    if (tint.id === id) {
      tintDescription =
        tint.type + " - " + tint.colour.value + " - " + tint.percentage + "%";
    }
  });
  return tintDescription;
};

export const getCustomerAddressDescription = async (customerId, addressId) => {
  //console.log(customerId, addressId);
  var addressDescription;
  const customerList = await DBgetItem("customerList");
  if (customerList) {
    customerList.forEach((customer) => {
      if (customer.id === customerId) {
        if (addressId) {
          customer.address.forEach((adr) => {
            if (adr.id === addressId) {
              addressDescription = adr.city.name + " - " + adr.location;
              //  console.log(addressDescription);
            }
          });
        } else {
          addressDescription =
            customer.address[0].city.name +
            " - " +
            customer.address[0].location;
          //  console.log(addressDescription);
        }
      }
    });
  }
  return addressDescription;
};

export const getFrameValues = () => {
  const frameValues = [
    { label: "Full Frame Plastic", key: "full_frame_plastic" },
    { label: "Full Frame Material", key: "full_frame_material" },
    { label: "Supra", key: "supra" },
    { label: "Rimless Punch", key: "rimless_punch" },
    { label: "Rimless Screw", key: "rimless_screw" },
  ];
  return frameValues;
};

export const getCoatingListFromLnamId = async (id) => {
  //coating of multiple lnams with duplicated removed
  const selectedProductGroup = await DBgetItem("selectedProductGroup");
  let coatingList = [];
  if (selectedProductGroup && selectedProductGroup.config) {
    selectedProductGroup.config.forEach((config) => {
      if (config.lnam.id === id) {
        config.coating.forEach((coating) => {
          coatingList.push({
            key: coating.id,
            label: coating.value,
          });
        });
      }
    });
  }
  const uniqueCoatings = removeDuplicates(coatingList, "key");
  //console.log(selectedProductGroup);
  return uniqueCoatings;
};

export const getCorridorList = (
  lnamId,
  fittingHeightId,
  corridorForFitting
) => {
  const lnamList = getItem("lnamList");
  const corridorList = [];
  if (lnamList !== null) {
    lnamList.forEach((lnam) => {
      if (lnam.id === lnamId) {
        if (corridorForFitting === true) {
          lnam.corridor.forEach((corridor) => {
            corridor.fitting_height.forEach((height) => {
              if (height.id === fittingHeightId) {
                const corridorData = {
                  key: corridor.id,
                  label: corridor.value,
                };

                corridorList.push(corridorData);
              }
            });
          });
        } else {
          lnam.corridor.forEach((corridor) => {
            const corridorData = {
              key: corridor.id,
              label: corridor.value,
            };
            corridorList.push(corridorData);
          });
        }
      }
    });
    return corridorList;
  }
};

export const getFittingHeight = (lnamId, corridorId, fittingForCorridor) => {
  const lnamList = getItem("lnamList");
  const fittingHeightList = [];
  if (lnamList !== null) {
    lnamList.forEach((lnam) => {
      if (lnam.id === lnamId) {
        lnam.corridor.forEach((corridor) => {
          if (fittingForCorridor === true) {
            if (corridor.id === corridorId) {
              corridor.fitting_height.forEach((height) => {
                const heightVal = {
                  key: parseInt(height.id),
                  label: parseInt(height.value),
                };
                fittingHeightList.push(heightVal);
              });
            }
          } else {
            corridor.fitting_height.forEach((height) => {
              const heightVal = {
                key: parseInt(height.id),
                label: parseInt(height.value),
              };
              fittingHeightList.push(heightVal);
            });
          }
        });
      }
    });
    const uniqueArr = removeDuplicates(fittingHeightList, "key");
    return uniqueArr;
  }
};
export const tintSpecificationData = [
  { key: "sample", label: "Sample" },
  { key: "standard", label: "Standard" },
];
export const getTintList = (spec) => {
  const tintData = getItem("tintList");
  const tintList = [];
  if (tintData !== null) {
    if (spec === "sample") {
      tintData.forEach((tint) => {
        if (tint.specification === "sample") {
          const tintItem = {
            label:
              tint.code +
              " - " +
              tint.type +
              " - " +
              tint.colour.value +
              " - " +
              tint.percentage +
              "%",
            key: tint.id,
          };
          tintList.push(tintItem);
        }
      });
    } else if (spec === "standard") {
      tintData.forEach((tint) => {
        if (tint.specification === "standard") {
          const tintItem = {
            label:
              tint.code +
              " - " +
              tint.type +
              " - " +
              tint.colour.value +
              " - " +
              tint.percentage +
              "%",
            key: tint.id,
          };
          tintList.push(tintItem);
        }
      });
    }

    return tintList;
  }
};

export const MandatoryHeader = ({ heading }) => {
  return (
    <div style={{ display: "flex" }}>
      <Header sub color="teal" content={heading} />
      <span className="mandatory-star">*</span>
    </div>
  );
};

// TODO: Negate this function def
export const doesFormDataExist = () => {
  const lnamList = getItem("lnamList");
  const tintList = getItem("tintList");
  const employeeList = getItem("employeeList");
  const dispatchList = getItem("dispatchList");
  //const branchList = getItem("branchList");
  const manufacturingUnitList = getItem("manufacturingUnitList");
  // const divisionList = await DBgetItem("divisionList")  removed in indexed db
  if (
    tintList === null ||
    lnamList == null ||
    employeeList == null ||
    dispatchList === null ||
    //branchList === null ||
    manufacturingUnitList === null ||
    tintList.length < 1 ||
    lnamList.length < 1 ||
    employeeList.length < 1 ||
    dispatchList.length < 1 ||
    ///branchList.length < 1 ||
    manufacturingUnitList.length < 1
  ) {
    console.log('doesFormDataExist', true)
    return true;
  } else {
    console.log('doesFormDataExist', false)
    return false;
  }
};

export const getLnamType = (id) => {
  const lnamList = getItem("lnamList");
  let lnamType;
  if (lnamList !== null) {
    lnamList.forEach((lnam) => {
      if (lnam.id === id) {
        lnamType = lnam.type.value;
      }
    });
    return lnamType;
  }
};

export const getSelectedProductGroupData = async (productGroupId) => {
  let productGroupsForSelectedIdentifier = await DBgetItem(
    "productGroupsForSelectedIdentifier"
  );

  let selectedProductGroup = {};

  productGroupsForSelectedIdentifier.forEach((group) => {
    if (group.id === productGroupId) {
      selectedProductGroup = group;
    }
  });

  return selectedProductGroup;
};

export const getSelectedPgFieldConfigFromLnamId = async (lnamId) => {
  const selectedProductGroup = await DBgetItem("selectedProductGroup");
  let config = {};
  if (selectedProductGroup) {
    for (let i = 0; i < selectedProductGroup.config.length; i++) {
      let currentConfig = selectedProductGroup.config[i];
      if (currentConfig.lnam.id === lnamId) {
        const { ellh, add, tint, prism } = currentConfig;
        config = {
          ellh,
          add,
          tint,
          prism,
        };
        break;
      }
    }
  }
  return config;
};
export const getLnamName = (id) => {
  const lnamList = getItem("lnamList");
  let lnamName;
  if (lnamList !== null) {
    lnamList.forEach((lnam) => {
      if (lnam.id === id) {
        lnamName = lnam.name;
      }
    });
    return lnamName;
  }
};

export const lensConfig = [
  "sph",
  "cyl",
  "axis",
  "add",
  "dia",
  "ellh",
  "swing",
  "mbase",
  "et",
  "ct",
  "ed",
];
export const frameFields = [
  "mono_pd_r",
  "mono_pd_l",
  "npd_r",
  "npd_l",
  "a_width",
  "b_width",
  "dbl",
  "panto",
  "bvd",
  "ztilt",
];
export const frameFieldsWithPrism = [
  "mono_pd_r",
  "mono_pd_l",
  "npd_r",
  "npd_l",
  "a_width",
  "b_width",
  "dbl",
  "panto",
  "bvd",
  "ztilt",
  "right_prism",
  "left_prism",
];
export const add_ons = [
  "special_instructions",
  "in_out",
  "other_charges",
  "tool_charges",
];
export const prismFields = ["prvm1", "prva1", "prvm2", "prva2"];
export const commons = ["patient", "external_order_id", "clinit"];
export const makeFormData = (data) => {
  const commons = makeCommonsData(data);
  const rightEyeConfig = makeRightEyeConfig(data);
  const leftEyeConfig = makeLeftEyeConfig(data);
  const frame = makeFrameData(data);
  const addOns = makeAddOnsData(data);
  const formJson = {
    ...commons,
    ...rightEyeConfig,
    ...leftEyeConfig,
    ...frame,
    ...addOns,
  };
  console.log(formJson);
  return formJson;
};

export const makeCommonsData = (data) => {
  const formData = {};
  formData.status = data.status;
  formData.id = data.id;
  formData.customer = {
    key: data.customer.id,
    label:
      data.customer.name +
      " - " +
      data.customer.nickname +
      " - " +
      data.customer.customer_accn,
  };
  if (data.distributor !== null) {
    formData.distributor = {
      label: data.distributor.name + " - " + data.distributor.nickname,
      key: data.distributor.id,
    };
  }
  if (data.retailer !== null) {
    formData.retailer = {
      key: data.retailer.id,
      label: data.retailer.name + " - " + data.retailer.nickname,
    };
  }
  if (data.product_group !== null) {
    formData.product_group = {
      key: data.product_group.id,
      label: data.product_group.code,
    };
  }
  if (data.reference_id !== null) {
    formData.reference_id = data.reference_id;
  }
  formData.internal_order_id = data.internal_order_id;

  commons.forEach((field) => {
    if (data[field] !== undefined && data[field] !== null) {
      formData[field] = data[field];
    }
  });
  formData.shipping_same_as_billing = String(data.shipping_same_as_billing);
  formData.shipping_address = {
    key: data.shipping_address.id,
    label:
      data.shipping_address.address_line +
      " - " +
      data.shipping_address.city.name +
      " - " +
      data.shipping_address.pincode,
  };
  if (data.received_by !== null && data.received_by !== "") {
    formData.received_by = {
      key: data.received_by,
      label: data.received_by,
    };
  }
  let userType = getUserType();
  if (userType === "Branch") {
    if (data.entered_by !== null && data.entered_by !== "") {
      formData.entered_by = {
        key: data.entered_by,
        label: data.entered_by,
      };
    }
  } else {
    if (data.entered_by !== null && data.entered_by !== "") {
      formData.entered_by = data.entered_by;
    }
  }

  if (
    data.manufacturing_business_unit !== null &&
    data.manufacturing_business_unit !== ""
  ) {
    formData.manufacturing_business_unit = {
      key: data.manufacturing_business_unit.id,
      label: data.manufacturing_business_unit.short_name,
    };
  }
  formData.dispatch_type = {
    key: data.dispatch_type.id,
    label: data.dispatch_type.value,
  };
  if (data.received_date !== null) {
    formData.received_hour = new Date(data.received_date).getHours();
    formData.received_minute = new Date(data.received_date).getMinutes();
    formData.received_date = data.received_date;
  }

  if (data.promised_date !== null) {
    formData.promised_date = data.promised_date;
    formData.promised_hour = new Date(data.promised_date).getHours();
    formData.promised_minute = new Date(data.promised_date).getMinutes();
  }

  if (data.urgent !== null) {
    formData.urgent = data.urgent.toString();
  }
  if (data.cto !== null) {
    formData.cto = data.cto.toString();
  }

  if (data.stock_description !== null) {
    formData.stock_description = {
      key: data.stock_description.id,
      label: data.stock_description.name,
    };
  }

  formData.lnam = {
    key: data.lnam.id,
    label: data.lnam.name + " - " + data.lnam.description,
  };
  if (data.coating !== null) {
    formData.coating = {
      key: data.coating.id,
      label: data.coating.value,
    };
  }
  if (data.corridor !== null) {
    formData.corridor = {
      key: data.corridor.id,
      label: data.corridor.value,
    };
  }
  if (data.fitting_height !== null) {
    formData.fitting_height = {
      key: data.fitting_height.id,
      label: data.fitting_height.value,
    };
  }
  return formData;
};
export const makeRightEyeConfig = (data) => {
  const formData = {};
  if (data.right_lens_config !== null && data.right_lens_config !== undefined) {
    formData.right_lens_config = {};
    lensConfig.forEach((config) => {
      if (
        data.right_lens_config[config] !== null &&
        data.right_lens_config[config] !== undefined
      ) {
        if (config === "mbase") {
          formData.right_lens_config["mbase"] = {
            key: data.right_lens_config["mbase"],
            label: data.right_lens_config["mbase"],
          };
        } else {
          Object.assign(formData.right_lens_config, {
            [config]: data.right_lens_config[config],
          });
        }
      }
    });
    if (
      formData.right_lens_config !== undefined &&
      formData.right_lens_config !== null
    ) {
      formData.right_lens_config["re"] = true;
    }
  }
  return formData;
};

export const makeLeftEyeConfig = (data) => {
  console.log("the data is ", data);
  const formData = {};
  if (data.left_lens_config !== null && data.left_lens_config !== undefined) {
    formData.left_lens_config = {};
    lensConfig.forEach((config) => {
      if (
        data.left_lens_config[config] !== null &&
        data.left_lens_config[config] !== undefined
      ) {
        if (config === "mbase") {
          formData.left_lens_config["mbase"] = {
            key: data.left_lens_config["mbase"],
            label: data.left_lens_config["mbase"],
          };
        } else {
          Object.assign(formData.left_lens_config, {
            [config]: data.left_lens_config[config],
          });
        }
      }
    });
    if (
      formData.left_lens_config !== undefined &&
      formData.left_lens_config !== null
    ) {
      formData.left_lens_config["le"] = true;
    }
  }
  return formData;
};
export const makeFrameData = (data) => {
  const formData = {};
  if (data.frame !== null) {
    formData.frame = {};
    frameFields.forEach((field) => {
      if (data.frame[field] !== null && data.frame[field] !== undefined) {
        Object.assign(formData.frame, { [field]: data.frame[field] });
      }
    });
    if (data.frame.frame_type !== null && data.frame.frame_type !== undefined) {
      // console.log("type:", formData.frame.frame_type);
      formData.frame["frame_type"] = {
        key: data.frame.frame_type,
        label: refactorSnakeCase(data.frame.frame_type),
      };
    }

    if (
      data.frame.right_prism !== null &&
      data.frame.right_prism !== undefined
    ) {
      formData.frame.right_prism = {};
      prismFields.forEach((field) => {
        if (
          data.frame.right_prism[field] !== null &&
          data.frame.right_prism[field] !== undefined
        ) {
          Object.assign(formData.frame.right_prism, {
            [field]: data.frame.right_prism[field],
          });
        }
      });
    }
    if (data.frame.left_prism !== null && data.frame.left_prism !== undefined) {
      formData.frame.left_prism = {};
      prismFields.forEach((field) => {
        if (
          data.frame.left_prism[field] !== null &&
          data.frame.left_prism[field] !== undefined
        ) {
          Object.assign(formData.frame.left_prism, {
            [field]: data.frame.left_prism[field],
          });
        }
      });
    }
  }
  return formData;
};

export const makeAddOnsData = (data) => {
  const formData = {};
  if (data.add_ons !== null) {
    formData.add_ons = {};
    if (data.add_ons.tint !== null) {
      formData.add_ons["tint"] = {
        key: data.add_ons.tint.id,
        label: data.add_ons.tint.code,
      };
      formData.add_ons["tint_needed"] = true;
      formData.add_ons["specification"] = {
        key: data.add_ons.tint.specification,
        label: changeToUpperCase(data.add_ons.tint.specification),
      };
    }
    if (data.add_ons.fitting_received !== null) {
      if (data.add_ons.fitting_received === true) {
        formData.add_ons["fitting_needed"] = true;
      }

      formData.add_ons[
        "fitting_received"
      ] = data.add_ons.fitting_received.toString();
    }
    add_ons.forEach((field) => {
      if (data.add_ons[field] !== null && data.add_ons[field] !== undefined) {
        Object.assign(formData.add_ons, { [field]: data.add_ons[field] });
      }
    });
  }
  return formData;
};
export const refactorSnakeCase = (str) => {
  const strWithoutUnderscore = str.replace("/_/g", " ");
  const uppercase = strWithoutUnderscore.toUpperCase();
  return uppercase;
};

export const refactorPayload = (payload) => {
  const data = {};
  for (let [key, value] of Object.entries(payload)) {
    if (value && value.key) {
      value = value.key;
      value !== "" ? (data[key] = value) : (data[key] = null);
    } else if (value && key === "frame") {
      data.frame = {};
      value.frame_type !== "" && value.frame_type !== undefined
        ? (data.frame["frame_type"] = value.frame_type.key)
        : (data.frame["frame_type"] = null);

      frameFieldsWithPrism.forEach((field) => {
        if (
          payload.frame[field] !== null &&
          payload.frame[field] !== undefined
        ) {
          payload.frame[field] !== ""
            ? (data.frame[field] = value[field])
            : (data.frame[field] = null);
        }
      });
    } else if (value && key === "add_ons") {
      data.add_ons = {};
      value.tint !== "" && value.tint !== undefined
        ? (data.add_ons.tint = value.tint.key)
        : (data.add_ons.tint = null);
      value.specification !== "" && value.specification !== undefined
        ? (data.add_ons.specification = value.specification.key)
        : (data.add_ons.specification = null);
      if (value.fitting_received !== undefined) {
        value.fitting_received !== ""
          ? (data.add_ons["fitting_received"] =
              payload.add_ons.fitting_received)
          : (data.add_ons["fitting_received"] = null);
      }
      add_ons.forEach((field) => {
        if (payload.add_ons[field] !== null) {
          payload.add_ons[field] !== ""
            ? (data.add_ons[field] = value[field])
            : (data.add_ons[field] = null);
        }
      });
    } else if (value && key === "right_lens_config") {
      if (value.re && value.re === true) {
        data.right_lens_config = {};
        lensConfig.forEach((field) => {
          if (
            payload.right_lens_config[field] !== null &&
            payload.right_lens_config[field] !== undefined
          ) {
            if (field === "mbase") {
              payload.right_lens_config[field] !== ""
                ? (data.right_lens_config[field] = value[field].key)
                : (data.right_lens_config[field] = null);
            } else {
              payload.right_lens_config[field] !== ""
                ? (data.right_lens_config[field] = value[field])
                : (data.right_lens_config[field] = null);
            }
          }
        });
      }
    } else if (value && key === "left_lens_config") {
      if (value.le && value.le === true) {
        data.left_lens_config = {};
        lensConfig.forEach((field) => {
          if (
            payload.left_lens_config[field] !== null &&
            payload.left_lens_config[field] !== undefined
          ) {
            if (field === "mbase") {
              payload.left_lens_config[field] !== ""
                ? (data.left_lens_config[field] = value[field].key)
                : (data.left_lens_config[field] = null);
            } else {
              payload.left_lens_config[field] !== ""
                ? (data.left_lens_config[field] = value[field])
                : (data.left_lens_config[field] = null);
            }
          }
        });
      }
    } else if (value !== null && value !== undefined) {
      value !== "" ? (data[key] = payload[key]) : (data[key] = null);
    }
  }
  const dataWithCombinedDateTime = combineDateTime(data);
  return dataWithCombinedDateTime;
};

export const getCoatingCode = (lnamID, coatingId) => {
  const lnamList = getItem("lnamList");
  let coatingCode = null;
  if (lnamList !== null) {
    lnamList.forEach((lnam) => {
      if (lnam.id === lnamID) {
        lnam.coating.forEach((coating) => {
          if (coating.id === coatingId) {
            coatingCode = coating.code;
          }
        });
      }
    });
  }

  return coatingCode;
};
export const getAddFieldIncludeFlag = (lnamId) => {
  const lnamList = getItem("lnamList");
  let addFieldFlag;
  if (lnamList !== null) {
    lnamList.forEach((lnam) => {
      if (lnam.id === lnamId) {
        if (lnam.include_add_field === true) {
          addFieldFlag = true;
        } else {
          addFieldFlag = false;
        }
      }
    });
    return addFieldFlag;
  }
};
export const getLnamPower = async (lnamId) => {
  // const lnamList = getItem("lnamList");
  // let lnamPower = {};
  // if (lnamList !== null) {
  //   lnamList.forEach(lnam => {
  //     if (lnam.id === lnamId) {
  //       lnamPower = {
  //         startPower: lnam.start_power,
  //         endPower: lnam.end_power,
  //         includeAddField: lnam.include_add_field
  //       };
  //     }
  //   });
  //   return lnamPower;
  // }
  let lnamConfig = await getLnamConfigFromId(lnamId);
  console.log("lnamConfig is", lnamConfig);
  let lnamPower = {
    // startPower: lnamConfig.min_prescription,
    // endPower: lnamConfig.max_prescription,
    // Picking this up from pg config
    includeAddField: lnamConfig.lnam.include_add_field,
  };
  console.log("lnamPower", lnamPower);
  return lnamPower;
};

export const momentFormat = moment().format("YYYY-MM-DD HH:mm");
export const coatingTimingMap = {
  uncoat: {
    requiredDateTime: moment(momentFormat)
      .milliseconds(0)
      .seconds(0)
      .add("1", "hours")
      .toDate(),
  },
  aqua: {
    requiredDateTime: moment(momentFormat)
      .milliseconds(0)
      .seconds(0)
      .add("4", "hours")
      .toDate(),
  },
  pureviz: {
    requiredDateTime: moment(momentFormat)
      .milliseconds(0)
      .seconds(0)
      .add("4", "hours")
      .toDate(),
  },
  others: {
    requiredDateTime: moment(momentFormat)
      .milliseconds(0)
      .seconds(0)
      .add("7", "hours")
      .toDate(),
  },
};

export const getDateString = (dateObj) => {
  const dateString = moment(dateObj).format("YYYY-MM-DD");
  return dateString;
};
export const getTimeString = (timeObj) => {
  const timeString = moment(timeObj).format("HH:mm");
  // console.log(timeObj,timeString)
  return timeString;
};
export const combineDateTime = (data) => {
  const payload = { ...data };
  if (payload.received_date !== undefined) {
    const receivedDateString = getDateString(payload.received_date);
    const receivedTimeString =
      payload.received_hour + ":" + payload.received_minute;
    const receivedDate = receivedDateString + " " + receivedTimeString;
    payload["received_date"] = receivedDate;
    console.log("date ......", payload.received_date);
    if (payload.promised_date !== null && payload.promised_date !== undefined) {
      const promisedDateString = getDateString(payload.promised_date);
      const promisedTimeString =
        payload.promised_hour + ":" + payload.promised_minute;
      const promisedDate = promisedDateString + " " + promisedTimeString;
      payload["promised_date"] = promisedDate;
    }
    return payload;
  } else {
    return data;
  }
};

// Search utils
export const getDistributorList = async () => {
  const customerListInStorage = await DBgetItem("customerList");

  if (!customerListInStorage) {
    return [];
  }

  const allDistributors = customerListInStorage.flatMap(
    (item) => item.distributor
  );

  const uniqueDistributors = [];
  allDistributors.forEach((distributor) => {
    const distributorAlreadyAdded = uniqueDistributors.find(
      (item) => item.key === distributor.id
    );
    if (!distributorAlreadyAdded) {
      uniqueDistributors.push({
        key: distributor.id,
        label: distributor.name,
      });
    }
  });
  return uniqueDistributors;
};
export const getRetailerList = async () => {
  const customerListInStorage = await DBgetItem("customerList");

  if (!customerListInStorage) {
    return [];
  }

  const allRetailers = customerListInStorage.flatMap(
    (item) => item.customer_retailer
  );

  const uniqueRetailers = [];
  allRetailers.forEach((retailer) => {
    const retailerAlreadyAdded = uniqueRetailers.find(
      (item) => item.key === retailer.id
    );
    if (!retailerAlreadyAdded) {
      uniqueRetailers.push({
        key: retailer.id,
        label: retailer.name,
      });
    }
  });
  return uniqueRetailers;
};

export const getManufacturingUnitList = () => {
  const muInStorage = getItem("manufacturingUnitList");
  let muList = [];
  if (muInStorage) {
    muInStorage.forEach((unit) => {
      let mu = {
        label: unit.short_name,
        key: unit.id,
      };
      muList.push(mu);
    });
    return muList;
  }
};

export const getCoatingList = () => {
  const lnamInStorage = getItem("lnamList");

  if (!lnamInStorage) {
    return [];
  }

  const allCoatings = lnamInStorage.flatMap((item) => item.coating);

  const uniqueCoatings = [];
  allCoatings.forEach((coating) => {
    const coatingAlreadyAdded = uniqueCoatings.find(
      (item) => item.key === coating.id
    );
    if (!coatingAlreadyAdded) {
      uniqueCoatings.push({
        key: coating.id,
        label: coating.value,
      });
    }
  });
  return uniqueCoatings;
};

export const getDistributorDataFromList = (distributorId, distributorList) => {
  let distributor = null;

  for (let i = 0; i < distributorList.length; i++) {
    if (distributorId === distributorList[i].id) {
      distributor = {
        label: distributorList[i].name + " - " + distributorList[i].nickname,
        key: distributorList[i].id,
      };
      break;
    }
  }
  return distributor;
};

export const getPgConfigIdentifier = (pgId, lnamId) => `${pgId}~${lnamId}`;

export const getPgDataForSelectedLnam = (state) => {
  const pgId = lodashGet(
    state,
    "form.orderForm.values.product_group.key",
    null
  );
  const lnamId = lodashGet(state, "form.orderForm.values.lnam.key", null);

  const configIdentifier = getPgConfigIdentifier(pgId, lnamId);

  return lodashGet(
    state,
    `orderForm.pgDependentFields.${configIdentifier}`,
    {}
  );
};
export const tooltips = {
  lnam: () => (
    <img
      style={{ width: "500px" }}
      src="http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder.gif"
      alt="field-tooltip"
    />
  ),
  corridor: () => (
    <img
      style={{ width: "500px" }}
      src="http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder.gif"
      alt="field-tooltip"
    />
  ),
  fitting_height: () => (
    <img
      style={{ width: "500px" }}
      src="http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder.gif"
      alt="field-tooltip"
    />
  ),
  swing: () => (
    <img
      style={{ width: "500px" }}
      src="http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder.gif"
      alt="field-tooltip"
    />
  ),
  frame_type: () => (
    <img
      style={{ width: "500px" }}
      src="http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder.gif"
      alt="field-tooltip"
    />
  ),
  dbl: () => (
    <img
      style={{ width: "500px" }}
      src="http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder.gif"
      alt="field-tooltip"
    />
  ),
  panto: () => (
    <img
      style={{ width: "500px" }}
      src="http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder.gif"
      alt="field-tooltip"
    />
  ),
  ztilt: () => (
    <img
      style={{ width: "500px" }}
      src="http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder.gif"
      alt="field-tooltip"
    />
  ),
  bvd: () => (
    <img
      style={{ width: "500px" }}
      src="http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder.gif"
      alt="field-tooltip"
    />
  ),
  npd_r: () => (
    <img
      style={{ width: "500px" }}
      src="http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder.gif"
      alt="field-tooltip"
    />
  ),
  npd_l: () => (
    <img
      style={{ width: "500px" }}
      src="http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder.gif"
      alt="field-tooltip"
    />
  ),
  mono_pd_r: () => (
    <img
      style={{ width: "500px" }}
      src="http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder.gif"
      alt="field-tooltip"
    />
  ),
  mono_pd_l: () => (
    <img
      style={{ width: "500px" }}
      src="http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder.gif"
      alt="field-tooltip"
    />
  ),
  a_width: () => (
    <img
      style={{ width: "500px" }}
      src="http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder.gif"
      alt="field-tooltip"
    />
  ),
  b_width: () => (
    <img
      style={{ width: "500px" }}
      src="http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder.gif"
      alt="field-tooltip"
    />
  ),
  ct: () => (
    <img
      style={{ width: "500px" }}
      src="http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder.gif"
      alt="field-tooltip"
    />
  ),
  et: () => (
    <img
      style={{ width: "500px" }}
      src="http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder.gif"
      alt="field-tooltip"
    />
  ),
  mbase: () => (
    <img
      style={{ width: "500px" }}
      src="http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder.gif"
      alt="field-tooltip"
    />
  ),
  urgent: () => (
    <img
      style={{ width: "500px" }}
      src="http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder.gif"
      alt="field-tooltip"
    />
  ),
  tint: () => (
    <img
      style={{ width: "500px" }}
      src="http://www.jennybeaumont.com/wp-content/uploads/2015/03/placeholder.gif"
      alt="field-tooltip"
    />
  ),
};
