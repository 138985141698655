import React from "react";
import { Row, Spin } from "antd";
import { Label } from "semantic-ui-react";
import FieldWrapper from "../FieldWrapper";
import CustomSelect from "../../../app/common/form/CustomSelect";
import { connect } from "react-redux";

class Location extends React.Component {
  render() {
    const {
      initialValues,
      canEditLocation,
      labelInValue,
      loadingMfLocation,
      mfLocationDependencies,
    } = this.props;
    return (
      <>
        <Row>
          <FieldWrapper
            name="manufacturing_business_unit"
            type="select"
            disabled={initialValues && canEditLocation === false}
            component={CustomSelect}
            placeholder="Manufacturing Location"
            data={this.muList}
            allowClear={true}
            showSearch={true}
            labelInValue={labelInValue}
            heading="Manufacturing Location"
            mandatory={true}
            width={6}
          />
        </Row>
        {loadingMfLocation && (
          <div style={{ marginTop: "20px" }}>
            <Spin />
            <Label color="blue" style={{ marginLeft: "15px" }}>
              Please wait we are getting Manufacturing Location
            </Label>
          </div>
        )}
        {!loadingMfLocation && mfLocationDependencies.state === "LOADED" && (
          <Label color="green" style={{ marginTop: "20px" }}>
            Manufacturing Location loaded.
          </Label>
        )}
        {!loadingMfLocation && mfLocationDependencies.state === "NOT_LOADED" && (
          <Label color="red" style={{ marginTop: "20px" }}>
            Manufacturing Location not loaded(enter Cyl).
          </Label>
        )}
      </>
    );
  }
}

const actions = {};

const mapStateToProps = (state) => ({
  mfLocationDependencies: state.orderForm.mfLocationDependencies,
  loadingMfLocation: state.orderForm.loadingMfLocation,
});
export default connect(
  mapStateToProps,
  actions
)(Location);
