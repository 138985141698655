import {
  GET_WHATSAPP_ORDERS,
  GET_WHATSAPP_ORDERS_SUCCESS,
  GET_WHATSAPP_ORDERS_FAILURE,
  ARCHIVE_ORDER_START,
  ARCHIVE_ORDER_SUCCESS,
  ARCHIVE_ORDER_FAILURE,
  PUNCH_WHATSAPP_ORDER_START,
  PUNCH_WHATSAPP_ORDER_SUCCESS,
  PUNCH_WHATSAPP_ORDER_FAILURE,
  SET_CURRENT_ACTIVE_ORDER,
  SET_MULTIPLE_ORDER_FLAG,
  SET_DATA_FOR_FORM,
  GET_IMAGE_DATA_START,
  GET_IMAGE_DATA_SUCCESS,
  GET_IMAGE_DATA_FAILURE,
  GET_INTERNAL_ORDER_ID_START,
  GET_INTERNAL_ORDER_ID_SUCCESS,
  GET_INTERNAL_ORDER_ID_FAILURE,
} from "./whatsappOrderConstants";
import {
  whatsAppOrdersApiCall,
  archiveOrder,
  submitWhatsappOrder,
  imageDataApiCall,
  internalOrderIdApiCall,
} from "./api";
import {
  errorToast,
  ErrorModal,
  successToast,
} from "../../app/common/util/genericUtils";
import { reportError } from "../../app/common/util/sentryConfig";
import {
  printWhatsappImage,
  makeCustomerData,
  getAddressList,
  dataWithoutCustomer,
  checkLargestNumberInArray,
} from "./whatsappUtils";
import { push } from "connected-react-router";
import { closeModal, openModal } from "../modal/modalActions";
import {
  setCustomerId,
  setAddressList,
  formSubmitOperations,
  customerActions,
  setAddressDescription,
} from "../orderPunching/orderActions";
import { payloadForOrderPunching } from "../../app/common/form/payloadUtils";
import { reset, change } from "redux-form";
import {
  getCustomerFromId,
  getCustomerAddressDescription,
  getDistributorFromCustomerId,
  getCustomerAddressfromId,
  combineDateTime,
} from "../orderPunching/formUtils";
import { ORDER_TYPES } from "../../app/common/util/genericConstants";
export const getWhatsappOrders = () => {
  return {
    type: GET_WHATSAPP_ORDERS,
  };
};

export const getWhatsappOrdersSuccess = (response) => {
  return {
    type: GET_WHATSAPP_ORDERS_SUCCESS,
    payload: response,
  };
};

export const getWhatsappOrdersFailure = () => {
  return {
    type: GET_WHATSAPP_ORDERS_FAILURE,
  };
};

export const getWhatsappOrdersList = (page) => async (dispatch) => {
  dispatch(getWhatsappOrders());
  return whatsAppOrdersApiCall(page)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(getWhatsappOrdersSuccess(response.data.data));
      } else {
        dispatch(getWhatsappOrdersFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(getWhatsappOrdersFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const archiveOrderStart = () => {
  return {
    type: ARCHIVE_ORDER_START,
  };
};
export const archiveOrderSuccess = () => {
  return {
    type: ARCHIVE_ORDER_SUCCESS,
  };
};
export const archiveOrderFailure = () => {
  return {
    type: ARCHIVE_ORDER_FAILURE,
  };
};

export const archiveWhatsappOrder = (id) => async (dispatch) => {
  dispatch(archiveOrderStart());
  return archiveOrder(id)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(archiveOrderSuccess());
        successToast("Success", "Order archived Successfully");
        dispatch(getWhatsappOrdersList());
      } else {
        dispatch(archiveOrderFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(archiveOrderFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};
export const setMultipleOrderFlag = (flag) => {
  return {
    type: SET_MULTIPLE_ORDER_FLAG,
    payload: flag,
  };
};
export const setDataForForm = (payload) => {
  return {
    type: SET_DATA_FOR_FORM,
    payload,
  };
};
export const setCurrentActiveOrder = (payload) => {
  return {
    type: SET_CURRENT_ACTIVE_ORDER,
    payload,
  };
};

export const setCustomerDependencies = (customerId, distributorId) => async (
  dispatch
) => {
  let customerObj = { key: customerId };
  if (customerId !== null && customerId !== undefined) {
    let customerData = await getCustomerFromId(customerId);
    console.log(customerData);
    dispatch(change("orderForm", "customer", customerData));
  }
  if (distributorId !== null && distributorId !== undefined) {
    let distributorData = await getDistributorFromCustomerId(
      customerId,
      distributorId
    );

    console.log(distributorData);
    dispatch(change("orderForm", "distributor", distributorData));
  }

  let adrDescription = getCustomerAddressDescription(customerId);
  dispatch(customerActions(customerObj));
  dispatch(setAddressDescription(adrDescription));
};
export const checkCustommerForOrder = (order) => async (dispatch) => {
  dispatch(setCurrentActiveOrder(order));
  dispatch(closeModal());
  if (order.customer === null) {
    ErrorModal(
      "Warning",
      `Please call/Whatsapp Ph. No. “${
        order.from_number
      }” and verify Customer Details.`
    );
    const data = dataWithoutCustomer(order);
    dispatch(setDataForForm(data));
    dispatch(push(`/create/whatsapp_order/${ORDER_TYPES.RX}/${order.id}`));
  } else {
    const customerData = await makeCustomerData(order);
    //const addressList = getCustomerAddressfromId(order.customer);
    dispatch(setDataForForm(customerData));
    dispatch(customerActions({ key: order.customer }));
    //dispatch(setCustomerId(order.customer));
    //dispatch(setAddressList(addressList));
    //dispatch(setCustomerDependencies(order.customer, order.distributor));
    dispatch(push(`/create/whatsapp_order/${ORDER_TYPES.RX}/${order.id}`));
  }
};
export const printMediaWithRedirect = (order) => (dispatch) => {
  dispatch(printWhatsappImage(order));
  dispatch(closeModal());
  dispatch(checkCustommerForOrder(order));
};
export const multipleOrderFlow = (order) => (dispatch) => {
  dispatch(setMultipleOrderFlag(true));
  dispatch(printMediaWithRedirect(order));
};
export const punchWhatsappOrderStart = () => {
  return {
    type: PUNCH_WHATSAPP_ORDER_START,
  };
};
export const punchWhatsappOrderSuccess = () => {
  return {
    type: PUNCH_WHATSAPP_ORDER_SUCCESS,
  };
};
export const punchWhatsappOrderFailure = () => {
  return {
    type: PUNCH_WHATSAPP_ORDER_FAILURE,
  };
};

export const punchWhatsappOrder = (data, imgData, multipleOrderFlag) => async (
  dispatch
) => {
  dispatch(punchWhatsappOrderStart());
  data["source"] = "whatsapp";
  const dataWithReafctoredDate = combineDateTime(data);
  const payload = payloadForOrderPunching(dataWithReafctoredDate);
  return submitWhatsappOrder(payload, imgData.id)
    .then((response) => {
      console.log(response);
      if (response && response.data.response_code === 80) {
        dispatch(punchWhatsappOrderSuccess());
        dispatch(formSubmitOperations());
        dispatch(reset("orderForm"));
        if (multipleOrderFlag === true) {
          dispatch(openModal("WhatsappImageModal", { imgData }));
        } else {
          dispatch(noMoreOrder(imgData.id));
        }
      } else {
        dispatch(punchWhatsappOrderFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(punchWhatsappOrderFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const noMoreOrder = (imageId) => (dispatch) => {
  dispatch(archiveWhatsappOrder(imageId));
  dispatch(setMultipleOrderFlag(false));
  dispatch(push("/"));
  dispatch(openModal("WhatsappModal"));
};

export const getImageDataStart = () => {
  return {
    type: GET_IMAGE_DATA_START,
  };
};
export const getImageDataSuccess = () => {
  return {
    type: GET_IMAGE_DATA_SUCCESS,
  };
};
export const getImageDataFailure = () => {
  return {
    type: GET_IMAGE_DATA_FAILURE,
  };
};

export const getImageData = (id) => (dispatch) => {
  dispatch(getImageDataStart());
  return imageDataApiCall(id)
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(getImageDataSuccess());
        dispatch(checkCustommerForOrder(response.data.data));
      } else {
        errorToast("Error", response.data.ui_message);
        dispatch(getImageDataFailure());
      }
    })
    .catch((error) => {
      dispatch(getImageDataFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const getInternalOrderIdStart = () => {
  return {
    type: GET_INTERNAL_ORDER_ID_START,
  };
};
export const getInternalOrderIdSuccess = () => {
  return {
    type: GET_INTERNAL_ORDER_ID_SUCCESS,
  };
};
export const getInternalOrderIdFailure = () => {
  return {
    type: GET_INTERNAL_ORDER_ID_FAILURE,
  };
};

export const getInternalOrderId = () => (dispatch) => {
  dispatch(getInternalOrderIdStart());
  return internalOrderIdApiCall()
    .then((response) => {
      if (response && response.data.response_code === 80) {
        dispatch(getInternalOrderIdSuccess());
        const maxId = checkLargestNumberInArray(response.data.data) + 1;
        dispatch(change("orderForm", "internal_order_id", String(maxId)));
      } else {
        dispatch(getInternalOrderIdFailure());
        errorToast("Error", response.data.ui_message);
      }
    })
    .catch((error) => {
      dispatch(getInternalOrderIdFailure());
      errorToast("Error", error.message);
      reportError(error);
    });
};

export const setInternalOrderId = (data) => (dispatch) => {};
