import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "../table/Table";
import { fetchPendingOrders, getOrderDetailsForForm } from "../../homeActions";
import Paginater from "../table/Paginater";
import moment from "moment";
import { openModal } from "../../../modal/modalActions";
import { Tag, Button } from "antd";
import { PermissibleRender } from "@brainhubeu/react-permissible";
import { getUserPermissions } from "../../../../app/common/util/genericUtils";

class PendingAuthTab extends Component {
  state = {};
  componentDidMount() {
    //  if (this.props.activeTabKey === "2") {
    //  this.props.fetchPendingOrders();
    //}
  }
  columns = [
    {
      title: "Order No.",
      dataIndex: "internal_order_id",
      sorter: (a, b) => a.internal_order_id - b.internal_order_id,
      sortDirections: ["ascend"],
      render: (text, record, index) => (
        <p
          className="validate-link"
          onClick={() => this.props.getOrderDetailsForForm(record.id, index)}
        >
          {text}
        </p>
      ),
    },
    {
      title: "Reference Id",
      dataIndex: "reference_id",
    },
    {
      title: "Entry Date & Time",
      dataIndex: "received_date",
      render: (text) => {
        if (text !== null) {
          return <p>{moment(text).format("YYYY-MM-DD HH:mm")}</p>;
        }
      },
    },
    {
      title: "Coating",
      dataIndex: "coating.value",
    },
    {
      title: "LNAM",
      dataIndex: "lnam.name",
    },
    {
      title: "Customer",
      dataIndex: "customer.name",
    },
    {
      title: "Promise Date & Time",
      dataIndex: "promised_date",
      render: (text) => {
        if (text !== null) {
          return <p>{moment(text).format("YYYY-MM-DD HH:mm")}</p>;
        }
      },
    },
    {
      title: "Transport Mode",
      dataIndex: "dispatch_type.value",
    },
    {
      title: "Order Source",
      dataIndex: "source",
    },
    {
      title: "Labels",
      width: 150,
      render: (text, record) => {
        return (
          <div>
            {record &&
              record.label.length > 0 &&
              record.label.map((item, index) => {
                return (
                  <Tag color="blue" key={index}>
                    {item.label.short_code}
                  </Tag>
                );
              })}
          </div>
        );
      },
    },
    {
      title: "Entered By",
      dataIndex: "received_by",
    },
    {
      title: "Type",
      dataIndex: "is_stock",
      render: (text, record) => {
        return record.is_stock ? <p>Stock</p> : <p>RX</p>;
      },
    },
    {
      title: "Actions",
      fixed: "right",
      width: 100,
      render: (text, record) => {
        const userPermissions = getUserPermissions();
        return (
          <div>
            <PermissibleRender
              userPermissions={userPermissions}
              requiredPermissions={["can_add_label"]}
            >
              <div>
                <Button
                  style={{ margin: "7% 0 0 5%" }}
                  size="small"
                  type="primary"
                  onClick={() =>
                    this.props.openModal("CreateLabelModal", { id: record.id })
                  }
                >
                  Add Label
                </Button>
              </div>
            </PermissibleRender>
            <PermissibleRender
              userPermissions={userPermissions}
              requiredPermissions={["can_link_order"]}
            >
              <Button
                style={{ margin: "7% 0 0 5%" }}
                size="small"
                type="primary"
                onClick={() =>
                  this.props.openModal("LinkedOrderModal", {
                    type: "linkOldOrder",
                    linkingOrderId: record.id,
                  })
                }
              >
                Link Order
              </Button>
            </PermissibleRender>
          </div>
        );
      },
    },
  ];

  render() {
    const { loading, orders, fetchPendingOrders } = this.props;
    return (
      <div>
        <Table
          columns={this.columns}
          loading={loading}
          data={orders.results}
          defaultPageSize={40}
          pagination={false}
          bordered={true}
          scroll={{ x: 1300 }}
          size="small"
        />
        <div style={{ textAlign: "center", marginTop: "4%" }}>
          <Paginater
            defaultCurrent={1}
            total={orders.count}
            onChange={fetchPendingOrders}
            defaultPageSize={40}
          />
        </div>
      </div>
    );
  }
}

const actions = {
  fetchPendingOrders,
  getOrderDetailsForForm,
  openModal,
};
const mapStateToProps = (state) => ({
  loading: state.home.loadingPendingAuthOrders,
  orders: state.home.pendingAuthOrders,
  orderDetailsLoading: state.home.orderDetailsLoading,
  activeTabKey: state.home.activeTabKey,
});
export default connect(
  mapStateToProps,
  actions
)(PendingAuthTab);
