import React from "react";
import { Row, Col, Button } from "antd";
import { combineDateTime } from "../formUtils";
import { connect } from "react-redux";
import { checkDuplicateOrders } from "../orderActions";
import { withRouter } from "react-router-dom";
import ValidateButtonSet from "./rxSets/ValidateButtonSet";
import { ORDER_TYPES } from "../../../app/common/util/genericConstants";

class StockOrderActions extends React.Component {
  handleSaveNewOrder = (values) => {
    const { productGroupOwner } = this.props;
    Object.assign(values, {
      status: "processing",
      source: "manual",
      order_source: ORDER_TYPES.STOCK_SINGLE,
      product_group_owner: productGroupOwner,
    });
    const payload = combineDateTime(values);
    return this.props.checkDuplicateOrders(payload);
  };

  handleSaveWithExit = (values) => {
    const { productGroupOwner, history } = this.props;
    Object.assign(values, {
      status: "processing",
      source: "manual",
      order_source: ORDER_TYPES.STOCK_SINGLE,
      product_group_owner: productGroupOwner,
    });
    const payload = combineDateTime(values);
    const callBack = () => {
      history.push("/");
    };
    return this.props.checkDuplicateOrders(payload, callBack);
  };

  render() {
    const {
      handleSubmit,
      handleBtnDisable,
      onFormHold,
      type,
      invalid,
      initialValues,
      productGroupOwner,
    } = this.props;
    let disabled = handleBtnDisable();

    if (type === "create") {
      return (
        <Row style={{ height: 100 }}>
          <Col span={5}>
            <Button
              size="large"
              onClick={handleSubmit(this.handleSaveNewOrder)}
              disabled={disabled}
            >
              Save and Enter new order
            </Button>
          </Col>
          <Col span={3}>
            <Button
              size="large"
              disabled={disabled}
              onClick={handleSubmit(this.handleSaveWithExit)}
            >
              Save and Exit
            </Button>
          </Col>
          <Col span={5}>
            <Button
              size="large"
              disabled={disabled}
              onClick={handleSubmit(onFormHold)}
            >
              Save and Hold
            </Button>
          </Col>
        </Row>
      );
    } else if (type === "validate" || "validatefromHome") {
      return (
        <ValidateButtonSet
          invalid={invalid}
          handleSubmit={handleSubmit}
          details={initialValues}
          type={type}
          showNextPrevious={true}
          productGroupOwner={productGroupOwner}
        />
      );
    }
  }
}

const mapStateToProps = (state) => ({
  productGroupOwner: state.orderForm.productGroupOwner,
});

const actions = {
  checkDuplicateOrders,
};

const StockOrderActionButtons = connect(
  mapStateToProps,
  actions
)(withRouter(StockOrderActions));

export { StockOrderActionButtons };
