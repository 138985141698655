import React, { Component } from "react";
import { reduxForm, FormSection, touch } from "redux-form";
import { connect } from "react-redux";
import { Form, Header } from "semantic-ui-react";
import { validate } from "./orderFormValidations";
import TextInput from "../../app/common/form/TextInput";
import RadioInput from "../../app/common/form/RadioInput";
import { Prompt } from "react-router-dom";
import DateInput from "../../app/common/form/DateInput";

import {
  getCustomerList,
  getCustomerAddressfromId,
  getRetailerDistributorAddressfromId,
  getEmployeeList,
  combineDateTime,
  getDispatchList,
  getCustomerAddressDescription,
  getManufacturingUnitList,
  transformLnamList,
  getCustomerListWithBranchTrue,
  transformStockDescriptionList,
  getSelectedProductGroupData,
} from "./formUtils";
import { openModal } from "../modal/modalActions";
import { Row, Checkbox } from "antd";
import RightEyeConfig from "./formSections/RightEyeConfig";
import LeftEyeConfig from "./formSections/LeftEyeConfig";
import Frame from "./formSections/Frame";
import AddOns from "./formSections/AddOns";
import {
  customerActions,
  handleDistributorActions,
  handleRetailerActions,
  submitForm,
  setCustomerId,
  setAddressList,
  setUrgency,
  checkCoatingTimeline,
  resetFields,
  checkOrderId,
  checkDuplicateOrders,
  setShippingAddress,
  setDispatchType,
  setCtoFieldsMandatory,
  setAddressDescription,
  setDistributorList,
  setRetailerList,
  setProductGroupList,
  setProductGroupDescription,
  setLnamListForProductGroup,
  setLnamDescription,
  setProductGroupOwner,
  setFieldDisableOverideFlag,
  setStockDescriptionList,
} from "./orderActions";

import CustomSelect from "../../app/common/form/CustomSelect";
import LoaderComponent from "../../app/common/util/LoaderComponent";
import {
  disableUpcomingDates,
  getUserType,
  getItem,
  setItem,
  getUserPermissions,
  DBsetItem,
} from "../../app/common/util/genericUtils";
import FieldWrapper from "./FieldWrapper";
import DropDown from "../../app/common/form/DropDown";
import { withRouter } from "react-router-dom";
import Location from "./formSections/Location";
import { ORDER_TYPES } from "../../app/common/util/genericConstants";
import { RxOrderActionButton } from "./buttonSets/RxOrderActionButtons";
import { StockOrderActionButtons } from "./buttonSets/StockOrderActionButtons";
import { ProductSection } from "./ProductSection";
import { MultiStockLensSection } from "./formSections/MultiStockLensSection";

export class OrderForm extends Component {
  state = {
    hasCustomerCare: false,
    canEditLocation: false,
  };
  async componentDidMount() {
    const userType = getUserType();
    const userPermissions = getUserPermissions();
    const user = getItem("suprolUser");
    if (userType !== "Corporate") {
      this.setState({
        hasCustomerCare: user.has_customer_care,
      });
    }
    if (
      userPermissions.includes("can_change_manufacturing_location") === true
    ) {
      this.setState({
        canEditLocation: true,
      });
    }
    this.callAllData();
  }

  handleResetFields = (fields = []) =>
    fields.forEach((field) => this.props.change(field, ""));

  handleCustomerField = (value) => {
    const { customerActions } = this.props;
    if (value && value.key) {
      customerActions(value);
    } else {
      this.resetCustomerDependentFields();
    }
  };
  resetCustomerDependentFields = () => {
    const {
      setLnamDescription,
      setProductGroupDescription,
      setAddressDescription,
      setProductGroupOwner,
      setDistributorList,
      setRetailerList,
      setLnamListForProductGroup,
      setProductGroupList,
      setStockDescriptionList,
    } = this.props;

    this.handleResetFields([
      "distributor",
      "retailer",
      "dispatch_type",
      "shipping_address",
      "product_group",
      "lnam",
      "coating",
      "fitting_height",
      "corridor",
    ]);
    setLnamDescription(null);
    setAddressDescription(null);
    setProductGroupDescription(null);
    setProductGroupOwner(null);
    setDistributorList([]);
    setRetailerList([]);
    setProductGroupList([]);
    setLnamListForProductGroup([]);
    setStockDescriptionList([]);
  };
  handleDistributorChange = (value) => {
    const { customerId, handleDistributorActions } = this.props;
    handleDistributorActions(value, customerId);
  };
  handleRetailerChange = (value) => {
    const { customerId, handleRetailerActions } = this.props;
    handleRetailerActions(value, customerId);
  };
  handleProductGroupChange = async (pg) => {
    const {
      setProductGroupDescription,
      setLnamListForProductGroup,
      setStockDescriptionList,
      change,
      match: { params },
    } = this.props;

    if (pg && pg.key) {
      const selectedProductGroup = await getSelectedProductGroupData(pg.key);
      console.log('1.', selectedProductGroup)

      await DBsetItem("selectedProductGroup", selectedProductGroup);
      let lnamList = transformLnamList(selectedProductGroup.config);

      setLnamListForProductGroup(lnamList);
      setProductGroupDescription(selectedProductGroup.description);

      const stockDescriptionList = transformStockDescriptionList(
        selectedProductGroup.stock_description
      );
      setStockDescriptionList(stockDescriptionList);
    } else {
      setLnamListForProductGroup([]);
      setProductGroupDescription(null);
      setStockDescriptionList([]);
    }

    if (params.orderType === ORDER_TYPES.STOCK_MULTI) {
      change("lens", [{}]);
    } else {
      this.handleResetFields([
        "stock_description",
        "lnam",
        "coating",
        "corridor",
        "fitting_height",
      ]);
    }
    this.handleResetFields([
      "stock_description",
      "lnam",
      "coating",
      "corridor",
      "fitting_height",
    ]);
  };

  onFormSubmit = (values) => {
    const { productGroupOwner } = this.props;
    Object.assign(values, {
      status: "pending_auth",
      source: "manual",
      product_group_owner: productGroupOwner,
      order_source: this.props.match.params.orderType,
    });
    const payload = combineDateTime(values);
    return this.props.checkDuplicateOrders(payload);
  };

  onFormHold = (values) => {
    const formData = combineDateTime(values);
    formData["product_group_owner"] = this.props.productGroupOwner;
    formData["order_source"] = this.props.match.params.orderType;
    const data = {
      formData,
    };
    this.props.openModal("HoldModal", data);
  };
  handleUrgentField = (e, val) => {
    const { setUrgency } = this.props;
    val === "true" ? setUrgency(true) : setUrgency(false);
  };
  handleCtoField = (e, val) => {
    const { setCtoFieldsMandatory, touch } = this.props;
    val === "true" ? setCtoFieldsMandatory(true) : setCtoFieldsMandatory(false);
    let fields = ["mono_pd_r", "mono_pd_l", "dbl", "a_width", "b_width"];
    fields.forEach((field) => {
      touch("orderForm", `frame.${field}`);
    });
  };
  setShippingAddress = async (e, val) => {
    const {
      customerId,
      setAddressList,
      setShippingAddress,
      setDispatchType,
    } = this.props;
    let addressList = [];
    val === "true"
      ? (addressList = await getCustomerAddressfromId(customerId))
      : (addressList = await getRetailerDistributorAddressfromId(customerId));
    setAddressList(addressList);
    if (addressList.length > 0) {
      setShippingAddress(addressList[0]);
      setDispatchType(addressList[0]["dispatchType"]);
    } else {
      setShippingAddress(null);
      setDispatchType(null);
    }
  };
  setDispatchTypeOnAddressChange = async (obj) => {
    console.log(obj);
    const { setDispatchType, customerId } = this.props;
    if (obj && obj.dispatchType) {
      setShippingAddress(obj.dispatchType);
      setDispatchType(obj.dispatchType);
    } else {
      setShippingAddress(null);
      setDispatchType(null);
    }
    const adrDescription = await getCustomerAddressDescription(customerId, obj.key);
    this.setState({ adrDescription });
  };
  componentWillUnmount() {
    this.props.resetFields();
  }

  handleBtnDisable = () => {
    const { invalid, rightEyeCheckbox, leftEyeCheckbox } = this.props;

    if (rightEyeCheckbox === false && leftEyeCheckbox === false) {
      return true;
    } else {
      if (invalid === true) {
        return true;
      } else {
        return false;
      }
    }
  };

  handleCustDistCheckbox = (e) => {
    this.props.change("customer", "");
    this.props.setFieldDisableOverideFlag(!e.target.checked);
    this.resetCustomerDependentFields();
  };

    
  callAllData = async() => {
    this.customerData = await getCustomerList();
    this.customerDataWithBranchTrue = await getCustomerListWithBranchTrue();
  }

  // lnamList = getLnamList();
  employeeList = getEmployeeList();
  dispatchList = getDispatchList();
  //branchList = getBranchList();
  muList = getManufacturingUnitList();
  render() {
    const {
      distributorList,
      retailerList,
      handleSubmit,
      invalid,
      addressList,
      submittingForm,
      type,
      labelInValue,
      validatingPendingAuth,
      change,
      addressDescription,
      initialValues,
      punchingWhatsappOrder,
      dirty,
      productGroupDescription,
      productGroupList,
      productGroupOwner,
      fieldDisableOverideFlag,
      match: { params },
    } = this.props;

    const userType = getUserType();

    const isMultiStockOrder = params.orderType === ORDER_TYPES.STOCK_MULTI;
    const isSingleStockOrder = params.orderType === ORDER_TYPES.STOCK_SINGLE;
    const isRXOrder = params.orderType === ORDER_TYPES.RX;

    if (submittingForm || validatingPendingAuth || punchingWhatsappOrder) {
      return <LoaderComponent />;
    }
    const { hasCustomerCare, canEditLocation } = this.state;
    return (
      <div>
        <Prompt
          when={dirty}
          message={(location) => `Are you sure you want to leave the Page`}
        />
        <div style={{ width: "85%", margin: "3% 0 2% 10%" }}>
          <Form error size="large" autoComplete="off">
            <Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Header color="blue" content="Customer Section" />
                {userType === "Branch" && hasCustomerCare === true && (
                  <div>
                    <Checkbox
                      checked={!fieldDisableOverideFlag}
                      onChange={this.handleCustDistCheckbox}
                    />
                    <span>Enable Customer & Distributor</span>
                  </div>
                )}
              </div>

              <Row gutter={10}>
                <FieldWrapper
                  name="reference_id"
                  component={TextInput}
                  type="text"
                  placeholder="Reff. No."
                  //handleOnBlur={checkOrderId}
                  heading="Reference Id"
                  mandatory={false}
                  width={4}
                />
                <CustomerDetails
                  labelInValue={labelInValue}
                  customerData={
                    !fieldDisableOverideFlag &&
                    userType === "Branch" &&
                    hasCustomerCare === true
                      ? this.customerDataWithBranchTrue
                      : this.customerData
                  }
                  customerActions={this.handleCustomerField}
                  heading="Customer Details"
                  mandatory={true}
                  width={12}
                  disabled={fieldDisableOverideFlag}
                />
                {/* <Field
                    name="customer"
                    component={CustomSelect}
                    placeholder="Customer Details"
                    data={this.customerData}
                    showSearch={true}
                    getSelectedValue={customerActions}
                    allowClear={true}
                    labelInValue={labelInValue}
                  /> */}

                <FieldWrapper
                  name="distributor"
                  // component={CustomSelect}
                  component={DropDown}
                  type="select"
                  placeholder="Distributor Name"
                  data={distributorList}
                  allowClear={true}
                  labelInValue={labelInValue}
                  showSearch={true}
                  heading="Distributor Name"
                  mandatory={false}
                  width={8}
                  getSelectedValue={this.handleDistributorChange}
                  disabled={fieldDisableOverideFlag}
                />
              </Row>
              <Row gutter={10} style={{ marginTop: "2%" }}>
                <FieldWrapper
                  name="retailer"
                  // component={CustomSelect}
                  component={DropDown}
                  type="select"
                  placeholder="Retailer Name"
                  data={retailerList}
                  allowClear={true}
                  labelInValue={labelInValue}
                  showSearch={true}
                  heading="Retailer Name"
                  mandatory={false}
                  width={6}
                  //  getSelectedValue={this.handleRetailerChange}
                />
                <FieldWrapper
                  name="product_group"
                  component={DropDown}
                  type="select"
                  placeholder="Product Group"
                  data={productGroupList}
                  allowClear={true}
                  labelInValue={labelInValue}
                  showSearch={true}
                  heading="Product Group"
                  mandatory={true}
                  width={6}
                  description={productGroupDescription}
                  getSelectedValue={this.handleProductGroupChange}
                />
                <FieldWrapper
                  name="patient"
                  type="text"
                  component={TextInput}
                  placeholder="Patient Name"
                  heading="Patient Name"
                  mandatory={false}
                  width={6}
                />
                <FieldWrapper
                  name="external_order_id"
                  component={TextInput}
                  type="text"
                  placeholder="External Reff. No."
                  heading="External Order Id"
                  mandatory={false}
                  width={6}
                />

                <FieldWrapper
                  name="received_by"
                  component={CustomSelect}
                  data={this.employeeList}
                  type="select"
                  placeholder="Order Received By"
                  allowClear={true}
                  showSearch={true}
                  labelInValue={labelInValue}
                  heading="Order Received By"
                  mandatory={true}
                  width={6}
                />
                <FieldWrapper
                  name="entered_by"
                  component={userType === "Branch" ? CustomSelect : TextInput}
                  data={this.employeeList}
                  type={userType === "Branch" ? "select" : "text"}
                  placeholder="Order Entered By"
                  allowClear={true}
                  showSearch={true}
                  labelInValue={labelInValue}
                  heading="Order Entered By"
                  mandatory={userType === "Branch" ? true : false}
                  width={6}
                />
              </Row>
              <Row gutter={10} style={{ marginTop: "2%" }}>
                <FieldWrapper
                  name="shipping_same_as_billing"
                  type="radio"
                  valueOne="true"
                  labelOne="Yes"
                  valueTwo="false"
                  labelTwo="No"
                  component={RadioInput}
                  onChange={this.setShippingAddress}
                  heading="Shipping Same as Billing"
                  mandatory={true}
                  width={6}
                />

                <FieldWrapper
                  name="shipping_address"
                  type="select"
                  component={CustomSelect}
                  placeholder="Shipping Address"
                  data={addressList}
                  allowClear={true}
                  labelInValue={labelInValue}
                  showSearch={true}
                  getSelectedValue={this.setDispatchTypeOnAddressChange}
                  heading="Shipping Address"
                  mandatory={true}
                  width={6}
                  description={addressDescription}
                />

                <FieldWrapper
                  name="dispatch_type"
                  type="select"
                  component={CustomSelect}
                  placeholder="Dispatch Type"
                  data={this.dispatchList}
                  allowClear={true}
                  showSearch={true}
                  labelInValue={labelInValue}
                  heading="Dispatch Type"
                  mandatory={true}
                  width={6}
                />
                <FieldWrapper
                  name="received_date"
                  component={DateInput}
                  type="date"
                  placeholder="Order Received Date"
                  disableFunction={disableUpcomingDates}
                  heading="Order Received Date"
                  mandatory={true}
                  width={6}
                />
              </Row>
              <Row gutter={10} style={{ marginTop: "2%" }}>
                <FieldWrapper
                  name="received_hour"
                  component={TextInput}
                  type="text"
                  placeholder="Order Received Time(Hour)"
                  heading="Order Received Hour"
                  mandatory={true}
                  width={6}
                />
                <FieldWrapper
                  name="received_minute"
                  component={TextInput}
                  type="text"
                  placeholder="Order Received Time(Minute)"
                  heading="Order Received Minute"
                  mandatory={true}
                  width={6}
                />
                <FieldWrapper
                  name="urgent"
                  type="radio"
                  valueOne="true"
                  labelOne="Yes"
                  valueTwo="false"
                  labelTwo="No"
                  component={RadioInput}
                  onChange={this.handleUrgentField}
                  heading="Urgent"
                  mandatory={false}
                  width={6}
                />
              </Row>
              <Row gutter={10} />
              {isMultiStockOrder && (
                <MultiStockLensSection
                  labelInValue={labelInValue}
                  change={change}
                  handleSubmit={handleSubmit}
                  invalid={invalid}
                />
              )}
              {isSingleStockOrder || isRXOrder ? (
                <>
                  <ProductSection
                    labelInValue={labelInValue}
                    change={change}
                    touch={touch}
                  />
                  <Row style={{ padding: "0px", marginTop: "2%" }}>
                    <FormSection name="right_lens_config">
                      <RightEyeConfig
                        change={change}
                        labelInValue={labelInValue}
                        orderType={params.orderType}
                      />
                    </FormSection>
                    <FormSection name="left_lens_config">
                      <LeftEyeConfig
                        change={change}
                        labelInValue={labelInValue}
                        orderType={params.orderType}
                      />
                    </FormSection>
                  </Row>
                </>
              ) : null}
            </Row>

            {isRXOrder && (
              <RxOrderActionButton
                type={type}
                invalid={invalid}
                handleSubmit={handleSubmit}
                initialValues={initialValues}
                productGroupOwner={productGroupOwner}
                onFormHold={this.onFormHold}
                onFormSubmit={this.onFormSubmit}
                handleBtnDisable={this.handleBtnDisable}
                btnStyles={{
                  create: {
                    width: "20%",
                    margin: "2% 0% -2% 74%",
                    display: "inline-flex",
                  },
                  validate: {
                    margin: "2% 0% -2% 74%",
                  },
                  validateFromHome: {
                    margin: "2% 0% -2% 74%",
                  },
                  whatsapp: {
                    width: "20%",
                    margin: "2% 0% -2% 74%",
                    display: "inline-flex",
                  },
                  linking: {
                    width: "20%",
                    margin: "2% 0% -2% 74%",
                    display: "inline-flex",
                  },
                }}
              />
            )}

            {isSingleStockOrder || isRXOrder ? (
              <div style={{ marginTop: "5%" }}>
                <Header color="blue" content="Frame Section" />
                <FormSection name="frame">
                  <Frame labelInValue={labelInValue} change={change} />
                </FormSection>
              </div>
            ) : null}

            {isRXOrder ? (
              <div style={{ marginTop: "9%" }}>
                <Header color="blue" content="Location" />
                <Location
                  initialValues={initialValues}
                  canEditLocation={canEditLocation}
                  labelInValue={labelInValue}
                />
              </div>
            ) : null}

            {isSingleStockOrder || isRXOrder ? (
              <div style={{ marginTop: "20px" }}>
                <Header color="blue" content="Add Ons Section" />
                <FormSection name="add_ons">
                  <AddOns labelInValue={labelInValue} change={change} />
                </FormSection>
              </div>
            ) : null}

            {isRXOrder && (
              <RxOrderActionButton
                type={type}
                invalid={invalid}
                handleSubmit={handleSubmit}
                initialValues={initialValues}
                productGroupOwner={productGroupOwner}
                onFormHold={this.onFormHold}
                onFormSubmit={this.onFormSubmit}
                handleBtnDisable={this.handleBtnDisable}
                btnStyles={{
                  create: {
                    width: "20%",
                    margin: "6% 0% 10% 37%",
                    display: "inline-flex",
                  },
                }}
              />
            )}
            {isSingleStockOrder && (
              <StockOrderActionButtons
                handleSubmit={handleSubmit}
                handleBtnDisable={this.handleBtnDisable}
                onFormHold={this.onFormHold}
                type={type}
                invalid={invalid}
                initialValues={initialValues}
              />
            )}
          </Form>
        </div>
      </div>
    );
  }
}

const actions = {
  openModal,
  submitForm,
  setCustomerId,
  setDistributorList,
  setRetailerList,
  setProductGroupList,
  setProductGroupDescription,
  setLnamListForProductGroup,
  setAddressList,
  setUrgency,
  checkCoatingTimeline,
  customerActions,
  resetFields,
  checkOrderId,
  checkDuplicateOrders,
  setDispatchType,
  setShippingAddress,
  setCtoFieldsMandatory,
  touch,
  setAddressDescription,
  setLnamDescription,
  setProductGroupOwner,
  handleDistributorActions,
  handleRetailerActions,
  setFieldDisableOverideFlag,
  setStockDescriptionList,
};
const mapStateToProps = (state) => ({
  formState: state,
  customerId: state.orderForm.customerId,
  distributorList: state.orderForm.distributorList,
  productGroupList: state.orderForm.productGroupList,
  productGroupDescription: state.orderForm.productGroupDescription,
  retailerList: state.orderForm.retailerList,
  addressList: state.orderForm.addressList,
  submittingForm: state.orderForm.submittingForm,
  validatingPendingAuth: state.home.validatingPendingAuth,
  punchingWhatsappOrder: state.whatsappOrders.punchingOrder,
  addressDescription: state.orderForm.addressDescription,
  rightEyeCheckbox: state.orderForm.rightEyeCheckbox,
  leftEyeCheckbox: state.orderForm.leftEyeCheckbox,
  productGroupOwner: state.orderForm.productGroupOwner,
  fieldDisableOverideFlag: state.orderForm.fieldDisableOverideFlag,
});
export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "orderForm",
    validate,
    enableReinitialize: true,
  })(withRouter(OrderForm))
);

class CustomerDetails extends React.PureComponent {
  render() {
    return (
      <FieldWrapper
        name="customer"
        type="select"
        // component={CustomSelect}
        component={DropDown}
        placeholder="Customer Details"
        data={this.props.customerData}
        showSearch={true}
        getSelectedValue={this.props.customerActions}
        allowClear={true}
        labelInValue={this.props.labelInValue}
        heading={this.props.heading}
        mandatory={this.props.mandatory}
        width={this.props.width}
        disabled={this.props.disabled}
      />
    );
  }
}

/**
 * Create a new field type stock description
 * It will get data from PG config.
 * It will be the parent of lnam and coating
 * On stock page single/multi the lnam and coating will be diabled
 * Send stock_description in the order creation API.
 */

/**
 * Create a new button for multi stock order and re direct with url param for multi stock.
 * Customer section will remain the same
 * Now the pg for every order will be one
 * stock description, lnam ,coating, a new field quantity and lens section for multi section can be multiple
 * The field quantity is can also hav max value 5.
 * There can be 5 max section of the above field.
 * Don't confuse quantity field with number of sections both are different things
 * USe multi stock order creation API.
 */
