export const ORDER_TYPES = {
  RX: "rx",
  STOCK_SINGLE: "stock_single",
  STOCK_MULTI: "multi_stock",
};

export const ORDER_TYPE_MAPPINGS = {
  [ORDER_TYPES.RX]: "RX_ORDER",
  [ORDER_TYPES.STOCK_SINGLE]: "STOCK_SINGLE",
  [ORDER_TYPES.STOCK_MULTI]: "STOCK_MULTI",
};
