import React, { Component, Fragment } from "react";
import { Header, Label } from "semantic-ui-react";
import { Row, Spin } from "antd";
import FieldWrapper from "./FieldWrapper";
import DropDown from "../../app/common/form/DropDown";
import RadioInput from "../../app/common/form/RadioInput";
import { checkCoatingTimeline } from "./orderActions";
import TextInput from "../../app/common/form/TextInput";
import {
  API_STATUS,
  disablePreviousDates,
  errorToast,
} from "../../app/common/util/genericUtils";
import DateInput from "../../app/common/form/DateInput";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ORDER_TYPES } from "../../app/common/util/genericConstants";
import CustomSelect from "../../app/common/form/CustomSelect";
import {
  getCoatingListFromLnamId,
  getCorridorList,
  getFittingHeight,
  getPgDataForSelectedLnam,
  getSelectedProductGroupData,
} from "./formUtils";
import { formValueSelector } from "redux-form";
import {
  setLnamId,
  setLnamFields,
  setFittingforCorridor,
  getPgConfig,
  setCorridorId,
  setCorridorForFitting,
  setFittingHeightId,
  setCtoFieldsMandatory,
} from "./orderActions";

class LnamDetails extends React.PureComponent {
  render() {
    const {
      lnamList,
      setLnamId,
      heading,
      mandatory,
      width,
      description,
      labelInValue,
      disabled,
    } = this.props;
    return (
      <FieldWrapper
        onFocus={() => window.scrollTo(0, 500)}
        name="lnam"
        component={DropDown}
        type="select"
        placeholder="LNAM"
        data={lnamList}
        allowClear={true}
        showSearch={true}
        getSelectedValue={setLnamId}
        heading={heading}
        mandatory={mandatory}
        width={width}
        description={description}
        labelInValue={labelInValue}
        disabled={disabled}
      />
    );
  }
}

class ProductSectionWrapper extends Component {
  async componentWillReceiveProps(){
    this.coatingList = await getCoatingListFromLnamId(this.props.lnamId);
  }
  handleResetFields = (fields = []) =>
    fields.forEach((field) => this.props.change(field, ""));

  handleStockDescriptionChange = async (stockDescription) => {
    const { formState, change } = this.props;
    if (stockDescription && stockDescription.key) {
      const selector = formValueSelector("orderForm");
      const productGroup = selector(formState, "product_group");
      const selectedProductGroup = await getSelectedProductGroupData(
        productGroup.key
      );
      console.log('2.', selectedProductGroup)

      const { stock_description = [] } = selectedProductGroup;

      let selectedStockDescriptionData = {};

      stock_description.forEach((description) => {
        if (description.id === stockDescription.key) {
          selectedStockDescriptionData = description;
        }
      });

      const lnamFieldValue = {
        label: `${selectedStockDescriptionData.lnam.name} - ${
          selectedStockDescriptionData.lnam.description
        }`,
        key: selectedStockDescriptionData.lnam.id,
      };

      const coatingFieldValue = {
        label: selectedStockDescriptionData.coating.value,
        key: selectedStockDescriptionData.coating.id,
      };

      change("lnam", lnamFieldValue);

      this.setLnamId(lnamFieldValue);

      change("coating", coatingFieldValue);
    } else {
      this.handleResetFields(["lnam", "coating", "corridor", "fitting_height"]);
    }

    this.handleResetFields(["corridor", "fitting_height"]);
  };

  setLnamId = (lnam) => {
    const {
      formState,
      setLnamId,
      setLnamFields,
      setFittingforCorridor,
      getPgConfig,
    } = this.props;

    if (lnam && lnam.key) {
      const selector = formValueSelector("orderForm");
      const pg = selector(formState, "product_group");
      pg && pg.key
        ? getPgConfig(lnam.key, pg.key)
        : errorToast("Error", "Please select product group first");

      setFittingforCorridor(false);
      setLnamId(lnam.key);
      setLnamFields(lnam.key);
    }
    this.handleResetFields(["coating", "corridor", "fitting_height"]);
  };

  setCorridorValue = (obj) => {
    const id = obj.key;
    const { setCorridorId, setFittingforCorridor, change } = this.props;
    if (id === undefined) {
      setCorridorId(id);
      setFittingforCorridor(false);
      change("fitting_height", "");
    } else {
      setCorridorId(id);
      setFittingforCorridor(true);
    }
  };

  setFittingHeightValue = (obj) => {
    const id = obj.key;
    const { setFittingHeightId, setCorridorForFitting, change } = this.props;
    if (id === undefined) {
      setFittingHeightId(id);
      setCorridorForFitting(false);
      change("corridor", "");
    } else {
      setFittingHeightId(id);
      setCorridorForFitting(true);
    }
  };

  handleCtoField = (e, val) => {
    const { setCtoFieldsMandatory, touch } = this.props;
    val === "true" ? setCtoFieldsMandatory(true) : setCtoFieldsMandatory(false);
    let fields = ["mono_pd_r", "mono_pd_l", "dbl", "a_width", "b_width"];
    fields.forEach((field) => {
      touch("orderForm", `frame.${field}`);
    });
  };

  render() {
    const {
      stockDescriptionList,
      lnamId,
      lnamListForProductGroup,
      lnamDescription,
      fittingForCorridor,
      fittingHeightId,
      corridorForFitting,
      corridorId,
      pgDependentFields,
      urgent,
      showCustomerInitial,
      match: { params },
      // Pass below prop from outside
      labelInValue,
    } = this.props;
    const coatingList = this.coatingList;
    const corridorList = getCorridorList(
      lnamId,
      fittingHeightId,
      corridorForFitting
    );
    const fittingHeightList = getFittingHeight(
      lnamId,
      corridorId,
      fittingForCorridor
    );

    return (
      <Fragment>
        <Header color="blue" content="Product Section" />
        <Row gutter={10} style={{ marginTop: "2%" }}>
          <FieldWrapper
            name="stock_description"
            component={DropDown}
            type="select"
            placeholder="Select Stock Description"
            data={stockDescriptionList}
            showSearch={true}
            allowClear={true}
            labelInValue={labelInValue}
            heading="Stock Description"
            mandatory={true}
            width={6}
            getSelectedValue={this.handleStockDescriptionChange}
          />
          <LnamDetails
            setLnamId={this.setLnamId}
            lnamList={lnamListForProductGroup}
            labelInValue={labelInValue}
            heading="LNAM"
            mandatory={true}
            width={10}
            description={lnamDescription}
            disabled={params.orderType !== ORDER_TYPES.RX}
          />

          <FieldWrapper
            name="coating"
            component={CustomSelect}
            type="select"
            placeholder="coating"
            data={coatingList}
            showSearch={true}
            allowClear={true}
            labelInValue={labelInValue}
            heading="Coating"
            mandatory={true}
            width={6}
            disabled={params.orderType !== ORDER_TYPES.RX}
          />
          {corridorList && corridorList.length > 0 && (
            <FieldWrapper
              name="corridor"
              type="select"
              component={CustomSelect}
              placeholder="corridor"
              data={corridorList}
              showSearch={true}
              allowClear={true}
              getSelectedValue={this.setCorridorValue}
              labelInValue={labelInValue}
              heading="Corridor"
              mandatory={true}
              width={4}
            />
          )}
          {corridorList && corridorList.length > 0 && (
            <FieldWrapper
              name="fitting_height"
              component={CustomSelect}
              type="select"
              placeholder="FittingHeight"
              data={fittingHeightList}
              showSearch={true}
              allowClear={true}
              labelInValue={labelInValue}
              //getSelectedValue={this.setFittingHeightValue}
              heading="Fitting Height"
              mandatory={false}
              width={4}
            />
          )}
        </Row>
        {pgDependentFields.status === API_STATUS.LOADING && (
          <div style={{ marginTop: "20px" }}>
            <Spin />
            <Label color="blue" style={{ marginLeft: "15px" }}>
              Please wait we are getting prescription config
            </Label>
          </div>
        )}
        {pgDependentFields.status === API_STATUS.SUCCESS && (
          <Label color="green" style={{ marginTop: "20px" }}>
            Prescription config loaded.
          </Label>
        )}
        {pgDependentFields.status === API_STATUS.ERROR && (
          <Label color="red" style={{ marginTop: "20px" }}>
            Prescription config not loaded.
          </Label>
        )}
        <Row gutter={10}>
          {showCustomerInitial === true && (
            <FieldWrapper
              name="clinit"
              component={TextInput}
              type="text"
              placeholder="Customer Initials"
              heading="Customer Initials"
              mandatory={false}
              width={6}
            />
          )}
          {urgent && (
            <FieldWrapper
              name="promised_date"
              component={DateInput}
              type="date"
              placeholder="Promise Date"
              disableFunction={disablePreviousDates}
              heading="Promise Date"
              mandatory={true}
              width={6}
            />
          )}
          {urgent && (
            <FieldWrapper
              name="promised_hour"
              component={TextInput}
              type="text"
              placeholder="Promise Time(Hour)"
              heading="Promised Time(Hour)"
              mandatory={true}
              width={6}
            />
          )}
          {urgent && (
            <FieldWrapper
              name="promised_minute"
              component={TextInput}
              type="text"
              placeholder="Promise Time(Minute)"
              handleOnBlur={() => checkCoatingTimeline("orderForm")}
              heading="Promised Time(Minute)"
              mandatory={true}
              width={6}
            />
          )}
        </Row>
        <Row gutter={10} style={{ marginTop: "2%" }}>
          {showCustomerInitial === true && (
            <FieldWrapper
              name="cto"
              type="radio"
              valueOne="true"
              labelOne="Yes"
              valueTwo="false"
              labelTwo="No"
              component={RadioInput}
              onChange={this.handleCtoField}
              heading="CTO"
              mandatory={false}
              width={6}
            />
          )}
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  formState: state,
  stockDescriptionList: state.orderForm.stockDescriptionList,
  lnamId: state.orderForm.lnamId,
  lnamListForProductGroup: state.orderForm.lnamListForProductGroup,
  lnamDescription: state.orderForm.lnamDescription,
  fittingForCorridor: state.orderForm.fittingForCorridor,
  fittingHeightId: state.orderForm.fittingHeightId,
  corridorForFitting: state.orderForm.corridorForFitting,
  corridorId: state.orderForm.corridorId,
  pgDependentFields: getPgDataForSelectedLnam(state),
  urgent: state.orderForm.urgent,
  showCustomerInitial: state.orderForm.showCustomerInitial,
});

const actions = {
  setLnamId,
  setLnamFields,
  setFittingforCorridor,
  getPgConfig,
  setCorridorId,
  setCtoFieldsMandatory,
  setCorridorForFitting,
  setFittingHeightId,
};

const ProductSection = connect(
  mapStateToProps,
  actions
)(withRouter(ProductSectionWrapper));

export { ProductSection };
