import React from "react";
import { Button } from "antd";

const CreateOrderButtons = ({
  handleSubmit,
  onFormHold,
  onFormSubmit,
  style,
  handleBtnDisable,
  type,
}) => {
  let disabled = handleBtnDisable();
  console.log(type);
  return (
    <div style={style}>
      <Button
        style={{ marginRight: "8%" }}
        size="large"
        disabled={disabled}
        onClick={handleSubmit(onFormSubmit)}
      >
        Save & Process
      </Button>
      <Button
        size="large"
        disabled={disabled}
        onClick={handleSubmit(onFormHold)}
      >
        Save & Hold
      </Button>
    </div>
  );
};

export default CreateOrderButtons;
